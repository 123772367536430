import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import { WordPressDetailsDTO } from '@wp-one/sites-microservice-types';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------
// Site details - Vulnerabilities tab

export const wordpressApi = createApi({
  reducerPath: 'wordpressApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/wordpress' }),
  tagTypes: ['WordPress'],
  endpoints: (builder) => ({
    // GET WORDPRESS COMPONENTS
    getWpComponents: builder.query<WordPressDetailsDTO, string>({
      query: (siteId) => ({
        url: `/${siteId}/components`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [{ type: 'WordPress', id: 'WORDPRESS-LIST' }] : []),
    }),
    //UPDATE WORDPRESS COMPONENTS
    updateWpComponents: builder.mutation<
      { core: number; plugins: number; themes: number },
      {
        siteId: string;
        data: {
          core: string | undefined;
          plugins: string[];
          themes: string[];
        };
      }
    >({
      query: ({ siteId, data }) => ({
        url: `/${siteId}/components`,
        method: 'post',
        data,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'WordPress', id: 'WORDPRESS-LIST' }] : []),
    }),
  }),
});

export const { useGetWpComponentsQuery, useUpdateWpComponentsMutation } = wordpressApi;
