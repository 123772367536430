import { ReactNode } from 'react';
// hooks
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  isVertical?: boolean;
};

// ----------------------------------------------------------------------

const ButtonGroup = ({ children, isVertical = false }: Props) => {
  // HOOK
  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div
      className={`gv-button-group${isVertical ? ' gv-vertical' : ''}${
        isMobile ? ' gv-flex-col-reverse' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default ButtonGroup;
