import { Dispatch, SetStateAction } from 'react';
// @types
import { SecuritySettings } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeactivateAutoFixConfirmation from './DeactivateAutoFixConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
};

// ----------------------------------------------------------------------

export default function DeactivateAutoFixDialog({
  open,
  onClose,
  setSettings,
  setIsSettingsLoading,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <DeactivateAutoFixConfirmation
          onClose={onClose}
          setSettings={setSettings}
          setIsSettingsLoading={setIsSettingsLoading}
        />
      }
    />
  );
}
