// @types
import { SupportMobileOS } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import CompanianAppDownloadContent from './CompanianAppDownloadContent';

// ----------------------------------------------------------------------

type Props = {
  mobileOS: SupportMobileOS;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function CompanianAppDownloadDialog({ mobileOS, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<CompanianAppDownloadContent onClose={onClose} mobileOS={mobileOS} />}
    />
  );
}
