import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { PlanDetails, PlanSelectionState } from 'src/@types/site';

// ----------------------------------------------------------------------

const initialState: PlanSelectionState = {
  create: {
    isCreating: false,
    isMigrating: false,
  },
  upgrade: {
    upgradingSite: null,
  },
};

const slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    // TOGGLE CREATING SITE PLAN
    toggleCreatingSitePlan(state, action: PayloadAction<boolean>) {
      state.create.isCreating = action.payload;
    },
    // TOGGLE MIGRATING SITE PLAN
    toggleMigratingSitePlan(state, action: PayloadAction<boolean>) {
      state.create.isMigrating = action.payload;
    },
    // SET CURRENT SITE DETAILS FOR UPGRADE PLAN
    setUpgradePlanDetails(
      state,
      action: PayloadAction<{
        name: string;
        currentPlan: PlanDetails;
        currentDiskSpace: number;
        fromTab: string;
      } | null>
    ) {
      state.upgrade.upgradingSite = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { toggleCreatingSitePlan, toggleMigratingSitePlan, setUpgradePlanDetails } =
  slice.actions;
