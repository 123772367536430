import { ReactNode } from 'react';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description?: string;
  action?: {
    text: string;
    onClick: VoidFunction;
    isLink?: boolean;
    iconName?: string;
  };
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function PageSection({ title, description, action, children }: Props) {
  // HOOK
  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-md gv-tab-gap-lg">
      <div
        className="gv-flex gv-flex-col"
        style={{
          gap: '4px',
        }}
      >
        <div className="gv-flex-row-md gv-items-center gv-justify-between">
          <p className="gv-text-lg gv-text-bold">{title}</p>
          {action && !isMobile && <PageSectionAction {...action} />}
        </div>
        {description && <p className="gv-text-sm gv-text-max">{description}</p>}
        {action && isMobile && <PageSectionAction {...action} />}
      </div>

      <div className="gv-flex-column-md gv-desk-flex-row">{children}</div>
    </div>
  );
}

// ----------------------------------------------------------------------

type PageSectionActionProps = {
  text: string;
  onClick: VoidFunction;
  isLink?: boolean;
  iconName?: string;
};

function PageSectionAction({ text, onClick, isLink, iconName }: PageSectionActionProps) {
  return isLink ? (
    <a
      href="/#"
      className="gv-action"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <span className="gv-text-sm gv-text-bold" style={{ color: 'var(--color-state-form-on)' }}>
        {text}
      </span>
      {iconName && <gv-icon src={`/src/icons/${iconName}.svg`} />}
    </a>
  ) : (
    <Button condensed text={text} onClick={onClick} extraClass="gv-w-auto" />
  );
}
