// @types
import { ProgressStepStatusEnum } from 'src/@types/layout';
import { BackupStep } from 'src/@types/site';

// ----------------------------------------------------------------------

export default function getProgressStatus(
  // Adding more step types in the future (migration)
  stepToCheck: BackupStep, // Current examine step
  currentStep: BackupStep, // Crrent in progress step
  steps: BackupStep[]
): ProgressStepStatusEnum {
  const currentStepIndex = steps.indexOf(currentStep);
  const stepToCheckIndex = steps.indexOf(stepToCheck);

  return currentStepIndex === stepToCheckIndex
    ? ProgressStepStatusEnum.IN_PROGRESS
    : currentStepIndex < stepToCheckIndex
    ? ProgressStepStatusEnum.WAITING
    : ProgressStepStatusEnum.COMPLETED;
}
