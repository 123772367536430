import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Alert(theme: Theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
          borderRadius: '0',
          alignItems: 'center',
          ...theme.typography.body2,
          '.MuiAlert-icon': {
            marginRight: '18px',
          },
          '& .MuiAlert-message': {
            padding: '0 !important',
          },
        },
        standardSuccess: {
          backgroundColor: theme.palette.success.lighter,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.success.main,
          },
        },
        standardError: {
          backgroundColor: theme.palette.error.lighter,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.error.main,
          },
        },
        standardWarning: {
          backgroundColor: theme.palette.warning.lighter,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.warning.main,
          },
        },
        standardInfo: {
          backgroundColor: theme.palette.info.lighter,
          color: theme.palette.text.primary,
          '& .MuiAlert-icon': {
            color: theme.palette.info.main,
          },
        },
      },
    },
  };
}
