// @types
import { AccessPermissionFullDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import ChangeUserRoleForm from './ChangeUserRoleForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullDTO | null;
};

// ----------------------------------------------------------------------

export default function ChangeUserRoleDialog({ open, onClose, accessPermissionInfo }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <ChangeUserRoleForm onClose={onClose} accessPermissionInfo={accessPermissionInfo} />
      }
    />
  );
}
