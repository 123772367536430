// @types
import {
  UpdateDetails,
  PerformedUpdatesResponse,
  KeyUpdateDetailsExample,
} from '@joonasvanhatapio/wp-cloud-backend-types';
import { UpdateDetailsTypeEnum } from 'src/@types/site';

// ----------------------------------------------------------------------

export function getUpdateReportStats(
  wpUpdate: UpdateDetails | null,
  themesUpdates: KeyUpdateDetailsExample | null,
  pluginsUpdates: KeyUpdateDetailsExample | null
) {
  // Check wp update
  let wpUpdateDetails: boolean | undefined = undefined; // Undefined: none - True: success - False: failed
  if (wpUpdate) {
    if (wpUpdate.reverted) {
      wpUpdateDetails = false;
    } else {
      wpUpdateDetails = true;
    }
  }

  // Check themes updates
  let themesUpdatesDetails = {
    attempted: 0,
    success: 0,
  };
  if (themesUpdates) {
    themesUpdatesDetails = {
      attempted: Object.values(themesUpdates).length,
      success: Object.values(themesUpdates).filter((update) => update.reverted !== true).length,
    };
  }

  // Check plugins updates
  let pluginsUpdatesDetails = {
    attempted: 0,
    success: 0,
  };
  if (pluginsUpdates) {
    pluginsUpdatesDetails = {
      attempted: Object.values(pluginsUpdates).length,
      success: Object.values(pluginsUpdates).filter((update) => update.reverted !== true).length,
    };
  }

  return {
    wp: wpUpdateDetails,
    themes: themesUpdatesDetails,
    plugins: pluginsUpdatesDetails,
  };
}

export function getUpdateReport(
  reportId: string,
  reportList: PerformedUpdatesResponse[] | undefined
) {
  return reportList === undefined ? undefined : reportList.find((report) => report.id === reportId);
}

export function getUpdateReportDetailsArr(updateReport: PerformedUpdatesResponse | undefined) {
  // There can be cases where updated_wp, updated_themes, updated_plugins is empty obj (which can be considered as null)
  const wpUpdate =
    !updateReport?.updated_wp || Object.keys(updateReport.updated_wp).length === 0
      ? null
      : updateReport.updated_wp;
  const themesUpdates =
    !updateReport?.updated_themes || Object.keys(updateReport.updated_themes).length === 0
      ? null
      : updateReport.updated_themes;
  const pluginsUpdates =
    !updateReport?.updated_plugins || Object.keys(updateReport.updated_plugins).length === 0
      ? null
      : updateReport.updated_plugins;

  const wpUpdatesArr: UpdateDetails[] = !wpUpdate ? [] : [wpUpdate];
  const themesUpdatesArr: UpdateDetails[] = !themesUpdates ? [] : Object.values(themesUpdates);
  const pluginsUpdatesArr: UpdateDetails[] = !pluginsUpdates ? [] : Object.values(pluginsUpdates);

  return [
    ...wpUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.CORE, details: update })),
    ...themesUpdatesArr.map((update) => ({ type: UpdateDetailsTypeEnum.THEME, details: update })),
    ...pluginsUpdatesArr.map((update) => ({
      type: UpdateDetailsTypeEnum.PLUGIN,
      details: update,
    })),
  ];
}

export function getUpdateDetails(
  detailsName: string, // Type included
  updateReport: PerformedUpdatesResponse | undefined
) {
  const detailsArr = getUpdateReportDetailsArr(updateReport);

  return detailsArr.find(
    (detailsInfo) =>
      `${detailsInfo.type}${
        detailsInfo.details.title
          ? `-${detailsInfo.details.title.toLowerCase().split(' ').join('-')}`
          : ''
      }` === detailsName
  );
}
