import { useParams } from 'react-router';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UpdateVulnerabilityRow, UpdateVulnerabilityType } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertScoreToSeverity } from 'src/utils/vulnerabilities';
// components
import Badge from 'src/components/gravity/Badge';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  autoUpdateSetting: boolean;
  row: UpdateVulnerabilityRow;
  handleSelectUpdateVulnerabilities: (vulns: UpdateVulnerabilityRow[]) => void;
};

// ----------------------------------------------------------------------

export default function VulnerabilityRow({
  autoUpdateSetting,
  row,
  handleSelectUpdateVulnerabilities,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const { updatingVulnsInfo } = useSelector((state) => state.site);

  // VAR
  const currentSiteUpdatingInfo = updatingVulnsInfo.find((siteInfo) => siteInfo.siteName === name);
  const isUpdating =
    currentSiteUpdatingInfo === undefined
      ? false
      : currentSiteUpdatingInfo.updatingVulns.map((vuln) => vuln.id).includes(row.id);

  const score = row.cvssScore;

  const { type, status } = convertScoreToSeverity(score);

  return (
    <tr>
      <td>
        <Badge
          text={translate(
            `dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.row.severity.${status}`
          )}
          type={type}
        />
      </td>
      <td>
        <a href={row.vulnUrl} target="_blank" rel="noreferrer">
          {row.vulnName}
        </a>
        {row.exploitable && (
          <div className="gv-underline">
            {translate(
              'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.row.vulnName'
            )}
          </div>
        )}
      </td>
      <td>
        {translate(
          `dashboard.sites.details.mwp.security.vulnType.${row.type}${
            row.type !== UpdateVulnerabilityType.CORE ? '.singular' : ''
          }`
        )}
      </td>
      <td>{row.pluginDisplayName}</td>
      <td>
        {row.updateVer ||
          translate(
            'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.row.fixVersion'
          )}
      </td>
      {!autoUpdateSetting && (
        <td>
          {row.updateVer &&
            (!isMobile ? (
              <Button
                text={translate(
                  `dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.row.action.${
                    isUpdating ? 'updating' : 'update'
                  }`
                )}
                uiType="secondary"
                onClick={() => handleSelectUpdateVulnerabilities([row])}
                disabled={isUpdating}
              />
            ) : (
              <div
                onClick={() => {
                  if (!isUpdating) {
                    handleSelectUpdateVulnerabilities([row]);
                  }
                }}
                style={{
                  opacity: isUpdating ? 'var(--opacity-disabled-elements)' : 1,
                  cursor: isUpdating ? 'auto' : 'pointer',
                }}
              >
                <gv-icon src="/src/icons/sync.svg" />
              </div>
            ))}
        </td>
      )}
    </tr>
  );
}
