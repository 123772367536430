// redux
import { useSelector } from 'src/redux/store';
// @types
import { SitePlanEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertOldSitePlan } from 'src/utils/convert';
import { handleZendeskChatOpen } from 'src/utils/zendesk';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  cancellingPlan: SitePlanEnum | null;
};

// ----------------------------------------------------------------------

export default function CancelPlanConfirmation({ onClose, cancellingPlan }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  function handleContactSupport() {
    handleZendeskChatOpen(user?.brand?.id);
    onClose();
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.plan.cancelPlanDialog.title')}
        </h2>

        <p style={{ overflowX: 'auto' }}>
          {translate('wpone.sites.details.plan.cancelPlanDialog.description', {
            planName: translate(`wpone.sites.plan.${convertOldSitePlan(cancellingPlan as string)}`),
          })}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.back')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.contactSupport')}
          onClick={handleContactSupport}
        />
      </ButtonGroup>
    </>
  );
}
