import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// redux
import { useLazyGetSiteWPMemoryLimitQuery } from 'src/redux/api/siteApi';
// hooks
import useToggle from 'src/hooks/useToggle';
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import Loader from 'src/components/gravity/Loader';
//
import EditWPMemoryLimitDialog from './EditWPMemoryLimitDialog';
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

export default function WPMemoryLimitSettings() {
  const { namespace, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const {
    toggle: toggleEditWPMemoryLimitDialog,
    onOpen: onOpenEditWPMemoryLimitDialog,
    onClose: onCloseEditWPMemoryLimitDialog,
  } = useToggle();

  // API
  const [getWPMemoryLimit, { isLoading: getWPMemoryLimitIsLoading }] =
    useLazyGetSiteWPMemoryLimitQuery();

  // STATE
  const [currentMemoryLimit, setCurrentMemoryLimit] = useState<number | null>(null);

  // EVENT FUNCTION
  function handleEditWpMemoryLimit(newLimit: number) {
    if (isMountedRef.current) {
      setCurrentMemoryLimit(newLimit);
    }
  }

  //
  useEffect(() => {
    if (!namespace || !cluster) return;
    getWPMemoryLimit(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (isMountedRef.current) {
          if (data.code === 0) {
            setCurrentMemoryLimit(
              !isNaN(Number(data.stdout.split('M')[0])) ? Number(data.stdout.split('M')[0]) : null
            );
          } else {
            displayToast(
              translate('wponesupport.sites.details.support.wpMemoryLimitSettings.toast.error'),
              {
                variant: 'alert',
              }
            );
          }
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          displayToast(
            translate('wponesupport.sites.details.support.wpMemoryLimitSettings.toast.error'),
            {
              variant: 'alert',
            }
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {currentMemoryLimit !== null && (
        <div style={{ position: 'absolute' }}>
          <EditWPMemoryLimitDialog
            currentMemoryLimit={currentMemoryLimit}
            handleEditWpMemoryLimit={handleEditWpMemoryLimit}
            open={toggleEditWPMemoryLimitDialog}
            onClose={onCloseEditWPMemoryLimitDialog}
          />
        </div>
      )}

      <SettingsBlock
        title={translate('wponesupport.sites.details.support.wpMemoryLimitSettings.title')}
      >
        <div className="gv-w-full gv-flex-column-md gv-tab-flex-row gv-tab-items-center">
          <p className="gv-text-sm">
            {translate('wponesupport.sites.details.support.wpMemoryLimitSettings.description', {
              number: getWPMemoryLimitIsLoading
                ? ''
                : currentMemoryLimit !== null
                ? `${currentMemoryLimit}${translate('wponesupport.general.unit.mb')}`
                : '',
            })}
          </p>
          {getWPMemoryLimitIsLoading ? (
            <Loader condensed />
          ) : currentMemoryLimit !== null ? (
            <Button
              condensed
              text={translate('wponesupport.general.action.edit')}
              onClick={onOpenEditWPMemoryLimitDialog}
            />
          ) : (
            <></>
          )}
        </div>
      </SettingsBlock>
    </>
  );
}
