import { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
// redux
import { dispatch } from 'src/redux/store';
import {
  siteApi,
  useSetWpHomeMutation,
  useSetWpSiteUrlMutation,
  useSearchReplaceMutation,
} from 'src/redux/api/siteApi';
import { useChangeSitePrimaryHostnameMutation } from 'src/redux/api/resourceApi';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFCheckbox } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import { TextCustomize } from 'src/components/gravity/text';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  search_replace: boolean;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
  selectedDomain: string | null;
};

// ----------------------------------------------------------------------

export default function ChangePrimaryHostnameForm({ onClose, selectedDomain }: Props) {
  const { cluster, namespace, name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  // use this state to disable the submit button while the form is submitting, including the 5 seconds delay
  const [isLoading, setIsLoading] = useState(false);

  // API
  const [changeSitePrimaryHostname] = useChangeSitePrimaryHostnameMutation();

  const [setWpHome] = useSetWpHomeMutation();
  const [setWpSiteUrl] = useSetWpSiteUrlMutation();
  const [searchReplace] = useSearchReplaceMutation();

  // FORM
  const defaultValues = {
    search_replace: false,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    setIsLoading(true);

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'change-primary-hostname',
    });

    await changeSitePrimaryHostname({
      siteId: `${cluster}/${namespace}`,
      domainName: selectedDomain as string,
    })
      .unwrap()
      .then(async (res) => {
        if (res.success) {
          // Run wp config commands via SSH, and handle errors separately for each
          // Ignore any errors for setting WP home/WP site url/search replace
          await setWpHome({
            siteId: `${cluster}/${namespace}`,
            siteNewHostname: selectedDomain as string,
          })
            .unwrap()
            .then((res) => {
              if (res.code !== 0) {
                displayToast(
                  translate(
                    'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.setWpHome'
                  ),
                  {
                    variant: 'alert',
                  }
                );
              }
            })
            .catch(() => {
              displayToast(
                translate(
                  'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.setWpHome'
                ),
                {
                  variant: 'alert',
                }
              );
            });

          await setWpSiteUrl({
            siteId: `${cluster}/${namespace}`,
            siteNewHostname: selectedDomain as string,
          })
            .unwrap()
            .then((res) => {
              if (res.code !== 0) {
                displayToast(
                  translate(
                    'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.setWpSiteUrlFailed'
                  ),
                  {
                    variant: 'alert',
                  }
                );
              }
            })
            .catch(() => {
              displayToast(
                translate(
                  'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.setWpSiteUrlFailed'
                ),
                {
                  variant: 'alert',
                }
              );
            });

          // Run searchReplace only if search_replace is checked
          if (data.search_replace) {
            await searchReplace({
              siteId: `${cluster}/${namespace}`,
              siteNewHostname: selectedDomain as string,
              siteOldHostname: name as string,
            })
              .unwrap()
              .then(async (res) => {
                if (res.code !== 0) {
                  displayToast(
                    translate(
                      'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.searchReplace'
                    ),
                    {
                      variant: 'alert',
                      duration: 5000,
                    }
                  );
                  await new Promise((resolve) => setTimeout(resolve, 5000));
                }
              })
              .catch(async () => {
                displayToast(
                  translate(
                    'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.searchReplace'
                  ),
                  {
                    variant: 'alert',
                    duration: 5000,
                  }
                );
                await new Promise((resolve) => setTimeout(resolve, 5000));
              });
          }

          displayToast(
            translate(
              'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.successMessage'
            )
          );
          dispatch(siteApi.util.invalidateTags([{ type: 'Sites', id: 'PARTIAL-LIST' }]));
          navigate(`/sites/domains/${cluster}/${namespace}/${selectedDomain}`);
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate(
                'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.changePrimaryHostname'
              ),
            });
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.requestResponse.errorMessage.changePrimaryHostname'
              ),
              {
                variant: 'alert',
              }
            );
          }
        }
      })
      .catch((err) => {
        const errCode = (err as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.title')}
        </h2>
        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm">
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <TextCustomize
              text={translate(
                'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.description',
                { domain: selectedDomain }
              )}
            />
            <RHFCheckbox
              name="search_replace"
              label={translate(
                'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.searchReplace.title'
              )}
              labelId="search-replace"
              description={translate(
                'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.searchReplace.description'
              )}
              disabled={isLoading}
              condensed
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate(
            'wponesupport.sites.details.domains.changeSitePrimaryHostnameDialog.action'
          )}
          onClick={() => {
            clearErrors();
            handleSubmit(onSubmit)();
          }}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
