import { useEffect, useState } from 'react';
import ct from 'countries-and-timezones';
// @types
import { CommunityCardContent, CommunityCardContentType, SupportMobileOS } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// mock
import { DUMMY_EVENT_CONTENT } from 'src/mock/sites';
//
import PageSection from '../../PageSection';
import RecommendedCard from './RecommendedCard';

// ----------------------------------------------------------------------

type Props = {
  onOpenCompanianAppDownloadDialog: (selectedOS: SupportMobileOS) => void;
};

// ----------------------------------------------------------------------

export default function RecommendedBlock({ onOpenCompanianAppDownloadDialog }: Props) {
  // HOOKS
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <PageSection title={translate('dashboard.sites.details.mwp.overview.recommended.title')}>
      <div className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-gap-lg`}>
        <DownloadCompanianAppCard
          onOpenCompanianAppDownloadDialog={onOpenCompanianAppDownloadDialog}
        />
        <CommunityCard />
      </div>
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type DownloadCompanianAppCardProps = {
  onOpenCompanianAppDownloadDialog: (selectedOS: SupportMobileOS) => void;
};

function DownloadCompanianAppCard({
  onOpenCompanianAppDownloadDialog,
}: DownloadCompanianAppCardProps) {
  // HOOKS
  const isDesktop = useResponsive('up', 'desktop_min');

  // STATE
  const [userOS, setUserOS] = useState<SupportMobileOS | null>(null);

  // VAR
  const ctaButtons =
    userOS && !isDesktop
      ? [
          {
            text: 'dashboard.sites.details.mwp.overview.recommended.companion.action.autoDetect',
            onClick: () => {
              const url =
                userOS === SupportMobileOS.ios
                  ? 'https://apps.apple.com/ua/app/one-com-companion/id1522498353'
                  : 'https://play.google.com/store/apps/details?id=com.dotcompanionapp';
              window.open(url, '_blank');
            },
          },
        ]
      : Object.values(SupportMobileOS).map((os) => ({
          text: `dashboard.sites.details.mwp.overview.recommended.companion.action.${os}`,
          onClick: () => onOpenCompanianAppDownloadDialog(os),
        }));

  //
  useEffect(() => {
    function detectUserOs() {
      const { userAgent } = navigator;
      if (/iPad|iPhone|iPod/.test(userAgent)) return SupportMobileOS.ios;
      if (/Android/.test(userAgent)) return SupportMobileOS.android;
      return null;
    }

    setUserOS(detectUserOs());
  }, []);

  return (
    <RecommendedCard
      title={'dashboard.sites.details.mwp.overview.recommended.companion.title'}
      description={'dashboard.sites.details.mwp.overview.recommended.companion.description'}
      ctaBtns={ctaButtons}
      isCompanionCard
    />
  );
}

// ----------------------------------------------------------------------

function CommunityCard() {
  // STATE
  const [currentTimeZoneCountries, setCurrentTimeZoneCountries] = useState<string[]>([]);

  // VAR
  const selectedCard = getCommunityCard();

  // HELPER FUNCTION
  function getCommunityCard() {
    let eventContent = DUMMY_EVENT_CONTENT.find(
      (item) =>
        item.type === CommunityCardContentType.event &&
        item.location
          ?.split(',')
          .map((location) => location.trim())
          .some((location) => currentTimeZoneCountries.includes(location))
    );

    let newsContent = DUMMY_EVENT_CONTENT.find(
      (item) => item.type === CommunityCardContentType.news
    );

    let statisticsContents: CommunityCardContent[] = [];
    for (let i = 1; i < 4; i++) {
      const hrefLink =
        i === 1
          ? 'https://events.wordpress.org'
          : i === 2
          ? 'https://learn.wordpress.org'
          : 'https://wordpress.org/documentation';

      statisticsContents.push({
        type: CommunityCardContentType.static,
        title: `dashboard.sites.details.mwp.overview.recommended.community.fallback.staticEvent${i}.title`,
        description: `dashboard.sites.details.mwp.overview.recommended.community.fallback.staticEvent${i}.description`,
        ctaBtn: {
          text: `dashboard.sites.details.mwp.overview.recommended.community.fallback.staticEvent${i}.action`,
          onClick: () => window.open(hrefLink, '_blank'),
        },
      });
    }
    const randomStatisticsContent =
      statisticsContents[Math.floor(Math.random() * statisticsContents.length)];

    // TEMP SOLUTION UNTIL HAVING EVENT DATA RETURNED FROM API
    //================================================================================
    eventContent = undefined;
    newsContent = undefined;
    //================================================================================

    return eventContent || newsContent || randomStatisticsContent;
  }

  //
  useEffect(() => {
    function getCountryFromTimeZone() {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      return ct.getCountriesForTimezone(timeZone).map((country) => country.name);
    }

    setCurrentTimeZoneCountries(getCountryFromTimeZone());
  }, []);

  return (
    <RecommendedCard
      title={selectedCard.title}
      description={selectedCard.description}
      subtitle={selectedCard.subtitle}
      ctaBtns={[selectedCard.ctaBtn]}
    />
  );
}
