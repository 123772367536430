// @types
import { UpdateVulnerabilityLogRow } from 'src/@types/site';
import { TableHeader } from 'src/@types/layout';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
// utils
import { filterTableSort } from 'src/utils/vulnerabilities';
// components
import { TableHeadCustom, TablePagination } from 'src/components/gravity/table';
import { TextCustomize } from 'src/components/gravity/text';
//
import VulnerabilityLogRow from './VulnerabilityLogRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'dateTime',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.dateTime',
  },
  {
    id: 'severity',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.severity',
    sort: true,
  },
  {
    id: 'vulnName',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.vulnName',
  },
  {
    id: 'type',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.type',
  },
  {
    id: 'pluginName',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.pluginName',
    sort: true,
  },
  {
    id: 'updateVer',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.fixVersion',
  },
  {
    id: 'result',
    label: 'dashboard.sites.details.mwp.security.vulnerabilityLog.table.header.result',
  },
];

// ----------------------------------------------------------------------

type Props = {
  tableData: UpdateVulnerabilityLogRow[];
};

// ----------------------------------------------------------------------

export default function VulnerabilityTable({ tableData }: Props) {
  // HOOK
  const { translate } = useLocales();

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    onSort,
    onChangePage,
  } = useGravityTable({
    defaultOrderBy: 'severity',
    defaultOrder: 'desc',
    defaultRowsPerPage: 10,
  });

  // VAR
  const filteredData: UpdateVulnerabilityLogRow[] = [...tableData].sort(
    filterTableSort(orderBy, order)
  );

  const isNotFound = filteredData.length === 0;

  return (
    <div className="gv-data-table">
      <div style={{ overflowX: 'auto' }}>
        <table className="gv-col-2-shrink gv-col-4-shrink gv-col-6-shrink">
          <TableHeadCustom headers={HEADERS} order={order} orderBy={orderBy} onSort={onSort} />
          <tbody>
            {filteredData.slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage).map((row) => (
              <VulnerabilityLogRow key={row.id} row={row} />
            ))}
          </tbody>
        </table>
      </div>

      {isNotFound && (
        <div className="gv-empty-state">
          <div className="gv-empty-state-content">
            <h3 className="gv-title">
              {translate(
                'dashboard.sites.details.mwp.security.vulnerabilityLog.table.noData.title'
              )}
            </h3>
            <p>
              {translate(
                'dashboard.sites.details.mwp.security.vulnerabilityLog.table.noData.description'
              )}
            </p>
          </div>
        </div>
      )}

      <TablePagination
        totalRows={filteredData.length}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        customInfo={
          <TextCustomize
            text={translate('dashboard.sites.details.mwp.security.vulnerabilityLog.table.footer')}
            textClass="gv-caption gv-text-bold"
            linkHref={['https://patchstack.com/']}
            linkStyle={{
              cursor: 'pointer',
              fontWeight: 600,
            }}
          />
        }
      />
    </div>
  );
}
