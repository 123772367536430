import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
//
import PageLayout from './PageLayout';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  title: string;
  noPadding?: boolean; // CheckEmail - 403 - 404
  meta?: ReactNode;
};

// ----------------------------------------------------------------------

const SidebarPage = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', noPadding, meta }, ref) => {
    const { pathname } = useLocation();

    const { cluster, namespace, name } = useParams();

    // HOOK
    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'desktop_min');

    // STATE
    const { headerHeight, footerHeight, overflowPadding, sidebarHighlightActivePage, tabConfig } =
      useSelector((state) => state.layout);

    // VAR
    const tabname = pathname.split('/')[2];

    return (
      <>
        <Helmet>
          <title>{`${title} | WP.one`}</title>
          {meta}
        </Helmet>

        <div className="gv-activated">
          {isDesktop && tabConfig ? (
            <div
              className="gv-flex gv-justify-center"
              style={{
                minHeight: `calc(100vh - ${headerHeight + footerHeight}px)`,
                paddingRight: overflowPadding,
                backgroundColor: 'var(--color-surface-dim)',
              }}
            >
              <div
                className="gv-flex gv-flex-row gv-w-full"
                style={{
                  maxWidth: 1536,
                }}
              >
                <div className="gv-sidebar gv-py-fluid">
                  <div className="gv-side-menu">
                    <div className="gv-side-label">{translate(tabConfig.title)}</div>
                    <nav className="gv-side-nav">
                      <ul>
                        {tabConfig.navConfig.map((tab, index) => (
                          <RouterLink
                            key={index}
                            to={
                              tabConfig.primaryPathname === 'sites'
                                ? `/${tabConfig.primaryPathname}/${tab.tabName}/${cluster}/${namespace}/${name}`
                                : tabConfig.primaryPathname === 'dns'
                                ? `/${tabConfig.primaryPathname}/${tab.tabName}/${name}`
                                : `/${tabConfig.primaryPathname}/${tab.tabName}`
                            }
                            className={`gv-nav-item${
                              tab.tabName === tabname && sidebarHighlightActivePage
                                ? ' gv-active'
                                : ''
                            }`}
                          >
                            <gv-icon src={`/src/icons/${tab.icon}.svg`} />
                            <span className="gv-text-truncate">{translate(tab.title)}</span>
                          </RouterLink>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>

                <div ref={ref} className="gv-p-fluid gv-flex-1">
                  {children}
                </div>
              </div>
            </div>
          ) : (
            <PageLayout ref={ref} noPadding={noPadding}>
              {children}
            </PageLayout>
          )}
        </div>
      </>
    );
  }
);

export default SidebarPage;
