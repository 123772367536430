import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import { DnsRecord, TTL } from 'src/@types/domain';
import {
  CloudflareGetListResponse,
  CloudflareGetByDomain,
  CloudflareCreateResponse,
  DeleteDNSMicroserviceBodyDTO,
  FillDefaultDNSRecordsResponseDTO,
  DeleteDNSRecordResponseDTO,
} from '@joonasvanhatapio/wp-cloud-backend-types';
// utils
import {
  convertNumberToTtl,
  convertToIDN,
  convertToUnicode,
  convertTtlToNumber,
} from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const domainApi = createApi({
  reducerPath: 'domainApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v5/zones',
  }),
  tagTypes: ['Domains', 'DnsRecords'],
  endpoints: (builder) => ({
    // GLOBAL SEARCH DOMAINS
    searchDomains: builder.query<CloudflareGetListResponse, string>({
      query: (search) => {
        const searchParams = new URLSearchParams();

        searchParams.append('search', convertToIDN(search));

        const searchParamsString = searchParams.toString();

        return {
          url: `/list?limit=10&page=0&sortingby=name&sortingorder=ASC&${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: CloudflareGetListResponse) => ({
        ...response,
        zones: response.zones.map((zone) => ({
          ...zone,
          name: convertToUnicode(zone.name),
        })),
      }),
    }),
    // GET DOMAINS
    getDomains: builder.query<
      CloudflareGetListResponse,
      {
        rowsPerPage: number;
        page: number;
        orderBy: string;
        order: 'ASC' | 'DESC';
        search: string;
      }
    >({
      query: ({ rowsPerPage, page, orderBy, order, search }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('limit', rowsPerPage.toString());
        searchParams.append('page', page.toString());
        searchParams.append('sortingBy', orderBy);
        searchParams.append('sortingOrder', order);
        searchParams.append('search', convertToIDN(search));

        const searchParamsString = searchParams.toString();

        return {
          url: `/list?${searchParamsString}`,
          method: 'get',
        };
      },
      transformResponse: (response: CloudflareGetListResponse) => ({
        ...response,
        zones: response.zones.map((zone) => ({
          ...zone,
          name: convertToUnicode(zone.name),
        })),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.zones.map(({ zone_id }) => ({ type: 'Domains' as const, id: zone_id })),
              { type: 'Domains', id: 'PARTIAL-LIST' },
              { type: 'Domains', id: 'DOMAIN-LIST' },
            ]
          : [
              { type: 'Domains', id: 'PARTIAL-LIST' },
              { type: 'Domains', id: 'DOMAIN-LIST' },
            ],
    }),
    // GET DOMAIN
    getDomain: builder.query<CloudflareGetByDomain, string | undefined>({
      query: (name) => ({
        url: `/${convertToIDN(name)}`,
        method: 'get',
      }),
      transformResponse: (response: CloudflareGetByDomain) => ({
        result: {
          ...response.result,
          name: convertToUnicode(response.result.name),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              { type: 'Domains', id: result.result.zone_id },
              { type: 'Domains', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'Domains', id: 'PARTIAL-LIST' }],
    }),
    // ADD DOMAIN
    addDomain: builder.mutation<
      CloudflareCreateResponse,
      { domain: string; email: string | undefined }
    >({
      query: ({ domain, email }) => ({
        url: `/`,
        method: 'post',
        data: {
          domain: convertToIDN(domain),
          email: email,
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Domains', id: 'PARTIAL-LIST' }] : []),
    }),
    // DELETE DOMAIN
    deleteDomain: builder.mutation<{ success: boolean }, string | undefined>({
      query: (domain) => ({
        url: `/${convertToIDN(domain)}`,
        method: 'delete',
      }),
      invalidatesTags: (result) =>
        result?.success ? [{ type: 'Domains', id: 'DOMAIN-LIST' }] : [],
    }),
    // GET DNS RECORDS
    getDnsRecords: builder.query<{ dnsRecords: DnsRecord[] }, string | undefined>({
      query: (domainName) => ({
        url: `/${convertToIDN(domainName)}/dns`,
        method: 'get',
      }),
      transformResponse: (response: { result: DnsRecord[] }) => ({
        dnsRecords: response.result.map((dnsRecord) => ({
          ...dnsRecord,
          name: convertToUnicode(dnsRecord.name),
          id: dnsRecord.id.toString(),
          ttl: convertNumberToTtl(Number(dnsRecord.ttl)),
        })),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.dnsRecords.map(({ zone_id }) => ({
                type: 'DnsRecords' as const,
                id: zone_id,
              })),
              { type: 'DnsRecords', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'DnsRecords', id: 'PARTIAL-LIST' }],
    }),
    // ADD DNS RECORD
    addDnsRecord: builder.mutation<
      boolean,
      { domainName: string | undefined; newDnsRecord: DnsRecord; isBunnyDns: boolean }
    >({
      query: ({ domainName, newDnsRecord, isBunnyDns }) => ({
        url: `/${convertToIDN(domainName)}/dns`,
        method: 'post',
        data: {
          ...newDnsRecord,
          name: convertToIDN(newDnsRecord.name),
          ttl: convertTtlToNumber(newDnsRecord.ttl as TTL, isBunnyDns),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'DnsRecords', id: 'PARTIAL-LIST' }] : []),
    }),
    // EDIT DNS RECORD
    editDnsRecord: builder.mutation<
      boolean,
      {
        domainName: string | undefined;
        dnsRecordId: string;
        updateDnsRecord: Partial<DnsRecord>;
        isBunnyDns: boolean;
      }
    >({
      query: ({ domainName, dnsRecordId, updateDnsRecord, isBunnyDns }) => ({
        url: `/${convertToIDN(domainName)}/dns/${dnsRecordId}`,
        method: 'patch',
        data: {
          ...updateDnsRecord,
          name: convertToIDN(updateDnsRecord.name),
          ttl: convertTtlToNumber(updateDnsRecord.ttl as TTL, isBunnyDns),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'DnsRecords', id: 'PARTIAL-LIST' }] : []),
    }),
    // DELETE DNS RECORD
    deleteDnsRecord: builder.mutation<DeleteDNSRecordResponseDTO[], DeleteDNSMicroserviceBodyDTO>({
      query: (data) => ({
        url: `/${convertToIDN(data.domain)}/dns`,
        method: 'delete',
        data: {
          ...data,
          domain: convertToIDN(data.domain),
        },
      }),
      invalidatesTags: (result) => (result ? [{ type: 'DnsRecords', id: 'PARTIAL-LIST' }] : []),
    }),
    // ADD DEFAULT DNS RECORD
    addDefaultDnsRecords: builder.mutation<
      FillDefaultDNSRecordsResponseDTO,
      { domain: string; brand: string }
    >({
      query: ({ domain, brand }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('brand', brand);

        const searchParamsString = searchParams.toString();

        return {
          url: `/fill-default-records/${convertToIDN(domain)}?${searchParamsString}`,
          method: 'post',
        };
      },
      invalidatesTags: (result) => (result ? [{ type: 'DnsRecords', id: 'PARTIAL-LIST' }] : []),
    }),
  }),
});

export const {
  useLazySearchDomainsQuery,
  useGetDomainsQuery,
  useGetDomainQuery,
  useAddDomainMutation,
  useDeleteDomainMutation,
  useGetDnsRecordsQuery,
  useAddDnsRecordMutation,
  useEditDnsRecordMutation,
  useDeleteDnsRecordMutation,
  useAddDefaultDnsRecordsMutation,
} = domainApi;
