import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function GuestGuard({ children }: GuestGuardProps) {
  const location = useLocation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const isAuthRouteWithToken =
    location.pathname === '/auth' && new URLSearchParams(location.search).has('token');

  if (user && !isAuthRouteWithToken) {
    return <Navigate to="/sites" replace />;
  }

  return <>{children}</>;
}
