import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { resourceApi } from 'src/redux/api/resourceApi';
import { userApi } from 'src/redux/api/userApi';
import { siteApi, useDeleteSiteMutation } from 'src/redux/api/siteApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { SiteStatusEnum } from 'src/@types/site';
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { RHFCheckbox, FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  mainDomain: string;
  immediate: boolean;
  afterSubmit?: string;
};

type Props = {
  siteStatus: SiteStatusEnum;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteForm({ siteStatus, onClose }: Props) {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [deleteSite, { isLoading }] = useDeleteSiteMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // FORM
  const FormSchema = Yup.object().shape({
    mainDomain: Yup.string()
      .required(
        translate(
          'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.inputField.validation1'
        )
      )
      .test(
        'main-domain',
        translate(
          'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.inputField.validation2'
        ),
        function (value) {
          if (value === name) {
            return true;
          }
          return false;
        }
      ),
  });

  const defaultValues = {
    mainDomain: '',
    immediate: siteStatus === SiteStatusEnum.suspended ? true : false,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!cluster || !namespace) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'delete-site',
    });

    deleteSite({
      cluster: cluster,
      namespace: namespace,
      immediate: data.immediate,
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.requestResponse.successMessage'
          )
        );

        // For getting latest update on Customer table (for support/service)
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));

        if (user?.role === UserRoleEnum.customer || data.immediate) {
          navigate('/sites');
        } else {
          // For getting latest update on Site Details if user is not a customer
          dispatch(siteApi.util.invalidateTags([{ type: 'Sites', id: 'PARTIAL-LIST' }]));

          // For getting latest update on Site User permissions
          dispatch(resourceApi.util.invalidateTags([{ type: 'Resources', id: 'PARTIAL-LIST' }]));
        }

        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.siteAdministration.deleteSite.dialog.title')}
        </h2>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <p>
              {translate(
                'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.description',
                {
                  domain: name,
                }
              )}
            </p>

            {user?.role !== UserRoleEnum.customer && siteStatus !== SiteStatusEnum.suspended && (
              <RHFCheckbox
                name="immediate"
                label={translate(
                  'wponesupport.sites.details.settings.siteAdministration.deleteSite.dialog.immediate'
                )}
                labelId="immediate-site-deletion"
                condensed
              />
            )}

            <p>
              {translate(
                'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.confirmation',
                {
                  domain: name,
                }
              )}
            </p>
            <RHFTextField
              name="mainDomain"
              label={translate(
                'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.inputField.label'
              )}
              labelId="main-domain"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate(
            'wpone.sites.details.settings.siteAdministration.deleteSite.dialog.action'
          )}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
