import { useState, useEffect, useRef } from 'react';
// redux
import {
  useEnableSiteRedisMutation,
  useActivateSiteRedisCacheMutation,
  useEnableSiteRedisSSHMutation,
  useDisableSiteRedisMutation,
  useDeactivateSiteRedisCacheMutation,
  useDisableSiteRedisSSHMutation,
  useLazyGetSiteRedisPluginStatusQuery,
  useLazyGetSiteRedisPluginAvailabilityQuery,
} from 'src/redux/api/siteApi';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Toggle from 'src/components/gravity/form/Toggle';
import Loader from 'src/components/gravity/Loader';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | undefined;
};

// ----------------------------------------------------------------------

export default function RedisSettings({ site }: Props) {
  // HOOK
  const { translate } = useLocales();
  const isMountedRef = useIsMountedRef();

  // API
  // Get Redis status
  const [getSiteRedisStatus, { isLoading: getRedisStatusIsLoading }] =
    useLazyGetSiteRedisPluginStatusQuery();
  // Get Redis availability
  const [getSiteRedisAvailability, { isFetching: getRedisAvailabilityIsLoading }] =
    useLazyGetSiteRedisPluginAvailabilityQuery();
  // Enable
  const [enableSiteRedis, { isLoading: enableRedisIsLoading }] = useEnableSiteRedisMutation();
  const [activateSiteRedisCache, { isLoading: activateRedisCacheIsLoading }] =
    useActivateSiteRedisCacheMutation();
  const [enableSiteRedisSSH, { isLoading: enableRedisSSHIsLoading }] =
    useEnableSiteRedisSSHMutation();
  // Disable
  const [disableSiteRedis, { isLoading: disableRedisIsLoading }] = useDisableSiteRedisMutation();
  const [deactivateSiteRedisCache, { isLoading: deactivateRedisCacheIsLoading }] =
    useDeactivateSiteRedisCacheMutation();
  const [disableSiteRedisSSH, { isLoading: disableRedisSSHIsLoading }] =
    useDisableSiteRedisSSHMutation();

  // STATE
  const [toggleRedisServer, setToggleRedisServer] = useState<boolean>(site?.redis_enabled || false);
  const [toggleRedisPlugin, setToggleRedisPlugin] = useState<boolean>(false);
  const [redisPluginError, setRedisPluginError] = useState<string | null>(null);
  const [isPolling, setIsPolling] = useState<boolean>(true);

  // VAR
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // EVENT FUNCTION
  const handleToggleRedisServer = () => {
    if (!site) return;

    // Deactivate Redis server
    if (toggleRedisServer) {
      disableSiteRedis(`${site.cluster.name}/${site.namespace}`)
        .unwrap()
        .then((disablePluginResponse) => {
          if (!disablePluginResponse) {
            displayToast(
              translate(
                'wponesupport.sites.details.support.redisSettings.redisServerStatus.deactivateRedisServer.toast.error'
              ),
              { variant: 'alert' }
            );
            return;
          }

          if (isMountedRef.current) {
            setToggleRedisServer(false);
          }
          displayToast(
            translate(
              'wponesupport.sites.details.support.redisSettings.redisServerStatus.deactivateRedisServer.toast.success'
            )
          );

          // Disable the second toggle automatically if the first one is disabled
          if (toggleRedisPlugin) {
            handleToggleRedisPlugin();
          }

          stopPollingRedisAvailability();
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    }
    // Activate Redis server
    else {
      enableSiteRedis(`${site.cluster.name}/${site.namespace}`)
        .unwrap()
        .then((enablePluginResponse) => {
          if (!enablePluginResponse) {
            displayToast(
              translate(
                'wponesupport.sites.details.support.redisSettings.redisServerStatus.activateRedisServer.toast.error'
              ),
              { variant: 'alert' }
            );
            return;
          }

          if (isMountedRef.current) {
            setToggleRedisServer(true);
          }
          displayToast(
            translate(
              'wponesupport.sites.details.support.redisSettings.redisServerStatus.activateRedisServer.toast.success'
            )
          );

          startPollingRedisAvailability();
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    }
  };

  const handleToggleRedisPlugin = async () => {
    if (!site) return;

    try {
      // Disable Redis Plugin
      if (toggleRedisPlugin) {
        const deactivateSiteRedisCacheResponse = await deactivateSiteRedisCache(
          `${site.cluster.name}/${site.namespace}`
        ).unwrap();
        if (deactivateSiteRedisCacheResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.redisSettings.redisPluginStatus.deactivateRedisPlugin.toast.error'
            ),
            { variant: 'alert' }
          );
          return;
        }

        await disableSiteRedisSSH(`${site.cluster.name}/${site.namespace}`).unwrap();

        if (isMountedRef.current) {
          setToggleRedisPlugin(false);
        }
        displayToast(
          translate(
            'wponesupport.sites.details.support.redisSettings.redisPluginStatus.deactivateRedisPlugin.toast.success'
          )
        );
      }
      // Enable Redis Plugin
      else {
        const activateSiteRedisCacheResponse = await activateSiteRedisCache(
          `${site.cluster.name}/${site.namespace}`
        ).unwrap();
        if (activateSiteRedisCacheResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.redisSettings.redisPluginStatus.activateRedisPlugin.toast.error.activateSiteRedisCache'
            ),
            { variant: 'alert' }
          );
          return;
        }

        const enableSiteRedisSSHResponse = await enableSiteRedisSSH(
          `${site.cluster.name}/${site.namespace}`
        ).unwrap();
        if (enableSiteRedisSSHResponse.code !== 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.redisSettings.redisPluginStatus.activateRedisPlugin.toast.error.enableSiteRedisSSH'
            ),
            { variant: 'alert' }
          );
          return;
        }

        if (isMountedRef.current) {
          setToggleRedisPlugin(true);
        }
        displayToast(
          translate(
            'wponesupport.sites.details.support.redisSettings.redisPluginStatus.activateRedisPlugin.toast.success'
          )
        );
      }
    } catch (error) {
      const errCode = (error as { status: number; data: any }).status;
      displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
    }
  };

  const startPollingRedisAvailability = () => {
    setIsPolling(true);
    if (redisPluginError) {
      setRedisPluginError(null);
    }
    if (!site) {
      setIsPolling(false);
      return;
    }
    pollingIntervalRef.current = setInterval(async () => {
      await getSiteRedisAvailability(`${site.cluster.name}/${site.namespace}`)
        .unwrap()
        .then((data) => {
          if (data.code === 1) {
            clearInterval(pollingIntervalRef.current!);
            setIsPolling(false);
          }
        })
        .catch(() => {
          clearInterval(pollingIntervalRef.current!);
          setIsPolling(false);
          if (isMountedRef.current) {
            setRedisPluginError(
              translate('wponesupport.sites.details.support.redisSettings.redisPluginStatus.error')
            );
          }
        });
    }, 5000);
  };

  const stopPollingRedisAvailability = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
    setIsPolling(false);
  };

  //
  useEffect(() => {
    if (!site) {
      setIsPolling(false);
      return;
    }
    getSiteRedisAvailability(`${site.cluster.name}/${site.namespace}`)
      .unwrap()
      .then((data) => {
        if (data.code === 1) {
          getSiteRedisStatus(`${site.cluster.name}/${site.namespace}`)
            .unwrap()
            .then((data) => {
              if (isMountedRef.current) {
                setToggleRedisPlugin(data.code === 0);
              }
            })
            .catch(() => {
              if (isMountedRef.current) {
                setRedisPluginError(
                  translate(
                    'wponesupport.sites.details.support.redisSettings.redisPluginStatus.error'
                  )
                );
              }
            })
            .finally(() => {
              setIsPolling(false);
            });
        } else {
          if (toggleRedisServer) {
            startPollingRedisAvailability();
          } else {
            setIsPolling(false);
          }
        }
      })
      .catch(() => {
        setIsPolling(false);
        if (isMountedRef.current) {
          setRedisPluginError(
            translate('wponesupport.sites.details.support.redisSettings.redisPluginStatus.error')
          );
        }
      });

    return () => {
      stopPollingRedisAvailability();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsBlock title={translate('wponesupport.sites.details.support.redisSettings.title')}>
      <div className="gv-w-full gv-flex-column-md gv-tab-flex-row gv-tab-items-center">
        <Toggle
          condensed
          name="redisServer"
          label={translate(
            'wponesupport.sites.details.support.redisSettings.redisServerStatus.label'
          )}
          labelId="redisServer"
          description={translate(
            'wponesupport.sites.details.support.redisSettings.redisServerStatus.description'
          )}
          checked={toggleRedisServer}
          onChange={handleToggleRedisServer}
          disabled={enableRedisIsLoading || disableRedisIsLoading}
        />
        {isPolling && <Loader condensed />}
      </div>

      <Toggle
        condensed
        name="redisPlugin"
        label={translate(
          'wponesupport.sites.details.support.redisSettings.redisPluginStatus.label'
        )}
        labelId="redisPlugin"
        description={translate(
          'wponesupport.sites.details.support.redisSettings.redisPluginStatus.description'
        )}
        checked={toggleRedisPlugin}
        onChange={() => handleToggleRedisPlugin()}
        disabled={
          redisPluginError !== null ||
          getRedisStatusIsLoading ||
          activateRedisCacheIsLoading ||
          enableRedisSSHIsLoading ||
          deactivateRedisCacheIsLoading ||
          disableRedisSSHIsLoading ||
          disableRedisIsLoading ||
          getRedisAvailabilityIsLoading ||
          !toggleRedisServer ||
          isPolling
        }
        errMsg={redisPluginError || undefined}
      />
    </SettingsBlock>
  );
}
