// ----------------------------------------------------------------------

// Gravity
// /* Breakpoints */
// @screen_mobile_min: 360px;
// @screen_mobile_max: 599px;
// @screen_tablet_min: 600px;
// @screen_tablet_max: 1023px;
// @screen_desktop_min: 1024px;
// @screen_desktop_max: 1440px;

// /* Fine grained break points */
// @screen_mobile_small_min: 360px; //mobile_min
// @screen_mobile_small_max: 413px;
// @screen_mobile_large_min: 414px;
// @screen_mobile_large_max: 599px; //mobile_max
// @screen_tablet_small_min: 600px; //tablet_min
// @screen_tablet_small_max: 767px;
// @screen_tablet_large_min: 768px;
// @screen_tablet_large_max: 1023px; //tablet_max
// @screen_desktop_small_min: 1024px;  //desktop_min
// @screen_desktop_small_max: 1279px;
// @screen_desktop_large_min: 1280px;

const breakpoints = {
  values: {
    xs: 0,
    sm: 768,
    md: 900,
    lg: 1200,
    xl: 4536,
    // Gravity (primary)
    mobile_min: 360,
    mobile_max: 599,
    tablet_min: 600,
    tablet_max: 1023,
    desktop_min: 1024,
    desktop_max: 1440,
    // Gravity (secondary)
    mobile_small_max: 413,
    mobile_large_min: 414,
    tablet_small_max: 767,
    tablet_large_min: 768,
    desktop_small_max: 1279,
    desktop_large_min: 1280,
  },
};

export default breakpoints;
