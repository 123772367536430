// @types
import { AccessPermissionFullDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import RevokeAccessConfirmation from './RevokeAccessConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullDTO | null;
};

// ----------------------------------------------------------------------

export default function RevokeAccessDialog({ open, onClose, accessPermissionInfo }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <RevokeAccessConfirmation onClose={onClose} accessPermissionInfo={accessPermissionInfo} />
      }
    />
  );
}
