import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// redux
import {
  useFlushSiteCacheMutation,
  useLazyGetSiteCacheStatusQuery,
  useEnableSiteCacheMutation,
  useDisableSiteCacheMutation,
} from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import Toggle from 'src/components/gravity/form/Toggle';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

export default function CacheSettings() {
  const { namespace, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  // Flush cache
  const [flushSiteCache, { isLoading: flushCacheIsLoading }] = useFlushSiteCacheMutation();
  // Cache status enable/disable
  const [getSiteCacheStatus, { isLoading: cacheStatusIsLoading }] =
    useLazyGetSiteCacheStatusQuery();
  const [enableSiteCache, { isLoading: enableCacheIsLoading }] = useEnableSiteCacheMutation();
  const [disableSiteCache, { isLoading: disableCacheIsLoading }] = useDisableSiteCacheMutation();

  // STATE
  const [toggleCache, setToggleCache] = useState(true);
  const [cacheErr, setCacheErr] = useState<string | null>(null);

  // EVENT FUNCTION
  const handleFlushCache = () => {
    if (!namespace || !cluster) return;
    flushSiteCache(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (data.code === 0) {
          displayToast(
            translate('wponesupport.sites.details.support.cacheSettings.flushCache.toast.success')
          );
        } else {
          displayToast(
            translate('wponesupport.sites.details.support.cacheSettings.flushCache.toast.error'),
            { variant: 'alert' }
          );
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  };

  const handleToggleChange = () => {
    if (!namespace || !cluster) return;
    if (toggleCache) {
      disableSiteCache(`${cluster}/${namespace}`)
        .unwrap()
        .then((data) => {
          if (data.code === 0) {
            if (isMountedRef.current) {
              setToggleCache(false);
            }

            displayToast(
              translate(
                'wponesupport.sites.details.support.cacheSettings.cacheStatus.disableCache.toast.success'
              )
            );
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.support.cacheSettings.cacheStatus.disableCache.toast.error'
              ),
              { variant: 'alert' }
            );
          }
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    } else {
      enableSiteCache(`${cluster}/${namespace}`)
        .unwrap()
        .then((data) => {
          if (data.code === 0) {
            if (isMountedRef.current) {
              setToggleCache(true);
            }

            displayToast(
              translate(
                'wponesupport.sites.details.support.cacheSettings.cacheStatus.enableCache.toast.success'
              )
            );
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.support.cacheSettings.cacheStatus.enableCache.toast.error'
              ),
              { variant: 'alert' }
            );
          }
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    }
  };

  //
  useEffect(() => {
    if (!namespace || !cluster) return;
    getSiteCacheStatus(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (isMountedRef.current) {
          if (data.code === 0) {
            setToggleCache(false);
          } else {
            setToggleCache(true);
          }
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setCacheErr(
            translate(
              'wponesupport.sites.details.support.cacheSettings.cacheStatus.getCacheStatus.error'
            )
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsBlock title={translate('wponesupport.sites.details.support.cacheSettings.title')}>
      {/* FLUSH CACHE BUTTON */}
      <div className="gv-w-full gv-flex-column-md gv-tab-flex-row gv-tab-items-center">
        <p className="gv-text-sm">
          {translate('wponesupport.sites.details.support.cacheSettings.flushCache.description')}
        </p>
        <Button
          condensed
          text={translate('wponesupport.sites.details.support.cacheSettings.flushCache.action')}
          onClick={handleFlushCache}
          disabled={flushCacheIsLoading}
          style={{ whiteSpace: 'nowrap' }}
        />
      </div>

      {/* CACHE TOGGLE */}
      <Toggle
        condensed
        name="cache"
        label={translate(
          'wponesupport.sites.details.support.cacheSettings.cacheStatus.getCacheStatus.label'
        )}
        labelId="cache"
        description={translate(
          'wponesupport.sites.details.support.cacheSettings.cacheStatus.getCacheStatus.description'
        )}
        checked={toggleCache}
        onChange={handleToggleChange}
        disabled={
          cacheErr !== null || cacheStatusIsLoading || enableCacheIsLoading || disableCacheIsLoading
        }
        errMsg={cacheErr || undefined}
      />
    </SettingsBlock>
  );
}
