// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import ChangePrimaryHostnameForm from './ChangePrimaryHostnameForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  selectedDomain: string | null;
};

// ----------------------------------------------------------------------

export default function ChangePrimaryHostnameDialog({ open, onClose, selectedDomain }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<ChangePrimaryHostnameForm onClose={onClose} selectedDomain={selectedDomain} />}
    />
  );
}
