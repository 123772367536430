import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  UserUpdateResponse,
  WpInstallOptionGetResponse,
  WpInstallOptionsUpdateRequest,
} from '@joonasvanhatapio/wp-cloud-backend-types';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const wpInstallOptionsApi = createApi({
  reducerPath: 'wpInstallOptions',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/wp-install-options' }),
  tagTypes: ['WPInstallOptions'],
  endpoints: (builder) => ({
    //GET CURRENT INSTALL OPTIONS
    getCurrentInstallOptions: builder.query<WpInstallOptionGetResponse, null>({
      query: () => ({
        url: `/`,
        method: 'get',
      }),
      providesTags: (result) => (result ? [{ type: 'WPInstallOptions', id: 'CURRENT-USER' }] : []),
    }),
    //UPDATE INSTALL OPTIONS
    updateWPInstallOptions: builder.mutation<
      UserUpdateResponse,
      WpInstallOptionsUpdateRequest | {}
    >({
      // Pass in empty object when user does not have install options by default. This will create default install options for user
      query: (updates) => ({
        url: `/update`,
        method: 'put',
        data: updates,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'WPInstallOptions', id: 'CURRENT-USER' }] : [],
    }),
  }),
});

export const { useGetCurrentInstallOptionsQuery, useUpdateWPInstallOptionsMutation } =
  wpInstallOptionsApi;
