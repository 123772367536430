import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primaryOutlined: true;
  }
}

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primaryOutlined' },
          style: {
            border: '1px solid',
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            backgroundColor: 'transparent',
            '&:hover': {
              color: '#6F74E1',
              borderColor: '#6F74E1',
              backgroundColor: 'transparent',
            },
            '&:disabled': {
              color: '#9BA3EF',
              borderColor: '#9BA3EF',
              backgroundColor: 'transparent',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          minHeight: '40px',
          borderRadius: '8px',
          padding: '12px 24px',
          textAlign: 'center',
          gap: '10px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: '#6F74E1',
          },
          '&:disabled': {
            backgroundColor: '#9BA3EF',
            color: '#FFFFFF',
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#FFFFFF',
          },
        },
        containedError: {
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: '#F0001E',
          },
          '&:disabled': {
            backgroundColor: '#F2C7CD',
            color: '#FFFFFF',
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#FFFFFF',
          },
        },
        outlined: {
          color: theme.palette.text.primary,
          backgroundColor: 'transparent',
          borderColor: '#BBBBBB',
          '&:hover': {
            borderColor: theme.palette.text.primary,
            backgroundColor: 'transparent',
          },
          '&:disabled': {
            color: '#BBBBBB',
            backgroundColor: 'transparent',
            borderColor: '#BBBBBB',
          },
          '& .MuiLoadingButton-loadingIndicator': {
            color: '#FFFFFF',
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
