import { matchPath } from 'react-router-dom';
// @types
import { UserDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { AccessTypeEnum, BrandIdEnum, UserRoleEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

// What's new banner
export function isExceedThreeMonths(date: Date) {
  const today = new Date();
  const differenceInTime = today.getTime() - date.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays > 90;
}

// Side drawer
export function isActivePage(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false;
}

// Add site/domain action display
export function isAbleToAddResource(user: UserDTO | null, resourceType: 'site' | 'zone') {
  if (user?.role !== UserRoleEnum.customer) return true;

  if (!user) return false;

  const currentTotalSites =
    user.accessPermissions?.filter(
      (permission) =>
        permission.resource.type === resourceType && permission.accessType === AccessTypeEnum.owner
    ).length || 0;

  const resourceLimit =
    user.userResourceLimits?.find(
      (limit) => limit.limitType === (resourceType === 'site' ? 'sites' : 'zones')
    )?.limitValue || 0;

  return currentTotalSites < resourceLimit;
}

//
export function isHostnetCustomer(user: UserDTO | null) {
  if (user && user.brand?.id === BrandIdEnum.hostnet && user.role === UserRoleEnum.customer) {
    return true;
  }

  return false;
}
