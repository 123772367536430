// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Divider, Typography, Tooltip, Fade, IconButton } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import StatusBlock from 'src/components/StatusBlock';
import MaterialIcon from 'src/components/MaterialIcon';

// ----------------------------------------------------------------------

const HeaderStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 5, 0, 5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description?: string;
  displayStatus?: 'live' | 'staging' | null;
  actions?: React.ReactNode;
  content: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function Block({ title, description, displayStatus, actions, content }: Props) {
  const { translate } = useLocales();

  return (
    <Box>
      <HeaderStyle>
        <Stack
          flexGrow={1}
          spacing={2}
          direction={{ xs: 'row', sm: 'row' }}
          alignItems="center"
          justifyContent="flex-start"
          height={40}
        >
          <Stack direction="row" alignItems={'center'}>
            <Typography variant="h4" noWrap>
              {title}
            </Typography>
            {description && (
              <Tooltip
                title={translate(description)}
                placement="top"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 300 }}
              >
                <IconButton size="small" disableRipple={true}>
                  <MaterialIcon icon="help" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>

          {displayStatus && <StatusBlock status={displayStatus} />}
        </Stack>

        {actions && actions}
      </HeaderStyle>
      <Box sx={{ py: 4, px: 5 }}>{content}</Box>
      <Divider />
    </Box>
  );
}
