import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useSelector } from 'src/redux/store';
import { useAddSiteDomainMutation } from 'src/redux/api/siteApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { fTranslationDynamicKey } from 'src/utils/format';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  domain: string;
  afterSubmit?: string;
};

type Props = {
  site: ListSiteResponseDTO | undefined;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AddDomainForm({ onClose, site }: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addSiteDomain, { isLoading }] = useAddSiteDomainMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // FORM
  const defaultValues = {
    domain: '',
  };

  const AddDomainSchema = Yup.object().shape({
    domain: Yup.string().trim().required(translate('wpone.general.inputField.domain.validation')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AddDomainSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!site) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'add-domain',
    });

    await addSiteDomain({
      cluster: site.cluster.name,
      namespace: site.namespace,
      domainName: data.domain.trim(),
    })
      .unwrap()
      .then(() => {
        displayToast(translate('wpone.sites.details.domains.addDomainDialog.toast.success'));
        onClose();
      })
      .catch((error) => {
        // Error message check order: domain attached => general
        const errCode = (error as { status: number; data: any }).status;
        const errMsg = (error as { status: number; data: any }).data.message;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate([
              `wpone.sites.details.domains.addDomainDialog.toast.error.${fTranslationDynamicKey(
                errMsg
              )}`,
              `wpone.sites.details.domains.addDomainDialog.toast.error.${fTranslationDynamicKey(
                errMsg,
                'Referer or site owner must be an owner of the root domain *'
              )}`,
              convertStatusCode(errCode),
            ]),
          });
        } else {
          displayToast(
            translate([
              `wpone.sites.details.domains.addDomainDialog.toast.error.${fTranslationDynamicKey(
                errMsg
              )}`,
              `wpone.sites.details.domains.addDomainDialog.toast.error.${fTranslationDynamicKey(
                errMsg,
                'Referer or site owner must be an owner of the root domain *'
              )}`,
              convertStatusCode(errCode),
            ]),
            { variant: 'alert' }
          );
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.domains.addDomainDialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <p>
              {translate('wpone.sites.details.domains.addDomainDialog.description', {
                brandName: user?.brand?.name,
              })}
            </p>
            {!!errors.afterSubmit?.message && (
              <Alert text={errors.afterSubmit.message} type="alert" />
            )}

            <RHFTextField
              name="domain"
              label={translate('wpone.general.inputField.domain.label')}
              labelId="domain"
              placeholder="example.com"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.sites.details.domains.addDomainDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
