import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useDispatch } from 'src/redux/store';
import { addUpdatingVulns, removeUpdatingVulns } from 'src/redux/features/site';
// @types
import { UpdateVulnerabilityRow, UpdateVulnerabilityType } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { filterHighestVersion } from 'src/utils/vulnerabilities';
import { displayToast } from 'src/utils/handleToast';
// component
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  vulns: UpdateVulnerabilityRow[];
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function VulnerabilityUpdateConfirmation({ vulns, onClose }: Props) {
  const { name } = useParams();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  // VAR
  // Divide vulns into group based on type, select only highest update ver in one plugin, and set the order to severity desc
  // NOTE: "select only highest update ver" only for display in modals, other places like updating in progress btn is still applied for vulns with lower ver
  const coreUpdates: UpdateVulnerabilityRow[] = filterHighestVersion(
    vulns,
    UpdateVulnerabilityType.CORE
  );
  const pluginsUpdates: UpdateVulnerabilityRow[] = filterHighestVersion(
    vulns,
    UpdateVulnerabilityType.PLUGINS
  );
  const themesUpdates: UpdateVulnerabilityRow[] = filterHighestVersion(
    vulns,
    UpdateVulnerabilityType.THEMES
  );

  // EVENT FUNCTION
  function handleUpdate() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'install-wp-updates',
    });

    setTimeout(() => {
      dispatch(removeUpdatingVulns({ siteName: name as string, removeUpdatingVulns: vulns }));

      const failedUpdates: number = 0;

      if (failedUpdates === 0) {
        displayToast(
          translate('dashboard.sites.details.mwp.security.main.updateVulnDialog.toast.success')
        );
      } else {
        displayToast(
          translate(
            failedUpdates === vulns.length
              ? 'dashboard.sites.details.mwp.security.main.updateVulnDialog.toast.error.all'
              : 'dashboard.sites.details.mwp.security.main.updateVulnDialog.toast.error.partial'
          ),
          {
            variant: 'alert',
          }
        );
      }
    }, 5000);

    // Add updating vulns after starting the update
    dispatch(addUpdatingVulns({ siteName: name as string, newUpdatingVulns: vulns }));
    onClose();
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('dashboard.sites.details.mwp.security.main.updateVulnDialog.title')}
        </h2>

        <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
          <p>
            {translate(
              `dashboard.sites.details.mwp.security.main.updateVulnDialog.description.${
                vulns.length > 1 ? 'plural' : 'singular'
              }`
            )}
          </p>

          <div className="gv-flex-column-md">
            <UpdateVulnDetailsGroup type={UpdateVulnerabilityType.CORE} updates={coreUpdates} />

            <UpdateVulnDetailsGroup
              type={UpdateVulnerabilityType.PLUGINS}
              updates={pluginsUpdates}
            />

            <UpdateVulnDetailsGroup type={UpdateVulnerabilityType.THEMES} updates={themesUpdates} />
          </div>
        </div>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button text={translate('dashboard.general.action.update')} onClick={handleUpdate} />
      </ButtonGroup>
    </>
  );
}

// ----------------------------------------------------------------------

type UpdateVulnDetailsGroupProps = {
  type: UpdateVulnerabilityType;
  updates: UpdateVulnerabilityRow[];
};

// ----------------------------------------------------------------------

function UpdateVulnDetailsGroup({ type, updates }: UpdateVulnDetailsGroupProps) {
  // HOOK
  const { translate } = useLocales();

  if (updates.length === 0) return <></>;

  return (
    <div>
      <p className="gv-text-bold">
        {translate(
          `dashboard.sites.details.mwp.security.vulnType.${type}${
            type !== UpdateVulnerabilityType.CORE
              ? updates.length > 1
                ? '.plural'
                : '.singular'
              : ''
          }`
        )}
      </p>
      {updates.map((update) => (
        <p key={update.id}>
          {translate(
            `dashboard.sites.details.mwp.security.main.updateVulnDialog.details.${
              type === UpdateVulnerabilityType.CORE ? type : 'others'
            }`,
            {
              name: update.pluginDisplayName,
              current: update.currentVer,
              new: update.updateVer,
            }
          )}
        </p>
      ))}
    </div>
  );
}
