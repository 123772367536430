// @types
import { UpdateVulnerabilityRow } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import VulnerabilityTable from './table/VulnerabilityTable';

// ----------------------------------------------------------------------

type Props = {
  autoUpdateSetting: boolean;
  isFetching: boolean;
  unsortedTableData: UpdateVulnerabilityRow[];
  handleSelectUpdateVulnerabilities: (vulns: UpdateVulnerabilityRow[]) => void;
};

// ----------------------------------------------------------------------

export default function VulnerabilitiesBlock({
  autoUpdateSetting,
  isFetching,
  unsortedTableData,
  handleSelectUpdateVulnerabilities,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex-column-md">
      <div
        className="gv-flex gv-flex-col"
        style={{
          gap: '4px',
        }}
      >
        <p className="gv-text-lg gv-text-bold">
          {translate('dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.title')}
        </p>
        <p className="gv-text-sm">
          {translate(
            `dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.description.${
              unsortedTableData.length === 0
                ? 'none'
                : unsortedTableData.length === 1
                ? 'singular'
                : 'plural'
            }`,
            {
              number: unsortedTableData.length,
            }
          )}
        </p>
      </div>
      <VulnerabilityTable
        autoUpdateSetting={autoUpdateSetting}
        isFetching={isFetching}
        unsortedTableData={unsortedTableData}
        handleSelectUpdateVulnerabilities={handleSelectUpdateVulnerabilities}
      />
    </div>
  );
}
