import { createSlice } from '@reduxjs/toolkit';
// @types
import { AuthState } from 'src/@types/auth';

// ----------------------------------------------------------------------

const initialState: AuthState = {
  verificationEmail: null,
  user: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // SET VERIFICATION EMAIL
    setVerificationEmail(state, action) {
      state.verificationEmail = action.payload;
    },
    // SET USER
    setUser(state, action) {
      state.user = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setVerificationEmail, setUser } = slice.actions;
