import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router';
// redux
import { useGetBackupsQuery } from 'src/redux/api/backupApi';
// @types
import { GetBackupTimestampsResponseDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { TableHeader } from 'src/@types/layout';
import {
  BackupSettings,
  BackupTableRowProps,
  BackupTypeEnum,
  RestoreEnvironmentEnum,
  RestoreMethodEnum,
} from 'src/@types/site';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
//
import BackupsTableRow from './BackupsTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'date',
    label: 'wpone.sites.details.backups.table.header.date',
  },
  {
    id: 'availability',
    label: 'wpone.sites.details.backups.table.header.availability',
  },
  {
    id: 'type',
    label: 'wpone.sites.details.backups.table.header.type',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  isRestoring: boolean;
  setBackupSettings: Dispatch<SetStateAction<BackupSettings | null>>;
  onOpenRestoreBackupDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function BackupsTable2({
  isRestoring,
  setBackupSettings,
  onOpenRestoreBackupDialog,
}: Props) {
  const { name, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  const {
    page,
    order,
    rowsPerPage,
    //
    onChangePage,
  } = useGravityTable({
    defaultOrderBy: 'date',
    defaultOrder: 'desc',
    defaultRowsPerPage: 10,
  });

  // API
  const { data, isFetching } = useGetBackupsQuery({
    hostname: name as string,
    cluster: cluster as string,
  });

  // VAR
  const formattedData = convertDataToRows(data);
  // const formattedData = convertDataToRows(DUMMY_BACKUPS_DATA);

  // HELPER FUNCTION
  /** 
   * @param endpointData The response data from the get backup timestamps endpoint.
   * @returns An array of BackupTableRowProps objects representing all unique date FILES backups + all unique date Automated DB backups + all non-unique Manual DB backups + all DATABASE_AND_FILES backups.

  * FILES backup is one per day
  * DB backup can be multiple per day, the first one will be Automated, the rest will be Manual
  * DATABASE_AND_FILES backup is when there are both FILES backup and at least one DB backup on the same day (the DATABASE_AND_FILES backup will use the date of the DB backup)
  * If more than one DB backup then the first (Automated) will be used for DATABASE_AND_FILES, rest (Manual) stays the same 
  */
  function convertDataToRows(
    endpointData: GetBackupTimestampsResponseDTO | undefined
  ): BackupTableRowProps[] {
    if (!endpointData) return [];
    // This includes all backups, inluding the duplicates.
    const filesBackups = getFilesBackups(endpointData);
    const dbBackups = getDbBackups(endpointData);
    const dbAndFilesBackups = getDbAndFilesBackups(filesBackups, dbBackups);

    // Remove the backups from the filesBackups array if there are same date backups in the dbAndFilesBackups array.
    const filteredFilesBackups = filesBackups.filter((fileBackup) => {
      const hasSameDateBackup = dbAndFilesBackups.some(
        (dbAndFilesBackup) =>
          dbAndFilesBackup.date.toLocaleDateString('fi-FI') ===
          fileBackup.date.toLocaleDateString('fi-FI')
      );
      return !hasSameDateBackup;
    });

    // Remove the backups from the dbBackups array if there are same date backups in the dbAndFilesBackups array and the file type is automated.
    const filteredDbBackups = dbBackups.filter((dbBackup) => {
      const hasSameDateBackup = dbAndFilesBackups.some(
        (dbAndFilesBackup) =>
          dbAndFilesBackup.date.toLocaleDateString('fi-FI') ===
          dbBackup.date.toLocaleDateString('fi-FI')
      );
      return !(hasSameDateBackup && dbBackup.type === BackupTypeEnum.AUTOMATED);
    });

    const finalBackups = [...dbAndFilesBackups, ...filteredDbBackups, ...filteredFilesBackups];
    return finalBackups.sort((a, b) => {
      if (a.date.toLocaleDateString('fi-FI') === b.date.toLocaleDateString('fi-FI')) {
        if (a.available === b.available) {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        } else {
          return a.available === RestoreMethodEnum.both
            ? -1
            : a.available === RestoreMethodEnum.database
            ? 0
            : 1;
        }
      } else {
        return order === 'asc'
          ? new Date(a.date).getTime() - new Date(b.date).getTime()
          : new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
  }

  // Handle FILES backups
  /**
   * @param endpointData - The response data from the API endpoint.
   * @returns An array of BackupTableRowProps objects representing file backups.
   */
  function getFilesBackups(endpointData: GetBackupTimestampsResponseDTO): BackupTableRowProps[] {
    return endpointData.fileItems.map((timestamp) => ({
      date: new Date(timestamp * 1000),
      available: RestoreMethodEnum.files,
      type: BackupTypeEnum.AUTOMATED,
    }));
  }

  // Handle DB backups
  /**
   * @param endpointData - The response data from the API endpoint.
   * @returns An array of BackupTableRowProps objects representing database backups.
   */
  function getDbBackups(endpointData: GetBackupTimestampsResponseDTO): BackupTableRowProps[] {
    const dbCountsByDate = new Map<string, number>();
    // HELPER FUNCTION
    function createBackupTableRow(
      date: Date,
      env: RestoreEnvironmentEnum,
      fileName: string
    ): BackupTableRowProps {
      const dateString = date.toLocaleDateString('fi-FI');
      // Get the current count of backups for this date and increment it.
      const count = dbCountsByDate.get(dateString) || 0;
      dbCountsByDate.set(dateString, count + 1);

      const backupType = count === 0 ? BackupTypeEnum.AUTOMATED : BackupTypeEnum.MANUAL;
      // For the database backup type, the first Production backup of the day (if there is any, else a Staging backup) is Automated (here when count is 0)
      // Rest are Manual.
      return {
        date,
        available: RestoreMethodEnum.database,
        type: backupType,
        env,
        fileName,
      };
    }

    const prodDbBackups: BackupTableRowProps[] = endpointData.productionDatabaseItems.map(
      (dbItem) => {
        const date = new Date(dbItem.timestamp * 1000);
        return createBackupTableRow(date, RestoreEnvironmentEnum.production, dbItem.fileName);
      }
    );

    // Temporary: staging db backups will not be displayed in the table
    // const stagingDbBackups: BackupTableRowProps[] = endpointData.stagingDatabaseItems.map(
    //   (dbItem) => {
    //     const date = new Date(dbItem.timestamp * 1000);
    //     return createBackupTableRow(date, RestoreEnvironmentEnum.staging, dbItem.fileName);
    //   }
    // );
    // Merge the staging and production database items into a single array.
    return [...prodDbBackups];
  }

  // Handle DATABASE_AND_FILES backups
  /**
   * @param filesBackups - The response data from the getFilesBackups function.
   * @param dbBackups - The response data from the getDbBackups function.
   * @returns An array of BackupTableRowProps objects representing files and database backups.
   */
  function getDbAndFilesBackups(
    filesBackups: BackupTableRowProps[],
    dbBackups: BackupTableRowProps[]
  ): BackupTableRowProps[] {
    const dbAndFilesBackups: BackupTableRowProps[] = [];

    for (const dbBackup of dbBackups) {
      const dateString = dbBackup.date.toLocaleDateString('fi-FI');

      const filesBackup = filesBackups.find(
        (backup) => backup.date.toLocaleDateString('fi-FI') === dateString
      );

      if (dbBackup.type === BackupTypeEnum.AUTOMATED && filesBackup !== undefined) {
        dbAndFilesBackups.push({
          ...dbBackup,
          date: filesBackup.date,
          available: RestoreMethodEnum.both,
        });
      }
    }
    return dbAndFilesBackups;
  }

  return (
    <div className="gv-data-table">
      <div className="data-table-scroll-handler" style={{ overflowX: 'auto' }}>
        <table className="gv-col-4-shrink">
          <TableHeadCustom headers={HEADERS} />
          <tbody>
            {!isFetching
              ? formattedData
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((row, index) => (
                    <BackupsTableRow
                      key={index}
                      row={row}
                      isRestoring={isRestoring}
                      setBackupSettings={setBackupSettings}
                      onOpenRestoreBackupDialog={onOpenRestoreBackupDialog}
                    />
                  ))
              : [...Array(rowsPerPage)].map((_, index) => (
                  <TableSkeleton key={index} headers={HEADERS} />
                ))}
          </tbody>
        </table>
      </div>

      {!isFetching && formattedData.length === 0 && (
        <div className="gv-empty-state">
          <div className="gv-empty-state-content">
            <h3 className="gv-title">
              {translate('wpone.sites.details.backups.table.noData.title')}
            </h3>
            <p>{translate('wpone.sites.details.backups.table.noData.description')}</p>
          </div>
        </div>
      )}

      <TablePagination
        totalRows={formattedData.length}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
      />
    </div>
  );
}
