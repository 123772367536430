// @types
import { UpdateVulnerabilityRow } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import VulnerabilityUpdateConfirmation from './VulnerabilityUpdateConfirmation';

// ----------------------------------------------------------------------

type Props = {
  vulns: UpdateVulnerabilityRow[];
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function VulnerabilityUpdateDialog({ vulns, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<VulnerabilityUpdateConfirmation vulns={vulns} onClose={onClose} />}
    />
  );
}
