import { useState, useEffect, useMemo, memo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import ReactGA from 'react-ga4';
// redux
import { useSelector } from 'src/redux/store';
import { useLazySearchSitesQuery } from 'src/redux/api/siteApi';
import { useLazySearchDomainsQuery } from 'src/redux/api/domainApi';
// @mui
import {
  Typography,
  Stack,
  Box,
  TextField,
  Popper,
  PopperProps,
  useTheme,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import useClickOutsideEffect from 'src/hooks/useClickOutside';
// utils
import { convertToIDN } from 'src/utils/convert';
// config
import { NAV_CONFIG } from 'src/config';
// components
import MaterialIcon from 'src/components/MaterialIcon';
import SearchNotFound from 'src/components/SearchNotFound';

// ----------------------------------------------------------------------

type SearchOptionProps = {
  id: string;
  cluster?: string;
  namespace?: string;
  group: 'sites' | 'dns';

  // Site hostname, additional domain, or domain
  name: string;

  // Site domain in case the name is additional domain
  fromSite?: string;
};

type Props = {
  openSearchbar: boolean;
  handleCloseSearchbar: VoidFunction;
};

// ----------------------------------------------------------------------

function Searchbar({ openSearchbar, handleCloseSearchbar }: Props) {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const theme = useTheme();

  const searchbarRef = useRef<HTMLDivElement>(null);

  const searchbarPopperRef = useRef<HTMLDivElement>(null);

  const headerEleRef = useRef<HTMLElement>(document.getElementById('header'));

  const headerMenuIconEleRef = useRef<HTMLElement>(document.getElementById('header-menu-icon'));

  const headerAvatarIconEleRef = useRef<HTMLElement>(document.getElementById('header-avatar-icon'));

  // HOOKS
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'desktop_min');

  useClickOutsideEffect({
    outsideRefs: [searchbarRef, searchbarPopperRef, headerEleRef],
    insideRefs: [headerMenuIconEleRef, headerAvatarIconEleRef],
    handleClickingOutside: handleCloseSearchbar,
    shouldListen: openSearchbar,
  });

  // API
  const [searchSites, { data: sitesData, isFetching: sitesIsFetching, isError: sitesIsError }] =
    useLazySearchSitesQuery();

  const [
    searchDomains,
    { data: domainsData, isFetching: domainsIsFetching, isError: domainsIsError },
  ] = useLazySearchDomainsQuery();

  // STATE
  const { headerHeight } = useSelector((state) => state.layout);

  const [data, setData] = useState<SearchOptionProps[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isError, setIsError] = useState(false);

  const [filterName, setFilterName] = useState('');

  // VAR
  // inputRef to prevent filtername reset after loses focus
  const inputRef = useRef<HTMLElement>(null);

  // HELPER FUNCTION
  const getSearch = useMemo(
    () =>
      debounce((searchInput) => {
        if (searchInput) {
          searchSites(searchInput);
          searchDomains(searchInput);
          ReactGA.event('search', {
            search_term: searchInput,
          });
        }
      }, 300),
    [searchSites, searchDomains]
  );

  // EVENT FUNCTION
  const handleFilterName = (newFilterName: string) => {
    setIsLoading(true);
    getSearch(newFilterName.toLocaleLowerCase().trim());
    setFilterName(newFilterName);
  };

  //
  useEffect(() => {
    if (!sitesIsFetching && !domainsIsFetching) {
      setIsLoading(false);
      if (sitesIsError || domainsIsError) {
        setIsError(true);
      } else {
        if (!sitesData || !domainsData) {
          setData([]);
        } else {
          let formattedSitesData: SearchOptionProps[] = [];

          sitesData.sites.forEach((site) => {
            // If site name includes the keyword, then add it to the result
            if (
              convertToIDN(site.hostname).includes(
                convertToIDN(filterName.toLocaleLowerCase().trim())
              )
            ) {
              formattedSitesData.push({
                id: `site-${site.hostname}`,
                group: 'sites',
                name: site.hostname,
                cluster: site.cluster.name,
                namespace: site.namespace,
              });
            }

            // Then check every additional domain this site has, if it includes the keyword, add it to the result as well
            site.additional_domains.forEach((additionalDomain) => {
              if (
                convertToIDN(additionalDomain.domain).includes(
                  convertToIDN(filterName.toLocaleLowerCase().trim())
                )
              ) {
                formattedSitesData.push({
                  id: `additional-domain-${additionalDomain.domain}`,
                  group: 'sites',
                  name: additionalDomain.domain,
                  fromSite: site.hostname,
                  cluster: site.cluster.name,
                  namespace: site.namespace,
                });
              }
            });
          });

          // Get the first 10 results for site, then append the dns results
          setData(
            formattedSitesData.slice(0, 10).concat(
              domainsData.zones.map((domain, index) => ({
                id: `domains-${index}`,
                group: 'dns',
                name: domain.name,
              }))
            )
          );
        }
      }
    } else {
      setIsLoading(true);
      setData([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesIsFetching, domainsIsFetching]);

  useEffect(
    () => () => {
      handleCloseSearchbar(); // Cannot be inside deps array since it will force this to rerun everytime component is rerender
      getSearch.cancel();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getSearch, pathname]
  );

  return (
    <Box sx={{ p: isDesktop ? 0 : '16px 24px' }}>
      <Autocomplete
        ref={searchbarRef}
        open={filterName.trim() !== ''}
        PopperComponent={(props: PopperProps) => (
          <Popper
            ref={searchbarPopperRef}
            {...props}
            placement="bottom-start"
            sx={{
              p: '0px !important',
              width: isDesktop
                ? `${isLoading && data.length === 0 ? 400 : 464}px !important`
                : '100% !important',
              top: '8px !important',
              '& .MuiPaper-root': {
                padding: `${isDesktop && isLoading && data.length === 0 ? 16 : 0}px ${
                  isDesktop && isLoading && data.length === 0 ? 16 : 0
                }px !important`,
                boxShadow: isDesktop ? '0px 4px 16px 0px rgba(60, 60, 60, 0.10)' : 'none',
                borderRadius: 0,
                '& .MuiAutocomplete-listbox': {
                  padding: '0 !important',
                },
                '& .MuiAutocomplete-noOptions': {
                  padding: '0 !important',
                },
                '& > *': {
                  maxHeight: isDesktop ? 'auto' : `calc(100vh - ${headerHeight}px) !important`,
                  height: isDesktop
                    ? isLoading
                      ? '196px'
                      : 'auto'
                    : `calc(100vh - ${headerHeight}px) !important`,
                },
              },
            }}
          />
        )}
        inputValue={filterName}
        onInputChange={(event, value) => {
          if (isDesktop || (!isDesktop && inputRef.current === document.activeElement)) {
            handleFilterName(value);
            setData([]);
          }
        }}
        value={null}
        onChange={(event, value) => {
          setFilterName('');
          setData([]);
          if (!value) return;

          if (value.group === 'sites') {
            navigate(
              `/sites/overview/${value.cluster}/${value.namespace}/${value.fromSite || value.name}`
            );
          } else {
            navigate(`/dns/records/${value.name}`);
          }
        }}
        blurOnSelect
        noOptionsText={
          isLoading ? (
            <Typography
              variant="body2"
              sx={{
                height: '100%',
                p: 3,
                my: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#141414',
              }}
            >
              {translate('wpone.globalSearch.optionLoading')}
            </Typography>
          ) : isError ? (
            <Typography variant="body2" align="center" sx={{ p: 3 }}>
              {translate('wpone.general.requestResponse.errorMessage.other')}
            </Typography>
          ) : (
            <SearchNotFound searchQuery={filterName} />
          )
        }
        options={data}
        groupBy={(option) => translate(option.group)}
        filterOptions={(x) => x}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            name="siteName"
            inputRef={inputRef}
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setFilterName('');
                    setData([]);
                    if (filterName.trim() !== '') {
                      handleCloseSearchbar();
                    }
                  }}
                >
                  <MaterialIcon icon={filterName === '' ? 'search' : 'close'} size={20} />
                </InputAdornment>
              ),
            }}
            sx={{
              width: isDesktop ? '240px' : '100%',
              '& .MuiOutlinedInput-root': {
                height: '30px',
                p: '4px 20px !important',
                borderRadius: '100px',
                border: '1px solid #E2E2E2',
                backgroundColor: '#F7F7F7',
                '& .MuiInputAdornment-root': {
                  cursor: 'pointer',
                  width: '16px',
                  height: '16px',
                  marginLeft: '16px',
                },
              },
              '& .MuiOutlinedInput-input': {
                ...theme.typography.body2,
                height: '22px',
                p: '0 !important',
              },
            }}
          />
        )}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Box
              component="li"
              sx={{
                pt: params.group === 'dns' ? 1 : 0,
                pb: 1,
                m: `${isDesktop ? 8 : 16}px 24px 0 24px`,
                ...theme.typography.caption,
                fontWeight: 600,
                borderBottom: '1px solid #CECECE',
              }}
            >
              {translate(`wpone.globalSearch.groupLabel.${params.group}`)}
            </Box>
            <Box>{params.children}</Box>
          </Box>
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={option.id}
            sx={{
              p: `${isDesktop ? 8 : 16}px 24px !important`,
            }}
          >
            <Stack
              direction="row"
              alignItems={'center'}
              spacing={1}
              sx={{
                maxWidth: '100%',
              }}
            >
              {option.group === 'sites' ? (
                <MaterialIcon icon={NAV_CONFIG[0].icon} size={20} sx={{ flex: '0 0 auto' }} />
              ) : (
                <MaterialIcon icon={NAV_CONFIG[1].icon} size={20} sx={{ flex: '0 0 auto' }} />
              )}
              {isDesktop ? (
                <SearchOptionHighlight
                  text={option.name}
                  subtext={filterName.trim()}
                  fromSite={option.fromSite}
                />
              ) : (
                <Stack direction={'column'}>
                  <SearchOptionHighlight
                    text={option.name}
                    subtext={filterName.trim()}
                    fromSite={option.fromSite}
                  />
                  {option.fromSite && (
                    <Typography
                      variant="caption"
                      noWrap
                      sx={{
                        color: '#6D6D6D',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {translate('wpone.globalSearch.additonalDomainOption', {
                        site: option.fromSite,
                      })}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
          </Box>
        )}
      />
    </Box>
  );
}

export default memo(Searchbar);

// ----------------------------------------------------------------------

type SearchOptionHighlightProps = {
  text: string;
  subtext: string;
  fromSite: string | undefined;
};

const SearchOptionHighlight = ({ text, subtext, fromSite }: SearchOptionHighlightProps) => {
  // HOOKS
  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'desktop_min');

  // HELPER FUNCTION
  function getHighlightedText() {
    let remainingText = text;
    const highlightedTextComponents = [];
    let keyCounter = 0;
    const lowercasedSubtext = subtext.toLowerCase();

    while (remainingText.toLowerCase().indexOf(lowercasedSubtext) !== -1) {
      const startIndex = remainingText.toLowerCase().indexOf(lowercasedSubtext);
      const endIndex = startIndex + subtext.length;
      const beforeSubtext = remainingText.slice(0, startIndex);
      const highlightedSubtext = remainingText.slice(startIndex, endIndex);
      highlightedTextComponents.push(beforeSubtext);
      highlightedTextComponents.push(
        <span key={keyCounter} style={{ backgroundColor: '#D9EBF7' }}>
          {highlightedSubtext}
        </span>
      );
      keyCounter++;
      remainingText = remainingText.slice(endIndex);
    }

    if (remainingText) {
      highlightedTextComponents.push(remainingText);
    }

    return highlightedTextComponents;
  }

  // Only for desktop screen
  function getTextForTooltip() {
    if (fromSite && isDesktop) {
      return `${text} - ${translate('wpone.globalSearch.additonalDomainOption', {
        site: fromSite,
      })}`;
    } else {
      return text;
    }
  }

  return (
    <Tooltip
      title={getTextForTooltip()}
      placement="top"
      disableHoverListener={!isDesktop || getTextForTooltip().length < 52}
    >
      <Typography variant="caption" noWrap sx={{ textOverflow: 'ellipsis' }}>
        {getHighlightedText()}
        {fromSite && isDesktop
          ? ` - ${translate('wpone.globalSearch.additonalDomainOption', {
              site: fromSite,
            })}`
          : ''}
      </Typography>
    </Tooltip>
  );
};
