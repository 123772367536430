import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  GetBackupTimestampsResponseDTO,
  GetRestoreStatusResponseDTO,
  InitiateRestoreResponseDTO,
  CancelRestoreResponseDTO,
  RestoreRequestDTO,
} from '@joonasvanhatapio/wp-cloud-backend-types';
// utils
import { convertToIDN } from 'src/utils/convert';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const backupApi = createApi({
  reducerPath: 'backupApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/backup' }),
  tagTypes: ['Backup'],
  endpoints: (builder) => ({
    // GET ALL BACKUPS
    getBackups: builder.query<
      GetBackupTimestampsResponseDTO,
      { hostname: string; cluster: string }
    >({
      query: ({ hostname, cluster }) => {
        const searchParams = new URLSearchParams();

        searchParams.append('cluster', cluster);

        const searchParamsString = searchParams.toString();

        // BACKUP STAGING TEST
        // return {
        //   url: `/get-backup-timestamps/norvanera.fi?cluster=wp-cloud-europe-north1-a-7`,
        //   method: 'get',
        // };

        return {
          url: `/get-backup-timestamps/${convertToIDN(hostname)}?${searchParamsString}`,
          method: 'get',
        };
      },
    }),
    // ENQUEUE RESTORATION
    initiateRestoration: builder.mutation<InitiateRestoreResponseDTO, RestoreRequestDTO>({
      query: (data) => ({
        url: `/restore/${convertToIDN(data.hostname)}`,
        method: 'post',
        data: {
          ...data,
          hostname: convertToIDN(data.hostname),
        },
      }),
    }),
    // GET RESTORE STATUS
    getRestorationStatus: builder.query<
      GetRestoreStatusResponseDTO,
      { hostname: string; backupId: number }
    >({
      query: ({ hostname, backupId }) => ({
        url: `/get-backup-status/${convertToIDN(hostname)}/${backupId}`,
        method: 'get',
      }),
    }),
    // CANCEL RESTORE PROGRESS
    cancelRestoration: builder.mutation<
      CancelRestoreResponseDTO,
      { hostname: string; backupId: number }
    >({
      query: ({ hostname, backupId }) => ({
        url: `/cancel/${convertToIDN(hostname)}/${backupId}`,
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetBackupsQuery,
  useGetRestorationStatusQuery,
  useInitiateRestorationMutation,
  useCancelRestorationMutation,
} = backupApi;
