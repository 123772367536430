import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
// redux
import { useLazyGetSiteLogQuery } from 'src/redux/api/siteApi';
// utils
import { displayToast } from 'src/utils/handleToast';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// components
import { Select } from 'src/components/gravity/form';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

enum LogOption {
  access = 'access',
  error = 'error',
  mail = 'mail',
  debug = 'debug',
}

// ----------------------------------------------------------------------

export default function LogSettings() {
  const { cluster, namespace } = useParams();

  const logContainerRef = useRef<HTMLDivElement>(null);

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  const [logOption, setLogOption] = useState<LogOption>(LogOption.error);
  const [logData, setLogData] = useState<string | null>(null);

  // API
  const [getSiteLog, { isFetching }] = useLazyGetSiteLogQuery();

  // EVENT FUNCTION
  function handleSelectLogOption(event: ChangeEvent<HTMLSelectElement>) {
    setLogData(null);
    setLogOption(event.target.value as LogOption);
  }

  //
  useEffect(() => {
    if (!namespace || !cluster) return;
    getSiteLog({ siteId: `${cluster}/${namespace}`, logOption: logOption })
      .unwrap()
      .then((data) => {
        if (data.code === 0) {
          if (isMountedRef.current) {
            setLogData(data.stdout || null);

            if (logContainerRef.current) {
              logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
            }
          }
        } else {
          displayToast(
            translate('wponesupport.sites.details.support.logSettings.toast.error', {
              logOption: translate(
                `wponesupport.sites.details.support.logSettings.options.${logOption}`
              ),
            }),
            {
              variant: 'alert',
            }
          );
        }
      })
      .catch(() => {
        displayToast(
          translate('wponesupport.sites.details.support.logSettings.toast.error', {
            logOption: translate(
              `wponesupport.sites.details.support.logSettings.options.${logOption}`
            ),
          }),
          {
            variant: 'alert',
          }
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOption]);

  return (
    <SettingsBlock title={translate('wponesupport.sites.details.support.logSettings.title')}>
      <div className="gv-flex-column-md">
        <div className="gv-flex-row-md gv-items-center">
          <p className="gv-text-sm">
            {translate('wponesupport.sites.details.support.logSettings.description')}
          </p>
          <Select
            value={logOption}
            onChange={handleSelectLogOption}
            options={Object.values(LogOption).map((option) => ({
              value: option,
              label: translate(`wponesupport.sites.details.support.logSettings.options.${option}`),
            }))}
            disabled={isFetching}
            condensed
          />
        </div>

        {logData && (
          <div
            ref={logContainerRef}
            className="gv-content-container gv-p-lg"
            style={{ maxHeight: '500px', overflowY: 'auto' }}
          >
            <pre className="gv-text-sm" style={{ whiteSpace: 'pre-wrap' }}>
              {logData}
            </pre>
          </div>
        )}
      </div>
    </SettingsBlock>
  );
}
