// @mui
import { Alert, AlertProps, SxProps } from '@mui/material';
// components
import MaterialIcon from './MaterialIcon';
// hooks
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

interface Props extends AlertProps {
  severity: 'info' | 'success' | 'warning' | 'error';
  size?: number;
  sx?: SxProps;
}

// ----------------------------------------------------------------------

export default function AlertBanner({ severity, size, sx, ...other }: Props) {
  const isExtraSmall = useResponsive('down', 'sm');

  // Display function
  function getAlertIconName() {
    switch (severity) {
      case 'info':
        return 'info';
      case 'success':
        return 'check_circle';
      default:
        return 'error';
    }
  }

  return (
    <Alert
      severity={severity}
      icon={<MaterialIcon icon={getAlertIconName()} size={size} sx={{ color: 'inherit' }} />}
      sx={{
        position: 'relative',
        flexDirection: isExtraSmall ? 'column' : 'row',
        alignItems: isExtraSmall ? 'flex-start' : 'center',
        '.MuiAlert-icon': {
          padding: 0,
          marginRight: '18px',
          marginBottom: isExtraSmall ? '12px' : 0,
          alignItems: 'center',
        },
        '.MuiAlert-message': {
          width: '100%',
        },
        '.MuiAlert-action': isExtraSmall
          ? {
              margin: 0,
              padding: 0,
              position: 'absolute',
              top: 16,
              right: 16,
            }
          : { margin: 'auto -8px auto auto', padding: '0 0 0 16px' },
        ...sx,
      }}
      {...other}
    />
  );
}
