import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// config
import { HEADER } from 'src/config';
// components
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
}));

// ----------------------------------------------------------------------

export default function LogoLayout() {
  // HOOK
  const isDesktop = useResponsive('up', 'desktop_min');

  return (
    <>
      <HeaderStyle>
        <Container
          sx={{
            height: HEADER.HEIGHT,
            maxWidth: '1536px !important',
            px: `${isDesktop ? 48 : 24}px !important`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Logo />
        </Container>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
