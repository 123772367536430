import { ReactNode } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
import { useGetSiteQuery } from 'src/redux/api/siteApi';
// @types
import {
  InstallationProvider,
  SiteBillingEnum,
  SitePlanEnum,
  SiteStatusEnum,
} from 'src/@types/site';
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useHighestUserRole from 'src/hooks/useHighestUserRole';
// utils
import { filterAllowedSiteDetailsTabs } from 'src/utils/layout';
// config
import { WPONE_SITE_DETAILS_NAV_CONFIG, MWP_SITE_DETAILS_NAV_CONFIG } from 'src/config';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function SiteDetailsGuard({ children }: Props) {
  const { pathname } = useLocation();

  const { cluster, namespace, name } = useParams();

  // HOOK
  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // API
  const { data: site, isFetching: isSiteFetching } = useGetSiteQuery({
    namespace: namespace as string,
    cluster: cluster as string,
  });

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const tabname = pathname.split('/')[2];

  //
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if ((user.role === UserRoleEnum.customer && isHighestUserRoleLoading) || isSiteFetching) {
    return <LoadingScreen />;
  }

  if (!site || name !== site.hostname) {
    return <Navigate to="/404" replace />;
  }

  const navConfig = (
    site.provider === InstallationProvider.WP_ONE
      ? WPONE_SITE_DETAILS_NAV_CONFIG
      : MWP_SITE_DETAILS_NAV_CONFIG
  ).filter((nav) => {
    // Extra filter for hiding plan tab for even support role (WP.one)
    if (
      site.provider === InstallationProvider.WP_ONE &&
      (site.plan.name === SitePlanEnum.internal || site.billing.name === SiteBillingEnum.none)
    ) {
      return nav.tabName !== 'plan';
    }
    return true;
  });

  // First check if tab exists in the specific site provider
  const isTabExisted = navConfig.some((nav) => nav.tabName === tabname);

  if (!isTabExisted) {
    return <Navigate to="/404" replace />;
  }
  // Then check if user has access to the tab
  const isAllowed =
    site.state === SiteStatusEnum.suspended && user.role === UserRoleEnum.customer
      ? tabname === 'overview'
      : filterAllowedSiteDetailsTabs(navConfig, user, highestUserRole).some(
          (tab) => tab.tabName === tabname
        );

  if (!isAllowed) {
    return <Navigate to="/403" replace />;
  }

  return <>{children}</>;
}
