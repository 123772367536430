import { useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useLazyGetPasswordsQuery } from 'src/redux/api/siteApi';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { PasswordInputState } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';
import SettingsDetails from './SettingsDetails';

// ----------------------------------------------------------------------

enum DatabaseType {
  STAGING = 'staging',
  LIVE = 'live',
}

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function DatabaseAccessSettingsBlock({ site }: Props) {
  // HOOK
  const { translate } = useLocales();

  // EVENT FUNCTION
  function handleOpenPhpMyAdmin() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'open-phpmyadmin',
    });

    window.open(site?.phpmyadmin);
  }

  return (
    <SettingsBlock
      title="wpone.sites.details.settings.databaseAccess.title"
      description="wpone.sites.details.settings.databaseAccess.description"
      content={
        <div className="gv-flex-column-md">
          <DatabaseDetails type={DatabaseType.LIVE} site={site} />
          <div className="gv-divider" />
          <DatabaseDetails type={DatabaseType.STAGING} site={site} />
        </div>
      }
      action={
        <Button
          condensed
          text={translate('wpone.sites.details.settings.databaseAccess.action')}
          onClick={handleOpenPhpMyAdmin}
          endIcon="open_in_new"
        />
      }
    />
  );
}

// ----------------------------------------------------------------------

type DatabaseDetailsProps = { type: DatabaseType; site: ListSiteResponseDTO };

// ----------------------------------------------------------------------

function DatabaseDetails({ type, site }: DatabaseDetailsProps) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // API
  const [
    getPasswords,
    {
      data: getPasswordsData, // When this is defined, default pw "1234" has already been replaced AND always be latest update
    },
  ] = useLazyGetPasswordsQuery();

  // STATE
  const [password, setPassword] = useState<PasswordInputState>({
    value: '1234',
    isHidden: true,
  });

  // EVENT FUNCTION
  // Show/Copy password
  function handleGetPassword(isCopy: boolean) {
    if (!namespace || !cluster) return;

    if (password.isHidden) {
      if (getPasswordsData) {
        if (isCopy) {
          navigator.clipboard.writeText(
            type === DatabaseType.LIVE
              ? getPasswordsData.mysql.production.password
              : getPasswordsData.mysql.staging.password
          );

          displayToast(translate('wpone.general.action.copied'));
        } else {
          setPassword((prev) => ({
            ...prev,
            isHidden: false,
          }));
        }
      } else {
        getPasswords({ cluster: cluster, namespace: namespace }, true)
          .unwrap()
          .then((res) => {
            const newPw =
              type === DatabaseType.LIVE
                ? res.mysql.production.password
                : res.mysql.staging.password;

            if (isCopy) {
              navigator.clipboard.writeText(newPw);
              displayToast(translate('wpone.general.action.copied'));

              setPassword((prev) => ({ ...prev, value: newPw }));
            } else {
              setPassword((prev) => ({ ...prev, value: newPw, isHidden: false }));
            }
          })
          .catch(() => {
            displayToast(
              translate(
                'wpone.sites.details.settings.databaseAccess.requestResponse.errorMessage.getCurrentPassword'
              ),
              { variant: 'alert' }
            );
          });
      }
    } else {
      if (isCopy) {
        navigator.clipboard.writeText(password.value);
        displayToast(translate('wpone.general.action.copied'));
      } else {
        setPassword((prev) => ({
          ...prev,
          isHidden: true,
        }));
      }
    }
  }

  return (
    <div className="gv-flex-column-sm">
      <p className="gv-text-sm gv-text-bold">
        {translate(`wpone.sites.details.settings.databaseAccess.infoLabel.type.${type}`)}
      </p>
      <div
        className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-4`}
        style={{
          gridRowGap: '16px',
          gridColumnGap: '24px',
        }}
      >
        <SettingsDetails
          title="wpone.sites.details.settings.databaseAccess.infoLabel.host"
          value={site.database_configuration.instance}
          copiable
        />
        <SettingsDetails
          title="wpone.sites.details.settings.databaseAccess.infoLabel.name"
          value={
            type === DatabaseType.LIVE
              ? site.database_configuration.production_username
              : site.database_configuration.staging_username
          }
          copiable
        />
        <SettingsDetails
          title="wpone.sites.details.settings.databaseAccess.infoLabel.username"
          value={
            type === DatabaseType.LIVE
              ? site.database_configuration.production_username
              : site.database_configuration.staging_username
          }
          copiable
        />
        <SettingsDetails
          title="wpone.sites.details.settings.databaseAccess.infoLabel.password"
          value={password.value}
          password={{
            isHidden: password.isHidden,
            onShow: () => handleGetPassword(false),
            onCopy: () => handleGetPassword(true),
          }}
        />
      </div>
    </div>
  );
}
