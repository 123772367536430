import { pxToRem } from 'src/utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Plus Jakarta Sans, sans-serif'; // Google Font

declare module '@mui/material/styles' {
  interface TypographyVariants {
    intro: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    intro?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: false;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: true;
    body2: true;
    button: false;
    overline: false;
    intro: true;
  }
}

const typography = {
  fontFamily: FONT_PRIMARY,
  h2: {
    fontWeight: 400,
    fontSize: pxToRem(40),
    lineHeight: '50px',
    letterSpacing: 0.25,
  },
  h3: {
    fontWeight: 400,
    fontSize: pxToRem(32),
    lineHeight: '40px',
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 400,
    fontSize: pxToRem(24),
    lineHeight: '30px',
    letterSpacing: 0.15,
    '@media (max-width:768px)': {
      fontWeight: 600,
      fontSize: pxToRem(16),
      letterSpacing: 0.3,
      lineHeight: '22px',
    },
  },
  h5: {
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: '24px',
    letterSpacing: 0.15,
  },
  intro: {
    fontWeight: 400,
    fontSize: pxToRem(18),
    lineHeight: '28px',
    letterSpacing: 0,
  },
  // Regular ==> Typography default
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: '24px',
    letterSpacing: 0.5,
  },
  // Compact
  body2: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: '22px',
    letterSpacing: 0.25,
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: '18px',
    letterSpacing: 0.4,
  },
  // Compact/Bold
  button: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: '22px',
    letterSpacing: 0.25,
    textTransform: 'initial',
  },
} as const;

export default typography;
