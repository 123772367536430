import { InputHTMLAttributes } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
//
import CheckBox from '../form/Checkbox';
// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label?: string;
  labelId?: string; // Required for label
  description?: string;
  mixed?: boolean;
  condensed?: true;
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

export default function RHFCheckbox({
  name,
  label,
  labelId,
  description,
  mixed,
  condensed,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CheckBox
          {...field}
          checked={field.value}
          label={label}
          labelId={labelId}
          description={description}
          mixed={mixed}
          errMsg={error?.message}
          condensed={condensed}
          {...other}
        />
      )}
    />
  );
}
