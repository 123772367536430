import { ReactNode } from 'react';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function SettingsBlock({ title, children }: Props) {
  return (
    <div className="gv-flex-column-md">
      <p className="gv-heading-sm">{title}</p>

      <div className="gv-flex-column-md gv-items-start gv-pl-md">{children}</div>
    </div>
  );
}
