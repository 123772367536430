// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import NewFeatureContent from './newFeatureContent';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function NewFeatureDialog({ open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<NewFeatureContent onClose={onClose} />}
    />
  );
}
