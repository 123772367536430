import { createApi } from '@reduxjs/toolkit/query/react';
// @types
import {
  DeleteMigrationRequestDto,
  DeleteResponseDTO,
  EnqueueRequestDTO,
  EnqueueResponseDTO,
  GetMigrationProgressResponseDTO,
  SshKeysRequestDTO,
} from '@joonasvanhatapio/wp-cloud-backend-types';
import { MigrationSshKeyResponse } from 'src/@types/site';
import { MigrationDetails, OverallMigrationDataResponse } from 'src/@types/data';
// utils
import { convertToIDN } from 'src/utils/convert';
import { fHttpPrefix } from 'src/utils/format';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const migrationApi = createApi({
  reducerPath: 'migrationApi',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v5/migration' }),
  tagTypes: ['Migration'],
  endpoints: (builder) => ({
    // GET SSH KEY MIGRATION INFO
    getSshKeyMigrationInfo: builder.query<
      MigrationSshKeyResponse,
      { destinationDomain: string; siteId: string; request: SshKeysRequestDTO }
    >({
      query: ({ destinationDomain, siteId, request }) => {
        // Handle params
        const params = new URLSearchParams();
        params.append('destinationDomain', convertToIDN(destinationDomain));
        params.append('siteId', siteId);
        const paramsString = params.toString();

        // Handle body req
        const sourceDomain = fHttpPrefix(request.sourceUrl);
        const idnSourceDomain = convertToIDN(sourceDomain);
        const idnSourceUrl = request.sourceUrl.replace(sourceDomain, idnSourceDomain);

        return {
          url: `/ssh-key?${paramsString}`,
          method: 'post',
          data: {
            sourceUrl: idnSourceUrl,
          },
        };
      },
    }),
    // ENQUEUE MIGRATION
    enqueueMigration: builder.mutation<EnqueueResponseDTO, EnqueueRequestDTO>({
      query: (data) => {
        const sourceDomain = fHttpPrefix(data.sourceUrl);

        const idnSourceDomain = convertToIDN(sourceDomain);

        const idnSourceUrl = data.sourceUrl.replace(sourceDomain, idnSourceDomain);

        return {
          url: `/enqueue`,
          method: 'post',
          data: {
            ...data,
            destinationDomain: convertToIDN(data.destinationDomain),
            sourceUrl: idnSourceUrl,
          },
        };
      },
    }),
    // ABORT MIGRATION
    abortMigration: builder.mutation<DeleteResponseDTO, DeleteMigrationRequestDto>({
      query: (data) => ({
        url: `/delete`,
        method: 'delete',
        data: {
          ...data,
          destinationDomain: convertToIDN(data.destinationDomain),
        },
      }),
    }),
    // GET MIGRATION STATUS
    getMigrationStatus: builder.query<GetMigrationProgressResponseDTO, string>({
      query: (destinationDomain) => ({
        url: `/status?destinationDomain=${convertToIDN(destinationDomain)}`,
        method: 'get',
      }),
    }),
    // GET OVERALL MIGRATION DATA
    getOverallMigrationData: builder.query<OverallMigrationDataResponse, { brand: string }>({
      query: ({ brand }) => ({
        url: `/stats/count/${brand}`,
        method: 'get',
      }),
    }),
    // GET DETAILED MIGRATION DATA
    getDetailedMigrationData: builder.query<MigrationDetails[], { brand: string }>({
      query: ({ brand }) => ({
        url: `/stats/details/${brand}`,
        method: 'get',
      }),
      transformResponse: (response: MigrationDetails[]) =>
        response.map((migration) => ({
          ...migration,
          started: new Date(migration.started),
          ended: new Date(migration.ended),
        })),
    }),
  }),
});

export const {
  useLazyGetSshKeyMigrationInfoQuery,
  useEnqueueMigrationMutation,
  useAbortMigrationMutation,
  useGetMigrationStatusQuery,
  useGetOverallMigrationDataQuery,
  useGetDetailedMigrationDataQuery,
} = migrationApi;
