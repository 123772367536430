import { ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description: string;
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function SettingsBlock({ title, description, children }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex-column-md">
      <div className="gv-flex gv-flex-col gv-text-max" style={{ gap: '4px' }}>
        <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
        <p className="gv-text-sm">{translate(description)}</p>
      </div>
      {children}
    </div>
  );
}
