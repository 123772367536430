import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#D1E9FC',
  light: '#76B0F1',
  main: '#5866E4', //FIGMA UPDATED COLOR -- THEME PRIMARY COLOR
  dark: '#103996',
  darker: '#061B64',
  contrastText: '#fff',
};
const INFO = {
  lighter: '#D9EBF7', //FIGMA UPDATED COLOR -- INFO BANNER BACKGROUND
  light: '#fff',
  main: '#0078C8', //FIGMA UPDATED COLOR -- INFO BANNER ICON COLOR
  dark: '#fff',
  darker: '#fff',
};
const SUCCESS = {
  lighter: '#DDEDCA', //FIGMA UPDATED COLOR -- SUCCESS BANNER BACKGROUND
  light: '#fff',
  main: '#05736D', //FIGMA UPDATED COLOR -- SUCCESS BANNER ICON COLOR
  dark: '#fff',
  darker: '#fff',
};
const WARNING = {
  lighter: '#FFEDD9', //FIGMA UPDATED COLOR -- WARNING BANNER BACKGROUND
  light: '#fff',
  main: '#E85E0F', //FIGMA UPDATED COLOR -- WARNING BANNER ICON COLOR
  dark: '#fff',
  darker: '#fff',
};
const ERROR = {
  lighter: '#F8D9DD', //FIGMA UPDATED COLOR -- ERROR BANNER BACKGROUND
  light: '#fff',
  main: '#D20019', //FIGMA UPDATED COLOR -- ERROR BANNER ICON COLOR
  dark: '#fff',
  darker: '#fff',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_12],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: '#141414', secondary: '#637381', disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  // We will be using this in the later version
  // dark: {
  //   ...COMMON,
  //   mode: 'dark',
  //   text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
  //   background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
  //   action: { active: GREY[500], ...COMMON.action },
  // },
} as const;

export default palette;
