import { useState } from 'react';
// @mui
import { Box, BoxProps, SxProps } from '@mui/material';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  icon: string;
  size?: number;
  fill?: true;
  sx?: SxProps;
}

// ----------------------------------------------------------------------

export default function MaterialIcon({ icon, size, fill, sx, ...other }: Props) {
  const isMountedRef = useIsMountedRef();

  // STATE
  const [fontLoaded, setFontLoaded] = useState(false);

  // Check if the font is loaded and set visibility based on fontLoaded state
  document.fonts.ready.then(() => {
    if (isMountedRef.current) {
      setFontLoaded(true);
    }
  });

  return (
    <Box
      sx={{
        '& .material-symbols-outlined': {
          fontVariationSettings: `'FILL' ${fill ? 1 : 0}, 'wght' 300`,
          fontSize: size || 24,
          visibility: fontLoaded ? 'visible' : 'hidden',
          maxWidth: size || 24,
          maxHeight: size || 24,
        },
        color: (theme) => theme.palette.text.primary,
        ...sx,
      }}
      {...other}
    >
      <span className="material-symbols-outlined" style={{ display: 'block' }}>
        {icon}
      </span>
    </Box>
  );
}
