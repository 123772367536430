// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import CustomPlanConfirmation from './CustomPlanConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function CustomPlanDialog({ open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<CustomPlanConfirmation onClose={onClose} />}
    />
  );
}
