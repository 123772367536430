// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import AddDomainForm from './AddDomainForm';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO | undefined;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AddDomainDialog({ open, onClose, site }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<AddDomainForm onClose={onClose} site={site} />}
    />
  );
}
