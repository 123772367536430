// redux
import { useSelector } from 'src/redux/store';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { handleZendeskChatOpen } from 'src/utils/zendesk';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function CustomPlanConfirmation({ onClose }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  function handleContactSupport() {
    handleZendeskChatOpen(user?.brand?.id);
    onClose();
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.plan.customPlanDialog.title')}
        </h2>

        <p style={{ overflowX: 'auto' }}>
          {translate('wpone.sites.details.plan.customPlanDialog.description')}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.contactSupport')}
          onClick={handleContactSupport}
        />
      </ButtonGroup>
    </>
  );
}
