import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { UserRoleEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

type Props = {
  allowRoles: UserRoleEnum[];
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function PageGuard({ allowRoles, children }: Props) {
  // STATE
  const { user } = useSelector((state) => state.auth);

  //
  if (!user || !allowRoles.includes(user.role)) {
    return <Navigate to="/403" replace />;
  }

  return <>{children}</>;
}
