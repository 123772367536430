import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
//
import PageLayout from './PageLayout';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  title: string;
  noPadding?: boolean; // CheckEmail - 403 - 404
  nested?: boolean; // Nested page will not have min height since the outer page already handled this
  fullWidth?: boolean; // Login
  meta?: ReactNode;
};

// ----------------------------------------------------------------------

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', noPadding, nested, fullWidth, meta }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | WP.one`}</title>
        {meta}
      </Helmet>

      <div className="gv-activated">
        {fullWidth ? (
          <div ref={ref}>{children}</div> // Haven't converted to gravity => haven't checked
        ) : (
          <PageLayout ref={ref} noPadding={noPadding} nested={nested}>
            {children}
          </PageLayout>
        )}
      </div>
    </>
  )
);

export default Page;
