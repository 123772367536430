// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useResponsive from 'src/hooks/useResponsive';
//
import SettingsBlock from './SettingsBlock';
import SettingsDetails from './SettingsDetails';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function BasicDetailsSettingsBlock({ site }: Props) {
  // HOOK
  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <SettingsBlock
      title="wpone.sites.details.settings.basicDetails.title"
      description="wpone.sites.details.settings.basicDetails.description"
      content={
        <div className="gv-flex-column-md">
          <div
            className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-4`}
            style={{
              gridRowGap: '16px',
              gridColumnGap: '24px',
            }}
          >
            <SettingsDetails
              title="wpone.sites.details.settings.basicDetails.infoLabel.dataCentreLocation"
              value={site.cluster.location}
            />
            <SettingsDetails
              title="wpone.sites.details.settings.basicDetails.infoLabel.cluster"
              value={site.cluster.name}
              copiable
            />
            <SettingsDetails
              title="wpone.sites.details.settings.basicDetails.infoLabel.siteIpAddress"
              value={site.cluster.address}
              copiable
            />
            <SettingsDetails
              title="wpone.sites.details.settings.basicDetails.infoLabel.path"
              value={site.installation_path}
              copiable
            />
          </div>

          <SettingsDetails
            title="wpone.sites.details.settings.basicDetails.infoLabel.outgoingIpAddresses"
            value="34.88.101.71, 34.88.138.128, 34.88.89.12, 35.228.1.6"
            tooltip="wpone.sites.details.settings.basicDetails.tooltip.outgoingIpAddresses"
            copiable
          />
        </div>
      }
    />
  );
}
