import { createApi } from '@reduxjs/toolkit/query/react';
// types
import { UserCallbackDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
//
import { axiosBaseQuery } from './axiosBaseQuery';

// ----------------------------------------------------------------------

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v5',
  }),
  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    // GET MAGIC LINK CALLBACK
    getMagicLinkCallback: builder.query<
      { user: UserCallbackDTO; access_token: string; refresh_token: string },
      string
    >({
      query: (token) => {
        const searchParams = new URLSearchParams();

        searchParams.append('token', token);

        const searchParamsString = searchParams.toString();

        return {
          url: `/auth/magiclogin/callback?${searchParamsString}`,
          method: 'get',
        };
      },
    }),
    // REQUEST FOR MAGIC LINK
    requestMagicLink: builder.mutation<void, string>({
      query: (email) => ({
        url: `/login`,
        method: 'post',
        data: { destination: email },
      }),
    }),
    // LOGOUT
    logout: builder.mutation<{ statusCode: number; message: string }, void>({
      query: () => ({
        url: `/logout`,
        method: 'post',
      }),
    }),
  }),
});

export const { useGetMagicLinkCallbackQuery, useRequestMagicLinkMutation, useLogoutMutation } =
  authApi;
