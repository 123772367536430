import { Dispatch, SetStateAction, useState } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
import useBeforeUnload from 'src/hooks/useBeforeUnload';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Loader from 'src/components/gravity/Loader';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  setIsRepairWordpressCoreSucceed: Dispatch<SetStateAction<boolean | null>>;
};

// ----------------------------------------------------------------------

export default function RepairWordpressCoreComfirmation({
  onClose,
  setIsRepairWordpressCoreSucceed,
}: Props) {
  // STATE
  const [isLoading, setisLoading] = useState(false);

  // HOOK
  const { translate } = useLocales();

  useBeforeUnload({ isDirty: isLoading });

  // EVENT HANDLER
  const handleRepairNow = async () => {
    setisLoading(true);
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve('Fetch successful');
          // reject('Fetch failed');
        }, 3000);
      });

      setIsRepairWordpressCoreSucceed(true);
    } catch (error) {
      setIsRepairWordpressCoreSucceed(false);
    } finally {
      setisLoading(false);
      onClose();
    }
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            `dashboard.sites.details.mwp.overview.repairWordpressCoreDialog${
              isLoading ? '.inProgress' : ''
            }.title`
          )}
        </h2>

        <div style={{ overflowX: 'auto' }}>
          <div className="gv-flex-column-sm">
            <p>
              {translate(
                `dashboard.sites.details.mwp.overview.repairWordpressCoreDialog${
                  isLoading ? '.inProgress' : ''
                }.description`,
                { URL: 'https://dummy/url' }
              )}
            </p>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="gv-text-center">
          <Loader condensed />
        </div>
      ) : (
        <ButtonGroup>
          <Button
            text={translate('dashboard.general.action.cancel')}
            uiType="cancel"
            onClick={onClose}
          />
          <Button
            text={translate(
              'dashboard.sites.details.mwp.overview.repairWordpressCoreDialog.action'
            )}
            onClick={handleRepairNow}
          />
        </ButtonGroup>
      )}
    </>
  );
}
