import { ZendeskAPI } from 'react-zendesk';
// @types
import { BrandIdEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

export function handleZendeskChatOpen(userBrandId: BrandIdEnum | undefined) {
  if (!userBrandId) return;

  if (userBrandId === BrandIdEnum.metanet) {
    ZendeskAPI('messenger', 'open');
    ZendeskAPI('messenger', 'show');
  } else {
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
  }
}
