import { useEffect, useRef, useState } from 'react';
// redux
import { useDispatch } from 'src/redux/store';
import { toggleScrollLock } from 'src/redux/features/layout';
// hooks
import useResponsive from 'src/hooks/useResponsive';
//
import ContextualMenu from './ContextualMenu';

// ----------------------------------------------------------------------

type MenuItem = {
  isIndicated?: boolean;
  isActive?: boolean;
  icon: string;
  text: string;
  function: () => void;
};

type MenuSection = {
  items: MenuItem[];
};

type Props = {
  sections: MenuSection[];
};

// ----------------------------------------------------------------------

export default function TableMoreMenu({ sections }: Props) {
  const dispatch = useDispatch();

  const iconRef = useRef<HTMLDivElement>(null);

  // HOOK
  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  // EVENT FUNCTION
  function handleOpenMenu() {
    setIsMenuOpen(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseMenu() {
    setIsMenuOpen(false);
    dispatch(toggleScrollLock(false));
  }

  //
  // Handle contextual menu layer bug on iphone devices
  useEffect(() => {
    if (!isMobile) return;

    const dataTableScrollHandlerElements = Array.from(
      document.getElementsByClassName('data-table-scroll-handler') as HTMLCollectionOf<HTMLElement>
    );

    if (isMenuOpen) {
      for (let i = 0; i < dataTableScrollHandlerElements.length; i++) {
        dataTableScrollHandlerElements[i].style.overflowX = 'hidden';
      }
    } else {
      for (let i = 0; i < dataTableScrollHandlerElements.length; i++) {
        dataTableScrollHandlerElements[i].style.overflowX = 'auto';
      }
    }
  }, [isMenuOpen, isMobile]);

  useEffect(
    () => () => {
      dispatch(toggleScrollLock(false));
    },
    [dispatch]
  );

  return (
    <div>
      <div
        ref={iconRef}
        onClick={isMenuOpen ? handleCloseMenu : handleOpenMenu}
        style={{ cursor: 'pointer' }}
      >
        <gv-icon src="/src/icons/more_horiz.svg" />
      </div>

      <ContextualMenu
        actionRef={iconRef}
        sections={sections}
        open={isMenuOpen}
        onClose={handleCloseMenu}
      />
    </div>
  );
}
