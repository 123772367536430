// @types
import { TableHeader } from 'src/@types/layout';
import { UpdateDetailsInfo, UpdateDetailsTypeEnum } from 'src/@types/site';
// components
import { TableHeadCustom } from 'src/components/gravity/table';
//
import UpdateDetailsTableRow from './UpdateReportTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'name',
    label: 'wpone.sites.details.updates.updateReport.table.header.name',
  },
  {
    id: 'status',
    label: 'wpone.sites.details.updates.updateReport.table.header.status',
  },
  {
    id: 'type',
    label: 'wpone.sites.details.updates.updateReport.table.header.type',
  },
  {
    id: 'version',
    label: 'wpone.sites.details.updates.updateReport.table.header.version',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  tableData: UpdateDetailsInfo[];
};

// ----------------------------------------------------------------------

export default function UpdateReportTable({ tableData }: Props) {
  // VAR
  const filteredData: UpdateDetailsInfo[] = tableData;

  return (
    <div
      className="gv-data-table"
      style={{
        overflowX: 'auto',
      }}
    >
      <table className="gv-col-5-text-right">
        <TableHeadCustom headers={HEADERS} />
        <tbody>
          {filteredData.map((row) => (
            <UpdateDetailsTableRow
              key={`${row.type}${row.type === UpdateDetailsTypeEnum.CORE ? '' : row.details.title}`}
              row={row}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
