// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import EditWPMemoryLimitForm from './EditWPMemoryLimitForm';

// ----------------------------------------------------------------------

type Props = {
  currentMemoryLimit: number;
  handleEditWpMemoryLimit: (newLimit: number) => void;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function EditWPMemoryLimitDialog({
  currentMemoryLimit,
  handleEditWpMemoryLimit,
  open,
  onClose,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <EditWPMemoryLimitForm
          handleEditWpMemoryLimit={handleEditWpMemoryLimit}
          currentMemoryLimit={currentMemoryLimit}
          onClose={onClose}
        />
      }
    />
  );
}
