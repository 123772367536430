import { Theme } from '@mui/material/styles';
//
import Alert from './Alert';
import Button from './Button';
import Card from './Card';
import CssBaseline from './CssBaseline';
import Dialog from './Dialog';
import Input from './Input';
import Link from './Link';
import List from './List';
import Table from './Table';
import Typography from './Typography';
import Tooltip from './Tooltip';
import MenuItem from './MenuItem';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Alert(theme),
    Button(theme),
    Card(theme),
    CssBaseline(theme),
    Dialog(theme),
    Link(theme),
    Input(theme),
    List(theme),
    Table(theme),
    Typography(theme),
    Tooltip(theme),
    MenuItem(theme)
  );
}
