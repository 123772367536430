import { useState } from 'react';
import useIsMountedRef from './useIsMountedRef';

// ----------------------------------------------------------------------

export default function useToggle(defaultChecked?: boolean) {
  const [toggle, setToggle] = useState(defaultChecked || false);

  const isMountedRef = useIsMountedRef();

  const onCloseHandler = () => {
    if (isMountedRef.current) {
      setToggle(false);
    }
  };

  return {
    toggle,
    onToggle: () => setToggle(!toggle),
    onOpen: () => setToggle(true),
    onClose: onCloseHandler,
    setToggle,
  };
}