import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

type Props = {
  type?: 'alert' | 'info' | 'success' | 'warning' | 'default'; // Only 4 main signal colors and 1 default color currently
  text?: string;
  direction?: 'right' | 'left'; // Only set for text
  textStyles?: CSSProperties;
  extraClass?: string;
};

// ----------------------------------------------------------------------

// Only dot indicator and text with dot indicator is handled here
// Icon with dot indicator will be handled by adding the class directly to the container of the icon
export default function Indicator({
  type = 'info',
  text,
  direction = 'left',
  textStyles,
  extraClass,
}: Props) {
  // VAR
  const backgroundColor =
    type === 'default' ? 'var(--color-border)' : `var(--color-signal-${type})`;

  return text ? (
    <div className={`gv-text-indicator${extraClass ? ` ${extraClass}` : ''}`}>
      {direction === 'left' && <div className="gv-indicator" style={{ backgroundColor }} />}
      <span style={textStyles}>{text}</span>
      {direction === 'right' && <div className="gv-indicator" style={{ backgroundColor }} />}
    </div>
  ) : (
    <div className="gv-indicator" style={{ backgroundColor }} />
  );
}
