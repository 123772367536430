import { ReactNode, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

// ----------------------------------------------------------------------

type Position =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

type Props = {
  tooltip: string;
  children: ReactNode;
  width?: 'auto' | number;
  position?: Position;
  arrow?: Position;
};

// ----------------------------------------------------------------------

export default function Tooltip({
  tooltip,
  children,
  width = 'auto',
  position = 'top-center',
  arrow,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // STATE
  const [showTooltip, setShowTooltip] = useState(false);

  const [coords, setCoords] = useState({ top: 0, left: 0 });

  //
  useEffect(() => {
    const updateMenuPosition = () => {
      if (!wrapperRef.current || !tooltipRef.current) return;

      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let menuTopPos: number = 0;
      let menuLeftPos: number = 0;

      switch (position) {
        case 'top-left':
          menuTopPos = wrapperRect.top - tooltipRect.height;
          menuLeftPos = wrapperRect.left;
          break;
        case 'top-center':
          menuTopPos = wrapperRect.top - tooltipRect.height;
          menuLeftPos = wrapperRect.left - tooltipRect.width / 2 + wrapperRect.width / 2;
          break;
        case 'top-right':
          menuTopPos = wrapperRect.top - tooltipRect.height;
          menuLeftPos = wrapperRect.right - tooltipRect.width;
          break;
        case 'bottom-left':
          menuTopPos = wrapperRect.bottom;
          menuLeftPos = wrapperRect.left;
          break;
        case 'bottom-center':
          menuTopPos = wrapperRect.bottom;
          menuLeftPos = wrapperRect.left - tooltipRect.width / 2 + wrapperRect.width / 2;
          break;
        case 'bottom-right':
          menuTopPos = wrapperRect.bottom;
          menuLeftPos = wrapperRect.right - tooltipRect.width;
          break;
        default:
          menuTopPos = wrapperRect.bottom;
          menuLeftPos = wrapperRect.right - tooltipRect.width;
      }

      const viewportWidth = window.innerWidth;

      // Adjust horizontal position if it overflows on the right
      if (menuLeftPos + tooltipRect.width > viewportWidth) {
        menuLeftPos = viewportWidth - tooltipRect.width - 10;
      }

      // Adjust horizontal position if it overflows on the left
      if (menuLeftPos < 0) {
        menuLeftPos = 10;
      }

      setCoords({ top: menuTopPos, left: menuLeftPos });
    };

    // Initial update of the menu position
    updateMenuPosition();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  return (
    <>
      <div
        ref={wrapperRef}
        className="tooltip-wrapper"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>

      {showTooltip &&
        createPortal(
          <div className="gv-activated">
            <div
              ref={tooltipRef}
              role="tooltip"
              className={`gv-tooltip${arrow ? ` gv-arrow-${arrow}` : ''}`}
              style={{
                width: width === 'auto' ? width : `${width}px`,
                top: coords.top,
                left: coords.left,
                cursor: 'text',
                pointerEvents: 'none',
              }}
            >
              <p>{tooltip}</p>
            </div>
          </div>,
          document.body
        )}
    </>
  );
}
