import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch } from 'src/redux/store';
import { useChangeSiteUserRoleMutation } from 'src/redux/api/resourceApi';
import { userApi } from 'src/redux/api/userApi';
// form
import { useForm } from 'react-hook-form';
// @types
import { AccessPermissionFullDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { AccessTypeEnum } from 'src/@types/user';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField, RHFSelect } from 'src/components/gravity/hook-form';
import Alert from 'src/components/gravity/Alert';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  role: AccessTypeEnum;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullDTO | null;
};

// ----------------------------------------------------------------------

export default function ChangeUserRoleForm({ onClose, accessPermissionInfo }: Props) {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [changeSiteUserRole, { isLoading }] = useChangeSiteUserRoleMutation();

  // FORM
  const defaultValues = {
    email: accessPermissionInfo?.user.email || '',
    role: accessPermissionInfo?.accessType || AccessTypeEnum.admin,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!data.email || !name || !cluster) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'change-user-role',
    });

    await changeSiteUserRole({
      userEmail: data.email,
      accessType: data.role,
      siteId: `${cluster}/${namespace}`,
    })
      .unwrap()
      .then((data) => {
        if (data.success) {
          displayToast(translate('wpone.general.requestResponse.access.successMessage.editAccess'));

          // For getting latest highest user role on Site User permissions and data in Customer table (for support/service)
          dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));

          onClose();
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate('wpone.general.requestResponse.errorMessage.other'),
            });
          } else {
            displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
              variant: 'alert',
            });
          }
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.users.changeUserRoleDialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <p>{translate('wpone.sites.details.users.changeUserRoleDialog.description')}</p>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}
            <RHFTextField
              name="email"
              label={translate('wpone.general.inputField.email.label')}
              labelId="user-email"
              disabled
            />
            <RHFSelect
              name="role"
              label={translate('wpone.general.inputField.userRole.label')}
              labelId="user-role"
              options={Object.values(AccessTypeEnum)
                .slice(1)
                .map((option) => ({
                  label: translate(`wpone.general.inputField.userRole.option.${option}`),
                  value: option,
                }))}
            />
          </div>
        </FormProvider>
      </div>
      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.confirm')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
