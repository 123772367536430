import { useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useResetSiteMutation } from 'src/redux/api/siteApi';
import { useSelector } from 'src/redux/store';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  domainName: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ResetSiteForm({ onClose }: Props) {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [resetSite, { isLoading }] = useResetSiteMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // FORM
  const FormSchema = Yup.object().shape({
    domainName: Yup.string()
      .required(
        translate(
          'wpone.sites.details.settings.siteAdministration.resetSite.dialog.inputField.validation1'
        )
      )
      .test(
        'domain-name',
        translate(
          'wpone.sites.details.settings.siteAdministration.resetSite.dialog.inputField.validation2'
        ),
        function (value) {
          if (value === name) {
            return true;
          }
          return false;
        }
      ),
  });

  const defaultValues = {
    domainName: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async () => {
    if (!cluster || !namespace) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'reset-site',
    });

    resetSite({
      cluster: cluster,
      namespace: namespace,
      wpAdminEmail: user?.wpInstallOptions?.adminEmail || '',
    })
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          displayToast(
            translate(
              'wpone.sites.details.settings.siteAdministration.resetSite.dialog.requestResponse.successMessage'
            )
          );
          onClose();
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.requestResponse.errorMessage'
              ),
            });
          } else {
            displayToast(
              translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.requestResponse.errorMessage'
              ),
              { variant: 'alert' }
            );
          }
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.siteAdministration.resetSite.dialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <p>
              {translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.description',
                {
                  domain: name,
                }
              )}
            </p>

            <Alert
              type="info"
              text={translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.info'
              )}
            />

            <p>
              {translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.confirmation',
                {
                  domain: name,
                }
              )}
            </p>

            <RHFTextField
              name="domainName"
              label={translate(
                'wpone.sites.details.settings.siteAdministration.resetSite.dialog.inputField.label'
              )}
              labelId="domain-name"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate(
            'wpone.sites.details.settings.siteAdministration.resetSite.dialog.action'
          )}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
