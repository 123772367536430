import { useEffect, useState } from 'react';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import { setLastReadFeatureVersion } from 'src/redux/features/layout';
import { useUpdateUserLastReadFeatureMutation } from 'src/redux/api/userApi';
// @mui
import { Skeleton } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useImagePreloader from 'src/hooks/useImagePreloader';
// config
import { FEATURE_RELEASE } from 'src/config';
// components
import Button from 'src/components/gravity/Button';
// assets
import migrationImg from 'src/assets/new_features/migration.svg';
import backupRestoreImg from 'src/assets/new_features/backup.svg';

// ----------------------------------------------------------------------

type NewFeatureContentProps = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function NewFeatureContent({ onClose }: NewFeatureContentProps) {
  const dispatch = useDispatch();

  // VAR
  const preloadSrcList: string[] = [migrationImg, backupRestoreImg];

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const { imagesPreloaded } = useImagePreloader(preloadSrcList);

  // API
  const [updateUserLastReadFeatureVersion] = useUpdateUserLastReadFeatureMutation();

  // STATE
  const { lastReadFeatureVersion, displayFeatureArr } = useSelector((state) => state.layout);

  const [activeIndex, setActiveIndex] = useState(0);

  // EVENT FUNCTION
  function handleNavigation(newIndex: number) {
    if (displayFeatureArr[newIndex].version > lastReadFeatureVersion) {
      dispatch(setLastReadFeatureVersion(displayFeatureArr[newIndex].version));
    }

    setActiveIndex(newIndex);
  }

  //
  useEffect(
    () => () => {
      // Set the data to 1.0 to reset for testing purpose
      if (!isMountedRef.current) {
        updateUserLastReadFeatureVersion({ lastReadFeatureVersion: lastReadFeatureVersion });
      }
    },

    [lastReadFeatureVersion, updateUserLastReadFeatureVersion, isMountedRef]
  );

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">{translate('wpone.whatsNewModal.title')}</h2>

        <div style={{ overflowX: 'hidden' }}>
          <div
            className="gv-flex"
            style={{
              transform: `translateX(-${activeIndex * 100}%)`,
              transition: 'transform 300ms',
            }}
          >
            {displayFeatureArr.map((feature, index) => (
              <div
                className="gv-flex-column-sm"
                key={index}
                style={{
                  flex: '0 0 100%',
                  opacity: activeIndex === index ? 1 : 0,
                }}
              >
                {imagesPreloaded ? (
                  <img
                    alt={feature.name}
                    src={
                      preloadSrcList[
                        FEATURE_RELEASE.findIndex(
                          (featureInfo) => featureInfo.name === feature.name
                        )
                      ]
                    }
                    style={{ width: '100%' }}
                  />
                ) : (
                  <Skeleton variant="rectangular" height={350} sx={{ width: '100%' }} />
                )}
                <p className="gv-modal-subtitle gv-mt-lg">{translate(feature.text.title)}</p>
                <p>{translate(feature.text.description)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Dialog header and footer style are set globally */}
      <div style={{ overflowX: 'auto' }}>
        {displayFeatureArr.length > 1 ? (
          <div className="gv-modal-progress">
            <Button
              uiType="cancel"
              text={translate('wpone.general.action.previous')}
              onClick={() => handleNavigation(activeIndex - 1)}
              disabled={activeIndex === 0}
            />
            <div className="gv-progress-bullets">
              {displayFeatureArr.map((_, index) => (
                <span
                  key={index}
                  className={
                    index < activeIndex
                      ? 'gv-bullet-done'
                      : index === activeIndex
                      ? 'gv-bullet-current'
                      : ''
                  }
                />
              ))}
            </div>
            <Button
              text={translate(
                activeIndex + 1 !== displayFeatureArr.length
                  ? 'wpone.general.action.next'
                  : 'wpone.whatsNewModal.close'
              )}
              onClick={
                activeIndex + 1 !== displayFeatureArr.length
                  ? () => handleNavigation(activeIndex + 1)
                  : onClose
              }
            />
          </div>
        ) : (
          <Button text={translate('wpone.whatsNewModal.close')} onClick={onClose} />
        )}
      </div>
    </>
  );
}
