import { ReactNode, TextareaHTMLAttributes } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
//
import Textarea from '../form/Textarea';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  addon?: ReactNode;
  condensed?: boolean;
};

type Props = IProps & TextareaHTMLAttributes<HTMLTextAreaElement>;

// ----------------------------------------------------------------------

export default function RHFTextarea({
  name,
  label,
  labelId,
  labelOptional,
  labelIcon,
  labelIconTooltip,
  addon,
  condensed,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Textarea
          {...field}
          label={label}
          labelId={labelId}
          labelOptional={labelOptional}
          labelIcon={labelIcon}
          labelIconTooltip={labelIconTooltip}
          inputErr={!!error}
          errMsg={error?.message}
          addon={addon}
          condensed={condensed}
          {...other}
        />
      )}
    />
  );
}
