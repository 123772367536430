import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { MigrationDetails, MigrationState, MigrationStatus } from 'src/@types/site';

// ----------------------------------------------------------------------

const initialState: MigrationState = {
  migratingAfterAddingSite: null,
  //
  currentMigrationDetails: null,
  migrationsInProgress: [],
};

const slice = createSlice({
  name: 'migration',
  initialState,
  reducers: {
    // SET HOSTNAME FOR MIGRATING AFTER ADDING
    setMigratingAfterAddingSite(state, action) {
      state.migratingAfterAddingSite = action.payload;
    },
    // SET CURRENT MIGRATION DETAILS
    setCurrentMigrationDetails(state, action: PayloadAction<null | MigrationDetails>) {
      state.currentMigrationDetails = action.payload;
    },
    // ADD MIGRATION IN PROGRESS
    addMigrationInProgress(state, action: PayloadAction<MigrationDetails>) {
      state.migrationsInProgress = [...state.migrationsInProgress, action.payload];
    },
    // REMOVE MIGRATION IN PROGRESS
    removeMigrationInProgress(state, action: PayloadAction<MigrationDetails>) {
      state.migrationsInProgress = state.migrationsInProgress.filter(
        (migration) => migration.destinationSite !== action.payload.destinationSite
      );
    },
    // CHANGE MIGRATION STATUS
    changeMigrationStatus(
      state,
      action: PayloadAction<{
        destinationSite: string;
        newStatus: MigrationStatus;
        endedAt: Date;
        failedMessage?: string;
      }>
    ) {
      // Modify current migration details
      if (state.currentMigrationDetails) {
        state.currentMigrationDetails = {
          ...state.currentMigrationDetails,
          currentStatus: action.payload.newStatus,
          endedAt: action.payload.endedAt,
          failedMessage: action.payload.failedMessage,
        };
      }

      // Modify migration in progress arr
      state.migrationsInProgress = state.migrationsInProgress.map((migrationDetails) => {
        if (migrationDetails.destinationSite !== action.payload.destinationSite) {
          return migrationDetails;
        }

        return {
          ...migrationDetails,
          currentStatus: action.payload.newStatus,
          endedAt: action.payload.endedAt,
          failedMessage: action.payload.failedMessage,
        };
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setMigratingAfterAddingSite,
  setCurrentMigrationDetails,
  addMigrationInProgress,
  removeMigrationInProgress,
  changeMigrationStatus,
} = slice.actions;
