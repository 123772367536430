// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import UpdatePHPVersionForm from './UpdatePHPVersionForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  currentPhpVersion: number;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdadePHPVersionDialog({ open, currentPhpVersion, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<UpdatePHPVersionForm onClose={onClose} currentPhpVersion={currentPhpVersion} />}
    />
  );
}
