import MD5 from 'crypto-js/md5';

// ----------------------------------------------------------------------

export default function generateGravatarUrl(email: string): string {
  const defaultURI = 'https://app.wpcloud.one/assets/user_icon_png.png';
  const encoded = encodeURIComponent(defaultURI);
  const input = email.trim().toLowerCase();
  const hash = MD5(input).toString();

  return `https://www.gravatar.com/avatar/${hash}?d=${encoded}`;
}
