import { useNavigate, useParams } from 'react-router';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import IconWrapper from 'src/components/gravity/IconWrapper';

// ----------------------------------------------------------------------

export default function ActionsBlock() {
  const navigate = useNavigate();

  const { name, cluster, namespace } = useParams();

  return (
    <div className="gv-flex gv-tab-lg-flex-row gv-flex-col gv-gap-md gv-w-full">
      <Action
        iconName="format_list_bulleted"
        title="dashboard.sites.details.mwp.security.main.actionsBlock.vulnerabilityLog.title"
        description="dashboard.sites.details.mwp.security.main.actionsBlock.vulnerabilityLog.description"
        onClick={() => {
          navigate(`/sites/security/${cluster}/${namespace}/${name}/vulnerability-log`);
        }}
      />
      <Action
        iconName="settings"
        title="dashboard.sites.details.mwp.security.main.actionsBlock.securitySettings.title"
        description="dashboard.sites.details.mwp.security.main.actionsBlock.securitySettings.description"
        onClick={() => {
          navigate(`/sites/security/${cluster}/${namespace}/${name}/settings`);
        }}
      />
    </div>
  );
}

// ----------------------------------------------------------------------

type ActionProps = {
  iconName: string;
  title: string;
  description: string;
  onClick: VoidFunction;
};

// ----------------------------------------------------------------------

function Action({ iconName, title, description, onClick }: ActionProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-content-container gv-p-lg gv-flex-row-md gv-items-center gv-flex-1"
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <IconWrapper>
        <gv-icon src={`/src/icons/${iconName}.svg`} />
      </IconWrapper>
      <div className="gv-flex-1">
        <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
        <p className="gv-caption-lg gv-text-on-alternative">{translate(description)}</p>
      </div>
      <IconWrapper>
        <gv-icon src="/src/icons/arrow_forward.svg" />
      </IconWrapper>
    </div>
  );
}
