import { ReactElement, ReactNode } from 'react';

// ----------------------------------------------------------------------

type Props = {
  text: string | ReactNode;
  type?: 'alert' | 'warning' | 'success' | 'info';
  title?: string;
  button?: ReactElement;
  handleClose?: VoidFunction;
  condensed?: boolean;
  addon?: boolean;
};

// ----------------------------------------------------------------------

export default function Alert({
  text,
  type = 'info',
  title,
  button,
  handleClose,
  condensed,
  addon,
}: Props) {
  return (
    <div
      className={`gv-notice gv-notice-${type}${condensed ? ' gv-mode-condensed' : ''}${
        addon ? ' gv-notice-addon' : ''
      }`}
    >
      <gv-icon
        class="gv-notice-icon"
        src={`/src/icons/${
          type === 'alert' ? 'error' : type === 'success' ? 'check_circle' : type
        }.svg`}
      />

      {title ? (
        <div className="gv-notice-content">
          <div className="gv-notice-title">{title}</div>
          {typeof text === 'string' ? <p>{text}</p> : text}
        </div>
      ) : typeof text === 'string' ? (
        <p className="gv-notice-content">{text}</p>
      ) : (
        <div className="gv-notice-content">{text}</div>
      )}

      {button && button}

      {handleClose && (
        <button className="gv-notice-close" onClick={handleClose} type="button">
          <gv-icon src="/src/icons/close.svg" />
        </button>
      )}
    </div>
  );
}
