import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

type Props = {
  text: string;
  type?: 'generic' | 'alert' | 'warning' | 'info' | 'success';
  style?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function Badge({ text, type = 'generic', style }: Props) {
  return (
    <div className={`gv-badge gv-badge-${type}`} style={style}>
      {text}
    </div>
  );
}
