import { useParams, useNavigate } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { siteApi } from 'src/redux/api/siteApi';
import { userApi } from 'src/redux/api/userApi';
import { useRevokeSiteUserMutation } from 'src/redux/api/resourceApi';
// @types
import { UserRoleEnum } from 'src/@types/user';
import { AccessPermissionFullDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullDTO | null;
};

// ----------------------------------------------------------------------

export default function RevokeAccessConfirmation({ onClose, accessPermissionInfo }: Props) {
  const { email, cluster, namespace } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // API
  const [revokeSiteUser, { isLoading }] = useRevokeSiteUserMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  const handleRevokeAccess = () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'revoke-site-access',
    });

    revokeSiteUser({
      userEmail: accessPermissionInfo?.user.email || email || '',
      siteId: accessPermissionInfo?.resource?.siteId || `${cluster}/${namespace}`,
    })
      .unwrap()
      .then((data) => {
        if (data?.success) {
          displayToast(
            translate('wpone.general.requestResponse.access.successMessage.revokeAccess')
          );
          if (
            user?.email === accessPermissionInfo?.user.email &&
            user?.role === UserRoleEnum.customer
          ) {
            navigate('/sites');

            // For getting latest update on Site table (if user is customer)
            dispatch(siteApi.util.invalidateTags([{ type: 'Sites', id: 'PARTIAL-LIST' }]));
          }

          if (user?.role === UserRoleEnum.serviceAccount || user?.role === UserRoleEnum.support) {
            // For getting latest update on Customer table
            dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));
          }
        } else {
          displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
            variant: 'alert',
          });
        }
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.users.removeUserDialog.title', {
            email: accessPermissionInfo?.user.email || email,
          })}
        </h2>
        <p style={{ overflowX: 'auto' }}>
          {translate('wpone.sites.details.users.removeUserDialog.description')}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button uiType="cancel" text={translate('wpone.general.action.cancel')} onClick={onClose} />

        <Button
          text={translate('wpone.sites.details.users.removeUserDialog.action')}
          onClick={handleRevokeAccess}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
