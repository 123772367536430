// @types
import { UpdateVulnerabilityLogRow, UpdateVulnerabilityType } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertScoreToSeverity } from 'src/utils/vulnerabilities';
import { fShortDateTime } from 'src/utils/format';
// components
import Badge from 'src/components/gravity/Badge';

// ----------------------------------------------------------------------

type Props = {
  row: UpdateVulnerabilityLogRow;
};

// ----------------------------------------------------------------------

export default function VulnerabilityLogRow({ row }: Props) {
  // HOOK
  const { translate, currentLang } = useLocales();

  // VAR
  const score = row.cvssScore;

  const { type, status } = convertScoreToSeverity(score);

  return (
    <tr>
      <td>
        {fShortDateTime(row.date, {
          locale: currentLang.value,
          separatedByComma: true,
          secondIncluded: true,
        })}
      </td>
      <td>
        <Badge
          text={translate(
            `dashboard.sites.details.mwp.security.vulnerabilityLog.table.row.severity.${status}`
          )}
          type={type}
        />
      </td>
      <td>
        <a href={row.vulnUrl} target="_blank" rel="noreferrer">
          {row.vulnName}
        </a>
      </td>
      <td>
        {translate(
          `dashboard.sites.details.mwp.security.vulnType.${row.type}${
            row.type !== UpdateVulnerabilityType.CORE ? '.singular' : ''
          }`
        )}
      </td>
      <td>{row.pluginDisplayName}</td>
      <td>
        {row.updateVer ||
          translate('dashboard.sites.details.mwp.security.vulnerabilityLog.table.row.fixVersion')}
      </td>
      <td>
        {translate(
          `dashboard.sites.details.mwp.security.vulnerabilityLog.table.row.result.${row.result}`
        )}
      </td>
    </tr>
  );
}
