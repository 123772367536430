import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router';
// redux
import { useGetUpdatesQuery } from 'src/redux/api/updateApi';
// utils
import { getUpdateDetails, getUpdateReport } from 'src/utils/getSiteUpdateInfo';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function SiteDetailsUpdateSubPageGuard({ children }: Props) {
  const { name, updateReportId, updateDetailsName } = useParams();

  // API
  const { data: siteUpdates, isFetching: isSiteUpdatesFetching } = useGetUpdatesQuery(name);

  // VAR
  const updateReport = getUpdateReport(updateReportId as string, siteUpdates?.data.updateList);
  // const updateReport = getUpdateReport(updateReportId as string, DUMMY_UPDATES_DATA.updateList); // TESTING CODE
  const updateDetails = getUpdateDetails(updateDetailsName as string, updateReport);

  if (isSiteUpdatesFetching) {
    return <LoadingScreen />;
  }

  if (!siteUpdates || !updateReport || (updateDetailsName && !updateDetails)) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
}
