// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteDomainConfirmation from './DeleteDomainConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  site: ListSiteResponseDTO | undefined;
  selectedDomain: string | null;
};

// ----------------------------------------------------------------------

export default function DeleteDomainDialog({ open, onClose, site, selectedDomain }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <DeleteDomainConfirmation onClose={onClose} site={site} selectedDomain={selectedDomain} />
      }
    />
  );
}
