import { Link as RouterLink } from 'react-router-dom';
// @mui
import { SxProps, Typography, Link, TypographyProps } from '@mui/material';

// ----------------------------------------------------------------------

// For text with one local navigation link
interface Props extends TypographyProps {
  text: string;
  navigateTo: string;
  textSx?: SxProps;
  linkSx?: SxProps;
}

export default function TextWithNavigationLink({
  text,
  navigateTo,
  textSx,
  linkSx,
  ...other
}: Props) {
  const arr = text.replace('</a>', '<a>').split('<a>');
  return (
    <Typography sx={{ ...textSx }} {...other}>
      {arr[0]}
      <Link component={RouterLink} to={navigateTo} sx={{ ...linkSx }}>
        {arr[1]}
      </Link>
      {arr[2]}
    </Typography>
  );
}
