// @types
import { SiteStatusEnum } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteSiteForm from './DeleteSiteForm';

// ----------------------------------------------------------------------

type Props = {
  siteStatus: SiteStatusEnum;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteSiteDialog({ siteStatus, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<DeleteSiteForm siteStatus={siteStatus} onClose={onClose} />}
    />
  );
}
