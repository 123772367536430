import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  logoOnly?: boolean;
}

export default function Logo({ disabledLink = false, logoOnly = false, sx }: Props) {
  const logo = (
    <Box
      sx={{ width: logoOnly ? 48 : 80, height: 40, ...sx }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component="img"
        alt="WP.one logo"
        src={logoOnly ? '/assets/wpone-minimised-logo.svg' : '/assets/wpone-login-logo.svg'}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
