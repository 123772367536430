import { InputHTMLAttributes, ReactNode, Ref, forwardRef } from 'react';
// @mui
import { Fade, Tooltip as MUITooltip } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = {
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  inputErr?: boolean; // Error color apply to input border as well
  errMsg?: string;
  generalMsg?: string;
  addonPrefix?: string;
  addonSuffix?: ReactNode; // Pass input or button as addon following the gravity pattern
  condensed?: boolean;
  disabled?: boolean; //Pass disabled manually to input div wrapper to apply gravity
  readOnly?: boolean; //Pass readyOnly manually to both input and input div wrapper to apply gravity, this will make the input field not editable, gravity doesn't have this feature in React
  nonInputElement?: boolean; //Pass nonInputElement manually to input, this will make the input field not editable, gravity doesn't have this feature in React
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

const TextField = forwardRef(
  (
    {
      label,
      labelId,
      labelOptional,
      labelIcon,
      labelIconTooltip,
      inputErr,
      errMsg,
      generalMsg,
      addonPrefix,
      addonSuffix,
      condensed,
      disabled,
      readOnly,
      nonInputElement,
      ...other
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => (
    <div className="gv-activated">
      <div className="gv-form-option">
        {label && labelId && (
          <label htmlFor={labelId} className={`gv-label${labelIcon ? ' gv-label-with-icon' : ''}`}>
            <span>{label}</span>
            {labelOptional && <span className="gv-label-optional">{labelOptional}</span>}
            {labelIcon &&
              (labelIconTooltip ? (
                <MUITooltip
                  placement="top"
                  title={labelIconTooltip}
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 300 }}
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  <gv-icon src={`/src/icons/${labelIcon}.svg`} />
                </MUITooltip>
              ) : (
                <gv-icon src={`/src/icons/${labelIcon}.svg`} />
              ))}
          </label>
        )}

        <div
          className={`gv-input${readOnly ? ' gv-read-only' : ''}${
            addonPrefix || addonSuffix ? ' gv-with-addon' : ''
          }${condensed ? ' gv-mode-condensed' : ''}${inputErr ? ' gv-error' : ''}${
            disabled ? ' gv-disabled' : ''
          }`}
        >
          {addonPrefix && (
            <div className="gv-prefix" style={{ whiteSpace: 'nowrap' }}>
              {addonPrefix}
            </div>
          )}
          <input
            ref={ref}
            id={labelId ? labelId : undefined}
            type="text"
            {...(readOnly || nonInputElement ? { readOnly: true } : {})}
            {...other}
          />
          {addonSuffix && addonSuffix}
        </div>
        {generalMsg && <span className="gv-input-message">{generalMsg}</span>}
        {errMsg && <span className="gv-input-message gv-error">{errMsg}</span>}
      </div>
    </div>
  )
);

export default TextField;
