import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

// ----------------------------------------------------------------------

type UseTableProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  //
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
  onSelectRow: (id: string) => void;
  onSelectAllRows: (checked: boolean, newSelecteds: string[]) => void;
  //
  onSort: (id: string) => void;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (event: ChangeEvent<HTMLSelectElement>) => void;
};

type Props = {
  defaultOrder?: 'asc' | 'desc';
  defaultOrderBy?: string;
  defaultSelected?: string[];
  defaultRowsPerPage?: number;
  defaultCurrentPage?: number;
};

// ----------------------------------------------------------------------

export default function useGravityTable({
  defaultOrder,
  defaultOrderBy,
  defaultSelected,
  defaultRowsPerPage,
  defaultCurrentPage,
}: Props): UseTableProps {
  // STATE
  const [orderBy, setOrderBy] = useState(defaultOrderBy || 'name');

  const [order, setOrder] = useState<'asc' | 'desc'>(defaultOrder || 'asc');

  const [page, setPage] = useState(defaultCurrentPage || 1);

  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);

  const [selected, setSelected] = useState<string[]>(defaultSelected || []);

  // EVENT FUNCTION
  const onSort = (id: string) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
    setPage(1);
  };

  const onSelectRow = (id: string) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllRows = (checked: boolean, newSelecteds: string[]) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return {
    order,
    page,
    setPage,
    orderBy,
    rowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  };
}
