import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { SecuritySettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFTextField, RHFCheckbox } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

type FormValuesProps = {
  newVulns: boolean;
  fixedVulns: boolean;
  adminEmail: boolean;
  otherEmails: string;
};

type Props = {
  notificationsSettings: {
    newVulns: boolean;
    fixedVulns: boolean;
    adminEmail: boolean;
    otherEmails: string;
  };
  isSettingsLoading: boolean;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
  setSaveNotiSettingsSuccess: Dispatch<SetStateAction<undefined | boolean>>;
};

// ----------------------------------------------------------------------

export default function NotificationsForm({
  notificationsSettings,
  isSettingsLoading,
  setSettings,
  setIsSettingsLoading,
  setSaveNotiSettingsSuccess,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const adminEmail = 'example@domain.one';

  //FORM
  const SettingsSchema = Yup.object().shape({
    otherEmails: Yup.string()
      .trim()
      .transform((value) =>
        value
          .split(',')
          .map((email: string) => email.trim())
          .filter((email: string) => email)
          .join(',')
      )
      .test(
        'valid-email',
        translate(
          'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.other.validation'
        ),
        function (value) {
          if (value) {
            return value.split(',').every((email) => Yup.string().email().isValidSync(email));
          }

          return true;
        }
      ),
  });

  const defaultValues = {
    newVulns: notificationsSettings.newVulns,
    fixedVulns: notificationsSettings.fixedVulns,
    adminEmail: notificationsSettings.adminEmail,
    otherEmails: notificationsSettings.otherEmails,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SettingsSchema),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  //EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-notifications-site-security-settings',
    });

    setIsSettingsLoading(true);
    setTimeout(() => {
      setIsSettingsLoading(false);

      const isSuccess: boolean = false;
      setSettings((prev) => {
        const copyObj = {
          newVulns: prev.notifications.newVulns,
          fixedVulns: prev.notifications.fixedVulns,
          adminEmail: prev.notifications.adminEmail,
          otherEmails: prev.notifications.otherEmails,
        };

        return { ...prev, notifications: isSuccess ? data : copyObj };
      });
      setSaveNotiSettingsSuccess(isSuccess);
    }, 2000);
  };

  //
  useEffect(() => {
    setValue('newVulns', notificationsSettings.newVulns);
    setValue('fixedVulns', notificationsSettings.fixedVulns);
    setValue('adminEmail', notificationsSettings.adminEmail);
    setValue('otherEmails', notificationsSettings.otherEmails);
  }, [notificationsSettings, setValue]);

  return (
    <SettingsBlock
      title="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.title"
      description="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.description"
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="gv-content-container gv-flex-column-md gv-p-lg">
          <FormSubSection title="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.preferences.title">
            <div className="gv-flex-column-md">
              <RHFCheckbox
                name="newVulns"
                label={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.preferences.newVulns.label'
                )}
                labelId="notifications-newVulns"
                condensed
              />
              <RHFCheckbox
                name="fixedVulns"
                label={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.preferences.fixedVulns.label'
                )}
                labelId="notifications-fixedVulns"
                description={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.preferences.fixedVulns.description'
                )}
                condensed
              />
            </div>
          </FormSubSection>

          <FormSubSection title="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.title">
            <RHFCheckbox
              name="adminEmail"
              label={translate(
                'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.admin.label'
              )}
              labelId="notifications-adminEmail"
              description={translate(
                'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.admin.description',
                {
                  administratorEmail: adminEmail,
                }
              )}
              condensed
            />
          </FormSubSection>

          <RHFTextField
            name="otherEmails"
            label={translate(
              'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.other.label'
            )}
            labelId="notifications-otherEmails"
            labelOptional={translate(
              'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.other.labelOptional'
            )}
            generalMsg={translate(
              'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.recipents.other.message'
            )}
          />

          <div>
            <Button
              type="submit"
              text={translate('dashboard.general.action.save')}
              disabled={isSettingsLoading}
            />
          </div>
        </div>
      </FormProvider>
    </SettingsBlock>
  );
}

// ----------------------------------------------------------------------

type FormSubSectionProps = {
  title: string;
  children: ReactNode;
};

// ----------------------------------------------------------------------

function FormSubSection({ title, children }: FormSubSectionProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex-column-sm">
      <p className="gv-text-sm gv-text-bold">{translate(title)}</p>
      {children}
    </div>
  );
}
