import { Dispatch, SetStateAction } from 'react';
// @types
import { SecuritySettings } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import RestoreDefaultSettingsConfirmation from './RestoreDefaultSettingsConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  settings: SecuritySettings;
  onClose: VoidFunction;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
};

// ----------------------------------------------------------------------

export default function RestoreDefaultSettingsDialog({
  open,
  settings,
  onClose,
  setSettings,
  setIsSettingsLoading,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <RestoreDefaultSettingsConfirmation
          settings={settings}
          onClose={onClose}
          setSettings={setSettings}
          setIsSettingsLoading={setIsSettingsLoading}
        />
      }
    />
  );
}
