import { Dispatch, SetStateAction } from 'react';
// @types
import { SecuritySettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
};

// ----------------------------------------------------------------------

export default function DeactivateAutoFixConfirmation({
  onClose,
  setSettings,
  setIsSettingsLoading,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // EVENT FUNCTION
  function handleDeactivateAutoFix() {
    setIsSettingsLoading(true);
    onClose();

    setTimeout(() => {
      const isSuccess = true;

      if (isSuccess) {
        setSettings((prev) => ({ ...prev, autoUpdate: false }));
        displayToast(
          translate(
            'dashboard.sites.details.mwp.security.settings.deactivateAutoFixDialog.toast.success'
          )
        );
      } else {
        displayToast(
          translate(
            'dashboard.sites.details.mwp.security.settings.deactivateAutoFixDialog.toast.error'
          ),
          { variant: 'alert' }
        );
      }

      setIsSettingsLoading(false);
    }, 2000);
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('dashboard.sites.details.mwp.security.settings.deactivateAutoFixDialog.title')}
        </h2>

        <p style={{ overflowX: 'auto' }}>
          {translate(
            'dashboard.sites.details.mwp.security.settings.deactivateAutoFixDialog.description'
          )}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate(
            'dashboard.sites.details.mwp.security.settings.deactivateAutoFixDialog.action'
          )}
          onClick={handleDeactivateAutoFix}
        />
      </ButtonGroup>
    </>
  );
}
