import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactGA from 'react-ga4';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { toggleActionsInProgressBanner } from 'src/redux/features/layout';
import { addBackupInProgress } from 'src/redux/features/backup';
import { useInitiateRestorationMutation } from 'src/redux/api/backupApi';
// @types
import { RestoreMethodEnum, BackupSettings, BackupStatus } from 'src/@types/site';
import { LocaleEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { fShortDate, fShortDateTime } from 'src/utils/format';
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFRadioGroup, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  mainDomain: string;
  // This option only for database and files backup settings
  backupMethod: RestoreMethodEnum;
  // This option only VISIBLE for support user
  notificationEmail: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
  backupSettings: BackupSettings;
};

// ----------------------------------------------------------------------

export default function RestoreBackupForm({ onClose, backupSettings }: Props) {
  const navigate = useNavigate();

  const { name } = useParams();

  const dispatch = useDispatch();

  // HOOK
  const { translate, currentLang } = useLocales();

  const isMountedRef = useIsMountedRef();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // API
  const [initiateRestoration, { isLoading }] = useInitiateRestorationMutation();

  // FORM
  const ConfirmationSchema = Yup.object().shape({
    mainDomain: Yup.string()
      .trim()
      .required(
        translate('wpone.sites.details.backups.restoreBackupDialog.confirmation.validation1')
      )
      .test(
        'main-domain',
        translate('wpone.sites.details.backups.restoreBackupDialog.confirmation.validation2'),
        function (value) {
          if (!name || !value) return false;

          return value === name;
        }
      ),
    notificationEmail: Yup.string()
      .trim()
      .required(
        translate(
          'wponesupport.sites.details.backups.restoreBackupDialog.notificationEmail.validation1'
        )
      )
      .email(
        translate(
          'wponesupport.sites.details.backups.restoreBackupDialog.notificationEmail.validation2'
        )
      ),
  });

  const defaultValues = {
    backupMethod: backupSettings.method,
    notificationEmail: user && user.role !== UserRoleEnum.support ? user.email : '',
    mainDomain: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ConfirmationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'restore-backup',
    });

    const selectedBackupMethod = data.backupMethod;

    let selectedSettings: BackupSettings = { ...backupSettings, method: selectedBackupMethod };

    // TEST CODE
    //================================================================================
    // dispatch(
    //   addBackupInProgress({
    //     id: 0,
    //     settings: selectedSettings,
    //     notificationEmail: data.notificationEmail.trim(),
    //     startedAt: new Date(),
    //     endedAt: null,
    //     currentStatus: BackupStatus.IN_PROGRESS,
    //   })
    // );

    // dispatch(toggleActionsInProgressBanner(true));

    // navigate(
    //   `/sites/backups/${selectedSettings.cluster}/${selectedSettings.namespace}/${selectedSettings.hostname}/details`
    // );
    //================================================================================

    await initiateRestoration({
      cluster: selectedSettings.cluster,
      namespace: selectedSettings.namespace,
      hostname: selectedSettings.hostname,
      environment: selectedSettings.env,
      restoreMethod: selectedSettings.method,
      ...(selectedBackupMethod !== RestoreMethodEnum.database
        ? { timestamp: selectedSettings.date.getTime() / 1000 }
        : {}),
      ...(selectedBackupMethod !== RestoreMethodEnum.files
        ? { databaseBackupFileName: selectedSettings.databaseBackupFileName as string }
        : {}),
      locale: user && data.notificationEmail.trim() === user.email ? user.locale : LocaleEnum.en,
      notificationEmail: data.notificationEmail.trim(),
    })
      .unwrap()
      .then((initiateRestorationResponse) => {
        dispatch(
          addBackupInProgress({
            id: initiateRestorationResponse.id,
            settings: selectedSettings,
            notificationEmail: data.notificationEmail.trim(),
            startedAt: initiateRestorationResponse.createdAt,
            endedAt: null,
            currentStatus: BackupStatus.IN_PROGRESS,
          })
        );

        dispatch(toggleActionsInProgressBanner(true));

        navigate(
          `/sites/backups/${selectedSettings.cluster}/${selectedSettings.namespace}/${selectedSettings.hostname}/details`
        );
      })
      .catch(async (error) => {
        let errCode = error.status;

        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            `wpone.sites.details.backups.restoreBackupDialog.title.${backupSettings.env}.${backupSettings.method}`
          )}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <p>
              {translate(
                `wpone.sites.details.backups.restoreBackupDialog.description.${backupSettings.method}`,
                {
                  date:
                    backupSettings.method === RestoreMethodEnum.both
                      ? fShortDate(backupSettings.date, currentLang.value)
                      : fShortDateTime(backupSettings.date, { locale: currentLang.value }),
                  siteName: name,
                }
              )}
            </p>

            <div className="gv-flex-column-md">
              {backupSettings.method === RestoreMethodEnum.both && (
                <>
                  <p className="gv-text-bold">
                    {translate(
                      'wpone.sites.details.backups.restoreBackupDialog.optionsDescription'
                    )}
                  </p>

                  <RHFRadioGroup
                    radioSpace="md"
                    condensed
                    name="backupMethod"
                    options={[
                      RestoreMethodEnum.both,
                      RestoreMethodEnum.database,
                      RestoreMethodEnum.files,
                    ].map((option) => ({
                      label: translate(`wpone.sites.details.backups.backupMethod.${option}`),
                      value: option,
                    }))}
                  />
                </>
              )}

              <Alert
                type="warning"
                text={translate(`wpone.sites.details.backups.restoreBackupDialog.alert`, {
                  siteName: name,
                })}
              />

              <div className="gv-flex-column-sm">
                <p>
                  {translate(
                    `wpone.sites.details.backups.restoreBackupDialog.confirmation.description`,
                    {
                      siteName: name,
                    }
                  )}
                </p>

                <RHFTextField
                  name="mainDomain"
                  label={translate(
                    'wpone.sites.details.backups.restoreBackupDialog.confirmation.label'
                  )}
                  labelId="domain"
                />
              </div>

              {user?.role === UserRoleEnum.support && (
                <RHFTextField
                  name="notificationEmail"
                  label={translate(
                    'wponesupport.sites.details.backups.restoreBackupDialog.notificationEmail.label'
                  )}
                  labelId="notification-email"
                />
              )}
            </div>
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.sites.details.backups.restoreBackupDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
