import { SiteClusterEnum } from './site';

// ----------------------------------------------------------------------

export enum UserResourceLimitTypeEnum {
  sites = 'sites',
  zones = 'zones',
}

export enum UserRoleEnum {
  customer = 'customer',
  support = 'support',
  serviceAccount = 'serviceAccount',
}

export enum ResourceTypeEnum {
  site = 'site',
  zone = 'zone',
}

export enum AccessTypeEnum {
  owner = 'owner',
  admin = 'admin',
  developer = 'developer',
}

export enum LocaleEnum {
  fi = 'fi',
  en = 'en',
  nl = 'nl',
  de = 'de',
  no = 'no',
}

export enum BrandIdEnum {
  zoner = 1,
  groupOne = 2,
  hostnet = 3,
  metanet = 4,
  uniweb = 5,
}

export enum BrandEnum {
  zoner = 'zoner',
  groupOne = 'one.com',
  hostnet = 'hostnet',
  metanet = 'metanet',
  uniweb = 'uniweb',
}

// ----------------------------------------------------------------------

// This type only use for current dummy activity log
export type User = {
  brand_id: number;
  email: string;
  user_name: string;
  roles: string[];

  // Array of all site names the user has access to
  sites: string[] | 'All sites';
};

export type SiteAccessUser = {
  email: string;
  siteName: string;
  accessType: AccessTypeEnum;
  siteCluster: SiteClusterEnum;
  siteNamespace: string;
};

export type ShareResourcePermissions = {
  userForSharing: string;
  siteName: string;
  siteId: string;
  accessType: AccessTypeEnum;
};
