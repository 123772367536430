// ----------------------------------------------------------------------

type Props = {
  text?: string;
  condensed?: boolean;
};

// ----------------------------------------------------------------------

export default function Loader({ text, condensed }: Props) {
  return text ? (
    <div className="gv-loader-container">
      <gv-loader class={condensed ? 'gv-mode-condensed' : ''} src="/src/loaders/spinner.svg" />
      <p>{text}</p>
    </div>
  ) : (
    <gv-loader class={condensed ? 'gv-mode-condensed' : ''} src="/src/loaders/spinner.svg" />
  );
}
