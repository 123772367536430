import { useParams } from 'react-router';
// redux
import { useReinstallSiteWPCoreMutation } from 'src/redux/api/siteApi';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ReinstallWPCoreConfirmation({ onClose }: Props) {
  const { namespace, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [reinstallWPCore, { isLoading }] = useReinstallSiteWPCoreMutation();

  // EVENT FUNCTION
  function handleReinstallWPCore() {
    if (!namespace || !cluster) return;
    reinstallWPCore(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (data.code === 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.wpCoreSettings.reinstallWPCoreDialog.toast.success'
            )
          );
        } else {
          displayToast(
            translate(
              'wponesupport.sites.details.support.wpCoreSettings.reinstallWPCoreDialog.toast.error'
            ),
            { variant: 'alert' }
          );
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      })
      .finally(() => {
        onClose();
      });
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'wponesupport.sites.details.support.wpCoreSettings.reinstallWPCoreDialog.title'
          )}
        </h2>
        <p style={{ overflowX: 'auto' }}>
          {translate(
            'wponesupport.sites.details.support.wpCoreSettings.reinstallWPCoreDialog.description'
          )}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate(
            'wponesupport.sites.details.support.wpCoreSettings.reinstallWPCoreDialog.action'
          )}
          onClick={handleReinstallWPCore}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
