import { useLocation } from 'react-router';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import { removeDiskUpgradedSite } from 'src/redux/features/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type Props = {
  siteName: string;
};

// ----------------------------------------------------------------------

export default function UpgradeDiskStatusBanner({ siteName }: Props) {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { diskUpgradedSites } = useSelector((state) => state.site);

  // VAR
  const tabname = pathname.split('/')[2];

  const site = diskUpgradedSites.find(
    (site) => site.siteName === siteName && site.fromTab === tabname
  );

  return (
    <>
      {site && (
        <div className="gv-activated">
          <Alert
            type={site.isSuccess ? 'success' : 'warning'}
            title={translate(
              `wpone.sites.details.upgradeDiskStatusBanner.${
                site.isSuccess ? 'success' : 'error'
              }.title`
            )}
            text={translate(
              `wpone.sites.details.upgradeDiskStatusBanner.${
                site.isSuccess ? 'success' : 'error'
              }.description`
            )}
            handleClose={() => dispatch(removeDiskUpgradedSite(siteName))}
          />
        </div>
      )}
    </>
  );
}
