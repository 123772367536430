// @types
import { MWPSiteStatusDetails, MWPSiteStatusEnum, MWPUpdateStatusEnum } from 'src/@types/site';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
// mock
import { DUMMY_PREVIEW_CONTENT } from 'src/mock/sites';
// components
import Button from 'src/components/gravity/Button';
import Indicator from 'src/components/gravity/Indicator';

// ----------------------------------------------------------------------

export default function PreviewBlock() {
  //  HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // VAR
  const { title, siteStatus, updateStatus, lastUpdate, nextUpdate, imageUrl } =
    DUMMY_PREVIEW_CONTENT[0]; // Change index number to see different site status and update status combination

  // HELPER FUNCTION
  function getPreviewActionLabel() {
    switch (updateStatus) {
      case MWPUpdateStatusEnum.success:
      case MWPUpdateStatusEnum.noUpdate:
        return 'runUpdate';
      case MWPUpdateStatusEnum.failed:
      case MWPUpdateStatusEnum.partialFailed:
        return 'seeReport';
      default:
        return 'goToUpdates';
    }
  }

  function getPreviewIndicatorInfo() {
    let type: 'alert' | 'info' | 'success' | 'warning' | 'default' = 'default';
    let description = '';

    if (siteStatus.status === MWPSiteStatusEnum.DOWN) {
      type = 'alert';
      description = 'dashboard.sites.details.mwp.overview.preview.description.siteBroken';
    } else {
      switch (updateStatus) {
        case MWPUpdateStatusEnum.success:
        case MWPUpdateStatusEnum.partialFailed:
          type = 'success';
          break;
        case MWPUpdateStatusEnum.failed:
          type = 'warning';
          break;
        default:
          type = 'default';
          break;
      }
      description = `dashboard.sites.details.mwp.overview.preview.description.siteUp.${updateStatus}`;
    }

    return {
      type,
      description,
    };
  }

  return (
    <div className="gv-content-container gv-p-lg gv-flex gv-flex-col gv-tab-flex-row gv-tab-items-center gv-gap-fluid">
      <div className="gv-flex-column-lg gv-flex-1">
        <div className="gv-flex-column-sm">
          <p className="gv-heading-sm">{translate(title)}</p>
          <Indicator
            type={getPreviewIndicatorInfo().type}
            text={translate(getPreviewIndicatorInfo().description)}
            extraClass="gv-items-baseline"
            textStyles={{ whiteSpace: 'normal' }}
          />
        </div>

        <div className="gv-flex gv-flex-col gv-tab-lg-flex-row gv-gap-fluid">
          <UpdateInfo
            type="last"
            siteStatus={siteStatus}
            updateStatus={updateStatus}
            date={lastUpdate}
          />
          <UpdateInfo
            type="next"
            siteStatus={siteStatus}
            updateStatus={updateStatus}
            date={nextUpdate}
          />
        </div>
        <div>
          {siteStatus.status === MWPSiteStatusEnum.UP && (
            <Button
              text={translate(
                `dashboard.sites.details.mwp.overview.preview.action.${getPreviewActionLabel()}`
              )}
              extraClass={`${isMobile ? 'gv-w-full' : ''}`}
            />
          )}
        </div>
      </div>
      <div className="gv-flex-1">
        <img
          src={imageUrl || '/assets/mwp/site_preview_default.svg'}
          alt={title}
          style={{ width: '100%', objectFit: 'contain' }}
        />
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------

type UpdateInfoProps = {
  type: 'last' | 'next';
  siteStatus: MWPSiteStatusDetails;
  updateStatus: MWPUpdateStatusEnum;
  date: Date;
};

function UpdateInfo({ type, siteStatus, updateStatus, date }: UpdateInfoProps) {
  // HOOK
  const { translate, currentLang } = useLocales();

  // HELPER FUNCTION
  function showUpdateDate() {
    if (
      updateStatus === MWPUpdateStatusEnum.updateDeactivated ||
      (type === 'last' && updateStatus === MWPUpdateStatusEnum.noUpdate) ||
      (type === 'next' && siteStatus.status === MWPSiteStatusEnum.DOWN)
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="gv-flex gv-flex-col">
      <p className="gv-text-sm gv-text-bold" style={{ color: 'var(--color-body-on-placeholder)' }}>
        {translate(`dashboard.sites.details.mwp.overview.preview.updateLabel.${type}`)}
      </p>

      <p className="gv-text-sm">
        {showUpdateDate()
          ? fShortDateTime(date, { locale: currentLang.value, separatedByComma: true })
          : '-'}
      </p>
    </div>
  );
}
