import { useDropzone } from 'react-dropzone';
// type
import { UploadProps } from './type';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  extraRejection,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, acceptedFiles } =
    useDropzone({
      multiple: false,
      ...other,
    });

  // HOOK
  const { translate } = useLocales();

  return (
    <div>
      <div
        {...getRootProps()}
        className="gv-content-container gv-p-lg gv-pos-relative gv-px-md gv-py-sm"
        onMouseEnter={(event) => {
          event.currentTarget.style.opacity = '0.72';
        }}
        onMouseLeave={(event) => {
          event.currentTarget.style.opacity = '';
        }}
        style={{
          borderStyle: 'dashed',
          borderColor: 'var(--color-signal-info)',
          backgroundColor: 'var(--color-signal-info-light)',
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'var(--color-signal-alert)',
            borderColor: 'var(--color-signal-alert)',
            backgroundColor: 'var(--color-signal-alert-light)',
          }),
          ...(file && {
            padding: '16px 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </div>

      {file && (
        <div className="gv-mt-lg">
          <p>
            {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.info3')}{' '}
            <strong>{typeof file === 'string' ? file : file.name}</strong>
          </p>
        </div>
      )}

      {fileRejections.length > 0 ? (
        <RejectionFiles fileRejections={fileRejections} />
      ) : extraRejection ? (
        <RejectionFiles
          fileRejections={[
            {
              file: acceptedFiles[0],
              errors: [
                {
                  message: translate(
                    'wpone.domains.details.dnsRecords.importDnsRecordsDialog.error'
                  ),
                  code: 'no-records',
                },
              ],
            },
          ]}
        />
      ) : (
        <></>
      )}

      {helperText && helperText}
    </div>
  );
}
