// @types
import { SitePlanEnum } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import CancelPlanConfirmation from './CancelPlanConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  cancellingPlan: SitePlanEnum | null;
};

// ----------------------------------------------------------------------

export default function CancelPlanDialog({ open, onClose, cancellingPlan }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<CancelPlanConfirmation onClose={onClose} cancellingPlan={cancellingPlan} />}
    />
  );
}
