import { Dispatch, SetStateAction } from 'react';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { MWPSiteStatusEnum, MWPSiteIssueEnum, MWPSiteStatusDetails } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { handleZendeskChatOpen } from 'src/utils/zendesk';
// components
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type Props = {
  statusDetails: MWPSiteStatusDetails;
  isRepairWordpressCoreSucceed: boolean | null;
  setIsRepairWordpressCoreSucceed: Dispatch<SetStateAction<boolean | null>>;
  onOpenRepairWordpressCoreDialogDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function Banner({
  statusDetails,
  isRepairWordpressCoreSucceed,
  setIsRepairWordpressCoreSucceed,
  onOpenRepairWordpressCoreDialogDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const { status, issue } = statusDetails;

  const siteUpIssues = [MWPSiteIssueEnum.SSL, MWPSiteIssueEnum.REDIRECTION];
  const siteDownIssues = [
    MWPSiteIssueEnum.BROKEN_WP_CORE,
    MWPSiteIssueEnum.REST_API,
    MWPSiteIssueEnum.GENERAL,
    MWPSiteIssueEnum.FORBIDDEN,
    MWPSiteIssueEnum.NOT_FOUND,
    MWPSiteIssueEnum.INTERNAL_ERROR,
    MWPSiteIssueEnum.SERVICE_UNAVAILABLE,
  ];
  const bannerAction = getActionButton();

  // HELPER FUNCTION
  function getActionButton() {
    if (
      !issue ||
      (status === MWPSiteStatusEnum.UP && !siteUpIssues.includes(issue)) ||
      (status === MWPSiteStatusEnum.DOWN && !siteDownIssues.includes(issue))
    )
      return;

    let type: 'alert' | 'info' | 'success' | 'warning' = 'alert';
    let title: string = '';
    let text: string = '';
    let action: { text: string; onClick: VoidFunction; endIcon: boolean } | undefined = undefined;
    let handleCloseBanner: VoidFunction | undefined = undefined;

    // Issue banner
    if (isRepairWordpressCoreSucceed === null) {
      // Handle type
      if (status === MWPSiteStatusEnum.UP && issue === MWPSiteIssueEnum.SSL) {
        type = 'warning';
      }
      // handle title
      if (siteDownIssues.indexOf(issue) > 2) {
        title = `dashboard.sites.details.mwp.overview.banner.issue.title.${issue}`;
      }
      // Handle text
      text = `dashboard.sites.details.mwp.overview.banner.issue.description.${issue}`;
      // Handle action
      switch (issue) {
        case MWPSiteIssueEnum.BROKEN_WP_CORE:
          action = {
            text: 'dashboard.sites.details.mwp.overview.banner.issue.action.repairCore',
            onClick: () => onOpenRepairWordpressCoreDialogDialog(),
            endIcon: false,
          };
          break;
        case MWPSiteIssueEnum.FORBIDDEN:
        case MWPSiteIssueEnum.NOT_FOUND:
        case MWPSiteIssueEnum.INTERNAL_ERROR:
          action = {
            text: 'dashboard.sites.details.mwp.overview.banner.issue.action.seeNextSteps',
            onClick: () =>
              window.open(
                'https://help.one.com/hc/en-us/articles/7634946366993-Troubleshooting-WordPress-errors-Regain-access-to-your-WP-Admin#Error-others',
                '_blank'
              ),
            endIcon: true,
          };
          break;
        case MWPSiteIssueEnum.SERVICE_UNAVAILABLE:
          action = {
            text: 'dashboard.sites.details.mwp.overview.banner.issue.action.viewStatusPage',
            onClick: () => window.open('https://status.one.com/', '_blank'),
            endIcon: true,
          };
          break;
        default:
          action = {
            text: 'dashboard.general.action.contactSupport',
            onClick: () => handleZendeskChatOpen(user?.brand?.id),
            endIcon: false,
          };
          break;
      }
    }
    // Core repair status banner
    else {
      // Handle title
      title = `dashboard.sites.details.mwp.overview.banner.repairCoreStatus.${
        isRepairWordpressCoreSucceed === true ? 'success' : 'error'
      }.title`;
      // Handle text
      text = `dashboard.sites.details.mwp.overview.banner.repairCoreStatus.${
        isRepairWordpressCoreSucceed === true ? 'success' : 'error'
      }.description`;
      if (isRepairWordpressCoreSucceed === true) {
        // Handle type
        type = 'success';
        // Handle close function
        handleCloseBanner = () => setIsRepairWordpressCoreSucceed(null);
      } else {
        // Handle action
        action = {
          text: 'dashboard.sites.details.mwp.overview.banner.repairCoreStatus.error.action',
          onClick: () => onOpenRepairWordpressCoreDialogDialog(),
          endIcon: false,
        };
      }
    }

    return { type, title, text, action, handleCloseBanner };
  }

  return bannerAction ? (
    <Alert
      type={bannerAction.type}
      text={translate(bannerAction.text, {
        URL: 'https://dummyurl.com',
      })}
      title={translate(bannerAction.title)}
      handleClose={bannerAction.handleCloseBanner}
      button={
        bannerAction.action && (
          <Button
            uiType="neutral"
            text={translate(bannerAction.action.text)}
            style={{
              whiteSpace: 'nowrap',
            }}
            endIcon={bannerAction.action.endIcon ? 'open_in_new' : ''}
            onClick={bannerAction.action.onClick}
          />
        )
      }
    />
  ) : (
    <></>
  );
}
