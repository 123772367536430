import { useLocation } from 'react-router';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import { removeChangedPlanSite } from 'src/redux/features/site';
// @types
import { SitePlanEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type Props = {
  siteName: string;
  newPlan: SitePlanEnum;
  localDiskLimit: number;
  planDiskLimit: number;
  onOpenUpgradeDiskDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ChangePlanStatusBanner({
  siteName,
  newPlan,
  localDiskLimit,
  planDiskLimit,
  onOpenUpgradeDiskDialog,
}: Props) {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { planChangedSites } = useSelector((state) => state.site);

  // VAR
  const tabname = pathname.split('/')[2];

  const site = planChangedSites.find(
    (site) => site.siteName === siteName && site.fromTab === tabname
  );

  return (
    <>
      {site && (
        <div className="gv-activated">
          <Alert
            type={site.isSuccess ? 'success' : 'warning'}
            title={translate(
              `wpone.sites.details.changePlanStatusBanner.${
                site.isUpgraded ? 'upgrade' : 'downgrade'
              }.${site.isSuccess ? 'success' : 'error'}.title`
            )}
            text={translate(
              `wpone.sites.details.changePlanStatusBanner.${
                site.isUpgraded ? 'upgrade' : 'downgrade'
              }.${site.isSuccess ? 'success' : 'error'}.description`,
              {
                planName: translate(`wpone.sites.plan.${newPlan}`),
              }
            )}
            button={
              site.isUpgraded && site.isSuccess && localDiskLimit < planDiskLimit ? (
                <Button
                  uiType="neutral"
                  text={translate(
                    'wpone.sites.details.changePlanStatusBanner.upgrade.success.action'
                  )}
                  onClick={onOpenUpgradeDiskDialog}
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                />
              ) : undefined
            }
            handleClose={() => dispatch(removeChangedPlanSite(siteName))}
          />
        </div>
      )}
    </>
  );
}
