import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// @types
import { SiteTabNavLinkDetails, TabNavLinkDetails } from 'src/@types/layout';

// ----------------------------------------------------------------------

type Props = {
  navConfig: TabNavLinkDetails[] | SiteTabNavLinkDetails[];
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function PageDetailsTabGuard({ navConfig, children }: Props) {
  const { tabname } = useParams();

  const isExisted = navConfig.find((tab) => tab.tabName === tabname) ? true : false;

  if (!isExisted) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
