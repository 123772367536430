import { useParams } from 'react-router';
// redux
import { useRestartServerMutation } from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

export default function WPInstallationSettings() {
  const { namespace, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [restartServer, { isLoading }] = useRestartServerMutation();

  // EVENT FUNCTION
  function handleRestartServer() {
    if (!namespace || !cluster) return;

    restartServer(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (data) {
          displayToast(
            translate('wponesupport.sites.details.support.wpInstallationSettings.toast.success')
          );
        } else {
          displayToast(
            translate('wponesupport.sites.details.support.wpInstallationSettings.toast.error'),
            { variant: 'alert' }
          );
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  }

  return (
    <SettingsBlock
      title={translate('wponesupport.sites.details.support.wpInstallationSettings.title')}
    >
      <div className="gv-w-full gv-flex-column-md gv-tab-flex-row gv-tab-items-center">
        <p className="gv-text-sm">
          {translate('wponesupport.sites.details.support.wpInstallationSettings.description')}
        </p>
        <Button
          condensed
          text={translate('wponesupport.sites.details.support.wpInstallationSettings.action')}
          onClick={handleRestartServer}
          disabled={isLoading}
          style={{ whiteSpace: 'nowrap' }}
        />
      </div>
    </SettingsBlock>
  );
}
