// @types
import { BackupDetails } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import AbortBackupConfirmation from './AbortBackupConfirmation';

// ----------------------------------------------------------------------

type Props = {
  backupDetails: BackupDetails;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AbortMigrationDialog({ backupDetails, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<AbortBackupConfirmation backupDetails={backupDetails} onClose={onClose} />}
    />
  );
}
