import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
import { isHostnetCustomer } from 'src/utils/check';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function CreateSitePlanGuard({ children }: Props) {
  // STATE
  const { user } = useSelector((state) => state.auth);
  const {
    create: { isCreating, isMigrating },
  } = useSelector((state) => state.plan);

  if (isHostnetCustomer(user)) {
    return <Navigate to={`/404`} replace />;
  }

  if (!isCreating && !isMigrating) {
    return <Navigate to="/sites" replace />;
  }

  return <>{children}</>;
}
