import { useRef, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { setHeaderHeight, toggleScrollLock } from 'src/redux/features/layout';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, Avatar, Container } from '@mui/material';
// @types
import { NavLinkDetails } from 'src/@types/layout';
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { isActivePage } from 'src/utils/check';
import generateGravatarUrl from 'src/utils/generateGravatarUrl';
import { displayActionsBanner } from 'src/utils/layout';
// config
import { HEADER, NAV_CONFIG } from 'src/config';
// components
import Logo from 'src/components/Logo';
import MaterialIcon from 'src/components/MaterialIcon';
import { IconButtonAnimate } from 'src/components/animate';
//
import Searchbar from './Searchbar';
import ActionsInProgressBanner from './ActionsInProgressBanner';

// ----------------------------------------------------------------------

type Props = {
  handleOpenDashboardDrawer: VoidFunction;
  handleOpenTabDrawer: VoidFunction;
};

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 1,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  boxShadow: 'none',
}));

// ----------------------------------------------------------------------

export default function DashboardHeader({ handleOpenDashboardDrawer, handleOpenTabDrawer }: Props) {
  const location = useLocation();

  const dispatch = useDispatch();

  const dashboardHeaderRef = useRef<HTMLDivElement>(null);

  // STATE
  const { user } = useSelector((state) => state.auth);

  const { tabConfig, showActionsInProgressBanner, overflowPadding } = useSelector(
    (state) => state.layout
  );

  const { migrationsInProgress } = useSelector((state) => state.migration);

  const { backupsInProgress } = useSelector((state) => state.backup);

  const [openSearchbar, setOpenSearchbar] = useState<boolean>(false);

  // VAR
  const gravatarImage = generateGravatarUrl(user?.email || 'user');

  // HOOK
  const isDesktop = useResponsive('up', 'desktop_min');

  // EVENT FUNCTION
  function handleOpenSearchbar() {
    setOpenSearchbar(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseSearchbar() {
    setOpenSearchbar(false);
    dispatch(toggleScrollLock(false));
  }

  //
  // Handle keeping track of header height
  useEffect(() => {
    function handleResize() {
      dispatch(
        setHeaderHeight(
          dashboardHeaderRef?.current?.clientHeight
            ? dashboardHeaderRef.current.clientHeight - (openSearchbar ? 62 : 0) // Skip searchbar height
            : 0
        )
      );
    }

    // And cleanup previous event listener
    window.addEventListener('resize', handleResize);

    // Call handleResize immediately to set initial height
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    location,
    dashboardHeaderRef?.current?.clientHeight,
    showActionsInProgressBanner,
    openSearchbar,
    dispatch,
  ]);

  return (
    <RootStyle id="header" ref={dashboardHeaderRef}>
      {showActionsInProgressBanner &&
        displayActionsBanner(migrationsInProgress, backupsInProgress).length > 0 && (
          <Box>
            <ActionsInProgressBanner />
          </Box>
        )}

      <Stack
        direction="column"
        sx={{
          height: HEADER.HEIGHT,
          borderBottom: '1px solid #E2E2E2',
          paddingLeft: `${isDesktop ? 48 : 24}px !important`,
          paddingRight: `${(isDesktop ? 48 : 24) + overflowPadding}px !important`,
        }}
      >
        <Container sx={{ maxWidth: '1536px !important', p: '0 !important' }}>
          <Toolbar
            sx={{
              minHeight: `${HEADER.HEIGHT}px !important`,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '0 !important',
            }}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
              {!isDesktop && tabConfig && (
                <MaterialIcon
                  id="header-menu-icon"
                  icon="menu"
                  onClick={handleOpenTabDrawer}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
              )}
              <Box sx={{ height: 40, width: 96 }}>
                <Logo />
              </Box>
              {isDesktop && (
                <Searchbar
                  openSearchbar={openSearchbar}
                  handleCloseSearchbar={handleCloseSearchbar}
                />
              )}
            </Stack>

            {isDesktop ? (
              <>
                <Stack direction={'row'}>
                  {(user?.role !== UserRoleEnum.customer
                    ? user?.role !== UserRoleEnum.support
                      ? NAV_CONFIG.slice(0, 4)
                      : NAV_CONFIG
                    : NAV_CONFIG.slice(0, 2)
                  ).map((navItem, index) => (
                    <NavLinkItem key={index} link={navItem} />
                  ))}
                </Stack>
                <IconButtonAnimate
                  id="header-avatar-icon"
                  onClick={handleOpenDashboardDrawer}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    src={gravatarImage}
                    alt={user?.email || 'user'}
                    sx={{ width: 24, height: 24 }}
                  />
                </IconButtonAnimate>
              </>
            ) : (
              <Stack direction="row" spacing={2} alignItems="center">
                <MaterialIcon
                  id="header-search-icon"
                  icon="search"
                  onClick={() => {
                    if (openSearchbar) {
                      handleCloseSearchbar();
                    } else {
                      handleOpenSearchbar();
                    }
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                />
                <IconButtonAnimate
                  id="header-avatar-icon"
                  onClick={handleOpenDashboardDrawer}
                  sx={{ p: 0 }}
                >
                  <Avatar
                    src={gravatarImage}
                    alt={user?.email || 'user'}
                    sx={{ width: 24, height: 24 }}
                  />
                </IconButtonAnimate>
              </Stack>
            )}
          </Toolbar>
        </Container>
      </Stack>

      {openSearchbar && !isDesktop && (
        <Searchbar openSearchbar={openSearchbar} handleCloseSearchbar={handleCloseSearchbar} />
      )}
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type NavLinkProps = {
  link: NavLinkDetails;
};

// ----------------------------------------------------------------------

const NavLinkItem = ({ link }: NavLinkProps) => {
  const { pathname } = useLocation();

  // HOOK
  const { translate } = useLocales();

  // VAR
  const active = isActivePage(`/${link.path.split('/')[1]}`, pathname);

  return (
    <Box
      component={RouterLink}
      to={link.path}
      sx={{ textDecoration: 'none', color: (theme) => theme.palette.text.primary }}
    >
      <Typography
        variant="body2"
        fontWeight={active ? 600 : 400}
        sx={{
          p: '16px 24px',
          backgroundColor: active ? '#F7F7F7' : '#FFF',
          borderBottom: active ? '1px solid #CECECE' : '1px solid #E2E2E2',
        }}
      >
        {translate(link.title)}
      </Typography>
    </Box>
  );
};
