import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user } = useSelector((state) => state.auth);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}
