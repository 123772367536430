import { useEffect } from 'react';
import { unstable_usePrompt } from 'react-router-dom';

// ----------------------------------------------------------------------

type Props = {
  message?: string;
  isDirty: boolean;
};

// ----------------------------------------------------------------------

export const useBeforeUnload = ({
  message = 'Changes you made may not be saved.',
  isDirty,
}: Props) => {
  // This hook prompts the user with a message when they try to navigate away from the current page or close the browser window/tab if there are unsaved changes (isDirty is true).
  // Note that the prompt for user navigate to different paths have to achineved by using unstable_usePrompt hook from react-router-dom, it is not possible to achive this by using window.onbeforeunload event.
  // Handle navigating internally -> useNavigate() from react-router-dom
  unstable_usePrompt({
    message,
    when: ({ currentLocation, nextLocation }) =>
      isDirty &&
      currentLocation.pathname !== nextLocation.pathname &&
      nextLocation.pathname !== '/login',
  });

  // Handle window.location changed (including reload/close and change path in the browser address bar)
  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.returnValue = message; // Standard for most browsers
        return message;
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty, message]);
};

export default useBeforeUnload;
