import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

type Props = {
  amount: string;
  description?: string;
  currentVal: number;
  minVal?: number;
  maxVal: number;
  defaultThresholds?: boolean;
  customThresholds?: {
    warning: number;
    alert: number;
  }; // Set to default bar when no thresholds
  style?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function Resource({
  amount,
  description,
  currentVal,
  minVal = 0,
  maxVal,
  defaultThresholds,
  customThresholds,
  style,
}: Props) {
  // VAR
  const valPercent = (currentVal / (maxVal - minVal)) * 100;

  const thresholds = defaultThresholds
    ? {
        warning: 60,
        alert: 80,
      }
    : customThresholds;

  const barType = thresholds
    ? valPercent <= thresholds.warning
      ? 'good'
      : valPercent <= thresholds.alert
      ? 'warning'
      : 'alert'
    : undefined;

  return (
    <div className="gv-resource" style={style}>
      <div
        className="gv-progress"
        role="progressbar"
        aria-valuenow={valPercent}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className={`gv-bar${barType ? ` gv-${barType}` : ''}`}
          style={{ width: `${valPercent}%` }}
        />
      </div>
      <div className="gv-content">
        <span className="gv-amount">{amount}</span>
        {description && <span className="gv-description">{description}</span>}
      </div>
    </div>
  );
}
