import { InputHTMLAttributes, ReactNode } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
//
import TextField from '../form/TextField';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  generalMsg?: string;
  addonPrefix?: string;
  addonSuffix?: ReactNode;
  condensed?: boolean;
  nonInputElement?: boolean;
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  label,
  labelId,
  labelOptional,
  labelIcon,
  labelIconTooltip,
  generalMsg,
  addonPrefix,
  addonSuffix,
  condensed,
  nonInputElement,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label}
          labelId={labelId}
          labelOptional={labelOptional}
          labelIcon={labelIcon}
          labelIconTooltip={labelIconTooltip}
          inputErr={!!error}
          errMsg={error?.message}
          generalMsg={generalMsg}
          addonPrefix={addonPrefix}
          addonSuffix={addonSuffix}
          condensed={condensed}
          nonInputElement={nonInputElement}
          {...other}
        />
      )}
    />
  );
}
