// @types
import { TableHeader } from 'src/@types/layout';

// ----------------------------------------------------------------------

type Props = {
  headers: TableHeader[];
};

// ----------------------------------------------------------------------

export default function TableSkeleton({ headers }: Props) {
  return (
    <tr>
      {headers.map((_, index) => (
        <td key={index}>
          <div className="gv-skeleton" />
        </td>
      ))}
    </tr>
  );
}
