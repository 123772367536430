import { SelectHTMLAttributes } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
//
import Select from '../form/Select';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: { value: string | number; label: string; disabled?: boolean }[];
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  condensed?: boolean;
};

type Props = IProps & SelectHTMLAttributes<HTMLSelectElement>;

// ----------------------------------------------------------------------

export default function RHFSelect({
  name,
  options,
  label,
  labelId,
  labelOptional,
  labelIcon,
  labelIconTooltip,
  condensed,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          {...field}
          options={options}
          label={label}
          labelId={labelId}
          labelOptional={labelOptional}
          labelIcon={labelIcon}
          labelIconTooltip={labelIconTooltip}
          inputErr={!!error}
          errMsg={error?.message}
          condensed={condensed}
          {...other}
        />
      )}
    />
  );
}
