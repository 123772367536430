import { ReactNode, useState, CSSProperties } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  openByDefault?: number; // Set index of item to open it be default
  accordionItems: {
    title: string;
    content: ReactNode;
  }[];
  style?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function Accordion({ openByDefault, accordionItems, style }: Props) {
  // STATE
  const [activeIndex, setActiveIndex] = useState<number | undefined>(openByDefault);

  // EVENT FUNCTION
  function handleToggleItem(newIndex: number) {
    // Open new item
    if (activeIndex !== newIndex) {
      setActiveIndex(newIndex);
    }
    // Close currently open item
    else {
      setActiveIndex(undefined);
    }
  }

  return (
    <div className="gv-accordion" style={style}>
      {accordionItems.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          children={item.content}
          activeIndex={activeIndex}
          itemIndex={index}
          handleToggleItem={handleToggleItem}
        />
      ))}
    </div>
  );
}

// ----------------------------------------------------------------------

type AccordionItemProps = {
  title: string;
  children: ReactNode;
  activeIndex: number | undefined;
  itemIndex: number;
  handleToggleItem: (newIndex: number) => void;
};

// ----------------------------------------------------------------------

function AccordionItem({
  title,
  children,
  activeIndex,
  itemIndex,
  handleToggleItem,
}: AccordionItemProps) {
  // HOOKS
  const { translate } = useLocales();

  return (
    <div className="gv-acc-item">
      <h2 className="gv-acc-header" onClick={() => handleToggleItem(itemIndex)}>
        <button
          id={`acc-trigger-${itemIndex.toString().padStart(2, '0')}`}
          className={`gv-acc-trigger${activeIndex === itemIndex ? ' gv-expanded' : ''}`}
          aria-expanded={activeIndex === itemIndex}
          aria-controls={`acc-body-${itemIndex.toString().padStart(2, '0')}`}
        >
          <span className="gv-acc-title">{translate(title)}</span>
        </button>
      </h2>
      <div
        id={`acc-body-${itemIndex.toString().padStart(2, '0')}`}
        role="region"
        aria-labelledby={`acc-trigger-${itemIndex.toString().padStart(2, '0')}`}
        className={`gv-acc-body${activeIndex === itemIndex ? '' : ' gv-hidden'}`}
      >
        <div className="gv-acc-content">{children}</div>
      </div>
    </div>
  );
}
