// @mui
import { Breakpoint } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// theme
import breakpoints from 'src/theme/breakpoints';

// ----------------------------------------------------------------------

type Query = 'up' | 'down' | 'between';
type Key = Breakpoint | number;
type Start = Breakpoint | number;
type End = Breakpoint | number;

type GravityBreakPoint =
  | 'mobile_min'
  | 'mobile_max'
  | 'tablet_min'
  | 'tablet_max'
  | 'desktop_min'
  | 'desktop_max'
  | 'mobile_small_max'
  | 'mobile_large_min'
  | 'tablet_small_max'
  | 'tablet_large_min'
  | 'desktop_small_max'
  | 'desktop_large_min';

// ----------------------------------------------------------------------

export default function useResponsive(
  query: Query,
  key?: Key | GravityBreakPoint,
  start?: Start | GravityBreakPoint,
  end?: End | GravityBreakPoint
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(breakpointConverter(key)));

  const mediaDown = useMediaQuery(theme.breakpoints.down(breakpointConverter(key)));

  const mediaBetween = useMediaQuery(
    theme.breakpoints.between(breakpointConverter(start), breakpointConverter(end))
  );

  // HELPER FUNCTION
  function breakpointConverter(value?: Key | GravityBreakPoint): number {
    return isNaN(Number(value))
      ? breakpoints.values[value as Breakpoint | GravityBreakPoint]
      : Number(value);
  }

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }
}
