import { FileRejection } from 'react-dropzone';
// utils
import { fData } from 'src/utils/format';
import getFileData from 'src/utils/getFileData';

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[];
};

// ----------------------------------------------------------------------

export default function RejectionFiles({ fileRejections }: Props) {
  return (
    <div
      className="gv-content-container gv-py-sm gv-px-md gv-mt-lg"
      style={{
        borderColor: 'var(--color-signal-alert)',
        backgroundColor: 'var(--color-signal-alert-light)',
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = getFileData(file);

        return (
          <div key={path} className="gv-my-sm">
            <p className="gv-text-md">
              {path} - {size ? fData(size) : ''}
            </p>
            {errors.map((error) => (
              <li key={error.code} className="gv-text-bold">
                {error.message}
              </li>
            ))}
          </div>
        );
      })}
    </div>
  );
}
