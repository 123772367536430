import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useHighestUserRole from 'src/hooks/useHighestUserRole';
// utils
import { isHostnetCustomer } from 'src/utils/check';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function ChangeSitePlanGuard({ children }: Props) {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);
  const {
    upgrade: { upgradingSite },
  } = useSelector((state) => state.plan);

  if (isHostnetCustomer(user)) {
    return <Navigate to={`/404`} replace />;
  }

  if (
    user?.role === UserRoleEnum.customer &&
    !isHighestUserRoleLoading &&
    highestUserRole !== AccessTypeEnum.owner
  ) {
    return <Navigate to={`/403`} replace />;
  }

  if (!upgradingSite) {
    return <Navigate to={`/sites/plan/${cluster}/${namespace}/${name}`} replace />;
  }

  return <>{children}</>;
}
