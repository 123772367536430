import { InputHTMLAttributes } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  options: { value: string; label: string }[];
  condensed?: true;
  radioSpace?: 'sm' | 'md' | 'lg';
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

export default function RHFRadioGroup({
  name,
  options,
  condensed,
  radioSpace = 'sm',
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="gv-activated">
          <div className={`gv-stack-space-${radioSpace}`}>
            {options.map((option) => (
              <div key={option.value} className={`${condensed ? ' gv-mode-condensed' : ''}`}>
                <div className="gv-form-option">
                  <div className="gv-option-inline">
                    <input
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                      onChange={() => field.onChange(option.value)}
                      className="gv-radio"
                      id={option.value}
                      type="radio"
                      {...other}
                    />

                    <label htmlFor={option.value} className="gv-label">
                      <span>{option.label}</span>
                    </label>
                  </div>
                </div>
              </div>
            ))}

            {!!error && (
              <div className={`gv-input-message ${error ? 'gv-error' : ''}`}>{error.message}</div>
            )}
          </div>
        </div>
      )}
    />
  );
}
