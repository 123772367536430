import { useEffect, useRef } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import ReactGA from 'react-ga4';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import { setFooterHeight } from 'src/redux/features/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { handleZendeskChatOpen } from 'src/utils/zendesk';
// config
import { METANET_ZENDESK_KEY, ZENDESK_KEY, ZendeskDepartmentIds } from 'src/config';
// @types
import { BrandIdEnum } from 'src/@types/user';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

export default function DashboardFooter() {
  // HOOK
  const { translate, currentLang } = useLocales();

  const dispatch = useDispatch();

  const dashboardFooterRef = useRef<HTMLDivElement>(null);

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const departmentList =
    user?.brand?.id === BrandIdEnum.zoner
      ? ZendeskDepartmentIds.zoner
      : ZendeskDepartmentIds.otherBrands;

  // EVENT FUNCTION
  const setChatInfo = () => {
    // Prefill name and email
    ZendeskAPI('webWidget', 'prefill', {
      name: {
        value: user ? user.name : '',
        readOnly: true,
      },
      email: {
        value: user ? user.email : '',
        readOnly: true,
      },
    });

    // Hide widget
    ZendeskAPI('webWidget', 'hide');

    ZendeskAPI('webWidget:on', 'close', function () {
      ZendeskAPI('webWidget', 'hide');
    });

    // Set current language
    ZendeskAPI('webWidget', 'setLocale', currentLang.value);

    // Add tag based on user brand
    ZendeskAPI('webWidget', 'chat:addTags', [
      `wpone-${user?.brand ? user.brand.name.toLowerCase() : 'no-brand'}`,
      'wpone-dashboard-chat',
    ]);

    // Get first online department
    let checkDepartmentArr: { id: number; status: string }[] = [];
    ZendeskAPI(
      'webWidget:on',
      'chat:departmentStatus',
      function (department: { id: number; status: string }) {
        if (
          departmentList.includes(department.id) &&
          checkDepartmentArr.length < departmentList.length
        ) {
          // Check if the department with the same id exists in the checkDepartmentArr
          const existingItemIndex = checkDepartmentArr.findIndex((dp) => dp.id === department.id);
          if (existingItemIndex !== -1) {
            checkDepartmentArr[existingItemIndex] = department;
          } else {
            checkDepartmentArr.push(department);
          }

          // If checkedDepartmentArr's length = departmentList's length => Finished getting all needed departments for checkedDepartmentArr
          if (checkDepartmentArr.length === departmentList.length) {
            // Reorder so that checkDepartmentArr items will follow departmentList for priority
            checkDepartmentArr.sort(
              (a, b) => departmentList.indexOf(a.id) - departmentList.indexOf(b.id)
            );

            // Update settings with the first online department in the array that following priority order
            ZendeskAPI('webWidget', 'updateSettings', {
              webWidget: {
                chat: {
                  departments: {
                    enabled: [checkDepartmentArr.filter((dp) => dp.status === 'online')[0].id],
                    select: checkDepartmentArr.filter((dp) => dp.status === 'online')[0].id,
                  },
                },
              },
            });
          }
        }
      }
    );

    // Send start chat event to GA
    ZendeskAPI('webWidget:on', 'chat:start', function () {
      ReactGA.event({
        category: 'button',
        action: 'click',
        label: 'start-chat',
      });
    });
  };

  const setMetanetChatInfo = () => {
    // Hide widget
    ZendeskAPI('messenger', 'hide');

    ZendeskAPI('messenger:on', 'close', function () {
      ZendeskAPI('messenger', 'hide');
    });
  };

  // Default setting
  const setting = {
    color: {
      theme: '#5866E4',
    },
    webWidget: {
      chat: {
        concierge: {
          avatarPath: 'https://app.wp.one/assets/wpone-login-logo.svg',
          name: 'WP.one',
          title: { '*': 'Support', fi: 'Tuki', nl: 'Support', de: 'Support', no: 'Support' },
        },
        title: {
          '*': 'Chat',
          fi: 'Chatti',
          nl: 'Chat',
          de: 'Chat',
          no: 'Chat',
        },
        departments: {
          enabled: [departmentList[0]],
          select: departmentList[0],
        },
        prechatForm: {
          greeting: {
            '*':
              user?.brand?.id === BrandIdEnum.zoner
                ? "We're online 24/7\n∘ Finnish/English Mon-Fri 8:00-16:00\n∘ Finnish/English Sat-Sun 10:00-16:00\n∘ English outside the listed times"
                : "We're online 24/7. The chat is available in English.",
            fi:
              user?.brand?.id === BrandIdEnum.zoner
                ? 'Chattimme auttaa 24/7\n∘ suomeksi ma-pe 8:00-17:00\n∘ suomeksi la-su 10:00-16:00\n∘ englanniksi iltaisin, öisin ja pyhäpäivinä'
                : 'Olemme tavoitettavissa verkossa 24/7. Chat on käytettävissä englanniksi.',
            nl:
              user?.brand?.id === BrandIdEnum.zoner
                ? 'We zijn online 24/7\n∘ Fins / Engels ma-vr 8:00-16:00\n∘ Fins / Engels za-zo 10:00-16:00\n∘ Engels buiten de vermelde tijden'
                : 'We zijn 24/7 online. De chat is beschikbaar in het Engels.',
            de:
              user?.brand?.id === BrandIdEnum.zoner
                ? 'Wir sind 24/7 online\n∘ Finnisch/Englisch Mo-Fr 8:00-16:00\n∘ Finnisch/Englisch Sa-So 10:00-16:00\n∘ Englisch außerhalb der aufgeführten Zeiten'
                : 'Wir sind 24/7 online. Der Chat ist auf Englisch verfügbar.',
            no:
              user?.brand?.id === BrandIdEnum.zoner
                ? 'Vi er online 24/7\n∘ Finsk/Engelsk man-fre 8:00-16:00\n∘ Finsk/Engelsk lør-søn 10:00-16:00\n∘ Engelsk utenfor de oppgitte tidene'
                : 'Vi er online 24/7. Chatten er tilgjengelig på engelsk.',
          },
        },
      },
    },
  };

  //
  // Handle keeping track of footer height
  useEffect(() => {
    function handleResize() {
      dispatch(
        setFooterHeight(
          dashboardFooterRef?.current?.clientHeight ? dashboardFooterRef.current.clientHeight : 0
        )
      );
    }

    // And cleanup previous event listener
    window.addEventListener('resize', handleResize);

    // Call handleResize immediately to set initial height
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <div className="gv-activated">
      {user?.brand?.id === BrandIdEnum.metanet ? (
        <Zendesk defer zendeskKey={METANET_ZENDESK_KEY} onLoaded={setMetanetChatInfo} />
      ) : (
        <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={setChatInfo} />
      )}

      <footer className="gv-footer" ref={dashboardFooterRef}>
        <div className="gv-footer-support">
          <div className="gv-footer-content">
            <div className="gv-support-text">
              <gv-tile src="/src/tiles/chat.svg" />
              <p>{translate('wpone.footer.description')}</p>
            </div>
            <Button
              text={translate('wpone.footer.action')}
              onClick={() => handleZendeskChatOpen(user?.brand?.id)}
            />
          </div>
        </div>
      </footer>
    </div>
  );
}
