export default function ToastProvider() {
  // To show a toast add a container with the class name .gv-toast-container to the page.
  // Inject the toast with javascript to this container, the code can be found in handleToast file.

  return (
    <div className="gv-activated">
      <div className="gv-toast-container" />
    </div>
  );
}
