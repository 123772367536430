// @types
import { SiteColumn, SiteColumnsEnum, SiteInfoCategoryEnum } from './@types/site';
import { AccessTypeEnum, LocaleEnum, UserRoleEnum } from './@types/user';
import {
  FeatureReleaseDetails,
  TabNavLinkDetails,
  PageDetailsTabConfig,
  SiteTabNavLinkDetails,
  NavLinkDetails,
} from './@types/layout';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// ZENDESK
// ----------------------------------------------------------------------

export const ZENDESK_KEY = '210e855f-e8e5-4426-bbd9-00a18cc7a398';
export const METANET_ZENDESK_KEY = 'ac711c41-6764-4186-aef7-bc949a781b4d';

// 1900000512873: Zoner Finnish support
// 12605405622417: Cebu WP.one
// 360010042257: Cebu Zoner team
export const ZendeskDepartmentIds = {
  zoner: [1900000512873, 12605405622417, 360010042257],
  otherBrands: [12605405622417],
};

// FEATURES
// ----------------------------------------------------------------------

// Year - Month (INDEX 0 - 11) - Day for releaseDate
export const FEATURE_RELEASE: FeatureReleaseDetails[] = [
  {
    name: 'migration',
    version: 1.1,
    releaseDate: new Date(2023, 7, 9),
    text: {
      title: 'wpone.whatsNewModal.migration.title',
      description: 'wpone.whatsNewModal.migration.description',
    },
  },
  {
    name: 'backup',
    version: 1.2,
    releaseDate: new Date(2024, 0, 30),
    text: {
      title: 'wpone.whatsNewModal.restore.title',
      description: 'wpone.whatsNewModal.restore.description',
    },
  },
];

// NAV CONFIG
// ----------------------------------------------------------------------

export const NAV_CONFIG: NavLinkDetails[] = [
  { title: 'wpone.navbar.sites', path: '/sites', icon: 'desktop_windows' },
  { title: 'wpone.navbar.dns', path: '/dns', icon: 'dns' },
  {
    title: 'wponesupport.navbar.customers',
    path: '/customers',
    icon: 'group',
  },
  {
    title: 'wponesupport.navbar.changeHistory',
    path: '/plan-change-history',
    icon: 'history',
  },
  {
    title: 'wponesupport.navbar.data',
    path: '/data/overview',
    icon: 'monitoring',
  },
];

export const WPONE_SITE_DETAILS_NAV_CONFIG: SiteTabNavLinkDetails[] = [
  {
    title: 'wpone.sites.details.overview.tab',
    tabName: 'overview',
    icon: 'list_alt',
  },
  {
    title: 'wpone.sites.details.settings.tab',
    tabName: 'settings',
    icon: 'settings',
  },
  {
    title: 'wpone.sites.details.domains.tab',
    tabName: 'domains',
    icon: 'language',
  },
  {
    title: 'wpone.sites.details.users.tab',
    tabName: 'users',
    icon: 'group',
  },
  {
    title: 'wpone.sites.details.statistics.tab',
    tabName: 'statistics',
    icon: 'show_chart',
  },
  {
    title: 'wpone.sites.details.updates.tab',
    tabName: 'updates',
    icon: 'sync',
  },
  {
    title: 'wpone.sites.details.vulnerabilities.tab',
    tabName: 'vulnerabilities',
    icon: 'vitals',
  },
  {
    title: 'wpone.sites.details.backups.tab',
    tabName: 'backups',
    icon: 'settings_backup_restore',
    restrictedSiteRoles: [AccessTypeEnum.developer],
  },
  {
    title: 'wpone.sites.details.plan.tab',
    tabName: 'plan',
    icon: 'description',
    restrictedSiteRoles: [AccessTypeEnum.admin, AccessTypeEnum.developer],
    restrictedForHostnetCustomer: true,
  },
  {
    title: 'wponesupport.sites.details.support.tab',
    tabName: 'support',
    icon: 'info',
    restrictedUserRoles: [UserRoleEnum.serviceAccount, UserRoleEnum.customer],
  },
];

export const MWP_SITE_DETAILS_NAV_CONFIG: SiteTabNavLinkDetails[] = [
  {
    title: 'dashboard.sites.details.mwp.overview.tab',
    tabName: 'overview',
    icon: 'list_alt',
  },
  {
    title: 'dashboard.sites.details.mwp.performance.tab',
    tabName: 'performance',
    icon: 'speed',
  },
  {
    title: 'dashboard.sites.details.mwp.security.tab',
    tabName: 'security',
    icon: 'key',
  },
];

export const DNS_DETAILS_NAV_CONFIG: TabNavLinkDetails[] = [
  {
    title: 'wpone.domains.details.dnsRecords.title',
    tabName: 'records',
    icon: 'dns',
  },
  {
    title: 'wpone.domains.details.settings.title',
    tabName: 'settings',
    icon: 'settings',
  },
  {
    title: 'wpone.domains.details.users.title',
    tabName: 'users',
    icon: 'group',
  },
];

export const ACCOUNT_SETTINGS_NAV_CONFIG: TabNavLinkDetails[] = [
  {
    title: 'wpone.settings.tabs.profile',
    tabName: 'profile',
    icon: 'account_circle',
  },
  {
    title: 'wpone.settings.tabs.installationPreset',
    tabName: 'installation-preset',
    icon: 'settings',
  },
  {
    title: 'wpone.settings.tabs.sshPreset',
    tabName: 'ssh-preset',
    icon: 'key',
  },
];

export const SUPPORT_DATA_NAV_CONFIG: TabNavLinkDetails[] = [
  {
    title: 'wponesupport.data.overview.tab',
    tabName: 'overview',
    icon: 'list_alt',
  },
  {
    title: 'wponesupport.data.migrator.tab',
    tabName: 'migrator',
    icon: 'compare_arrows',
  },
  {
    title: 'wponesupport.data.updateManager.tab',
    tabName: 'update-manager',
    icon: 'sync',
  },
];

export const PAGE_DETAILS_TAB_CONFIG: {
  sites: { wpone: PageDetailsTabConfig; mwp: PageDetailsTabConfig };
  dns: PageDetailsTabConfig;
  settings: PageDetailsTabConfig;
  data: PageDetailsTabConfig;
} = {
  sites: {
    wpone: {
      primaryPathname: 'sites',
      title: 'wpone.sites.details.sidebarTitle',
      navConfig: WPONE_SITE_DETAILS_NAV_CONFIG,
    },
    mwp: {
      primaryPathname: 'sites',
      title: 'dashboard.sites.details.mwp.sidebarTitle',
      navConfig: MWP_SITE_DETAILS_NAV_CONFIG,
    },
  },
  dns: {
    primaryPathname: 'dns',
    title: 'wpone.domains.details.sidebarTitle',
    navConfig: DNS_DETAILS_NAV_CONFIG,
  },
  settings: {
    primaryPathname: 'settings',
    title: 'wpone.settings.sidebarTitle',
    navConfig: ACCOUNT_SETTINGS_NAV_CONFIG,
  },
  data: {
    primaryPathname: 'data',
    title: 'wponesupport.data.sidebarTitle',
    navConfig: SUPPORT_DATA_NAV_CONFIG,
  },
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  HEIGHT: 56,
};

export const FOOTER = {
  HEIGHT: 80,
};

export const SITE_COLUMN: SiteColumn[] = [
  // Basic info
  {
    id: SiteColumnsEnum.location,
    category: SiteInfoCategoryEnum.basic,
    label: 'wpone.sites.infoLabel.location',
    customLabel: 'wpone.sites.infoLabel.location',
    sort: true,
  },
  {
    id: SiteColumnsEnum.cluster,
    category: SiteInfoCategoryEnum.basic,
    label: 'wpone.sites.infoLabel.cluster',
    customLabel: 'wpone.sites.infoLabel.cluster',
    sort: true,
  },
  {
    id: SiteColumnsEnum.php,
    category: SiteInfoCategoryEnum.basic,
    label: 'wpone.sites.infoLabel.php',
    customLabel: 'wpone.sites.infoLabel.php',
    sort: true,
  },
  {
    id: SiteColumnsEnum.additionalDomains,
    category: SiteInfoCategoryEnum.basic,
    label: 'wpone.sites.infoLabel.additionalDomains',
    customLabel: 'wpone.sites.infoLabel.additionalDomains',
  },
  {
    id: SiteColumnsEnum.plan,
    category: SiteInfoCategoryEnum.basic,
    label: 'wpone.sites.infoLabel.plan',
    customLabel: 'wpone.sites.infoLabel.plan',
  },

  // Connectivity
  {
    id: SiteColumnsEnum.sshHost,
    category: SiteInfoCategoryEnum.connectivity,
    label: 'wpone.sites.infoLabel.sshHost',
    customLabel: 'wpone.sites.infoLabel.sshHost',
  },
  {
    id: SiteColumnsEnum.sshPort,
    category: SiteInfoCategoryEnum.connectivity,
    label: 'wpone.sites.infoLabel.sshPort',
    customLabel: 'wpone.sites.infoLabel.sshPort',
  },
  {
    id: SiteColumnsEnum.sshTerminalCommand,
    category: SiteInfoCategoryEnum.connectivity,
    label: 'wpone.sites.infoLabel.sshTerminalCommand',
    customLabel: 'wpone.sites.infoLabel.sshTerminalCommand',
  },
  {
    id: SiteColumnsEnum.dbsize,
    tooltip: 'wpone.sites.table.header.tooltip.dbSize',
    category: SiteInfoCategoryEnum.statistics,
    label: 'wpone.sites.infoLabel.dbSize',
    customLabel: 'wpone.sites.infoLabel.dbSize',
    sort: true,
  },
  {
    id: SiteColumnsEnum.diskusage,
    tooltip: 'wpone.sites.table.header.tooltip.diskUsage',
    category: SiteInfoCategoryEnum.statistics,
    label: 'wpone.sites.infoLabel.diskUsage',
    customLabel: 'wpone.sites.infoLabel.diskUsage',
    sort: true,
  },

  // Web vitals
  {
    id: SiteColumnsEnum.performance,
    tooltip: 'wpone.sites.table.header.tooltip.performance',
    category: SiteInfoCategoryEnum.vitalsScore,
    label: 'wpone.sites.infoLabel.performance',
    customLabel: 'wpone.sites.infoLabel.performance',
  },
  {
    id: SiteColumnsEnum.accessibility,
    tooltip: 'wpone.sites.table.header.tooltip.accessibility',
    category: SiteInfoCategoryEnum.vitalsScore,
    label: 'wpone.sites.infoLabel.accessibility',
    customLabel: 'wpone.sites.infoLabel.accessibility',
  },
  {
    id: SiteColumnsEnum.seo,
    tooltip: 'wpone.sites.table.header.tooltip.seo',
    category: SiteInfoCategoryEnum.vitalsScore,
    label: 'wpone.sites.infoLabel.seo',
    customLabel: 'wpone.sites.infoLabel.seo',
  },
  {
    id: SiteColumnsEnum.bestPractices,
    tooltip: 'wpone.sites.table.header.tooltip.bestPractices',
    category: SiteInfoCategoryEnum.vitalsScore,
    label: 'wpone.sites.infoLabel.bestPractices',
    customLabel: 'wpone.sites.infoLabel.bestPractices',
  },
  {
    id: SiteColumnsEnum.fcp,
    tooltip: 'wpone.sites.table.header.tooltip.fcp',
    category: SiteInfoCategoryEnum.vitalsPerformanceMetrics,
    label: 'wpone.sites.infoLabel.fcp',
    customLabel: 'wpone.sites.infoLabel.fcp',
  },
  {
    id: SiteColumnsEnum.speedIndex,
    tooltip: 'wpone.sites.table.header.tooltip.speedIndex',
    category: SiteInfoCategoryEnum.vitalsPerformanceMetrics,
    label: 'wpone.sites.infoLabel.speedIndex',
    customLabel: 'wpone.sites.infoLabel.speedIndex',
  },
  {
    id: SiteColumnsEnum.lcp,
    tooltip: 'wpone.sites.table.header.tooltip.lcp',
    category: SiteInfoCategoryEnum.vitalsPerformanceMetrics,
    label: 'wpone.sites.infoLabel.lcp',
    customLabel: 'wpone.sites.infoLabel.lcp',
  },
  {
    id: SiteColumnsEnum.blocking,
    tooltip: 'wpone.sites.table.header.tooltip.blocking',
    category: SiteInfoCategoryEnum.vitalsPerformanceMetrics,
    label: 'wpone.sites.infoLabel.blocking',
    customLabel: 'wpone.sites.infoLabel.blocking',
  },
  {
    id: SiteColumnsEnum.cls,
    tooltip: 'wpone.sites.table.header.tooltip.cls',
    category: SiteInfoCategoryEnum.vitalsPerformanceMetrics,
    label: 'wpone.sites.infoLabel.cls',
    customLabel: 'wpone.sites.infoLabel.cls',
  },

  // Tools
  {
    id: SiteColumnsEnum.phpMyAdmin,
    category: SiteInfoCategoryEnum.tools,
    label: '',
    customLabel: 'wpone.sites.infoLabel.phpMyAdmin',
  },
];

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    value: LocaleEnum.en,
  },
  {
    value: LocaleEnum.fi,
  },
  {
    value: LocaleEnum.nl,
  },
  {
    value: LocaleEnum.de,
  },
  {
    value: LocaleEnum.no,
  },
];

export const defaultLang = allLangs[0]; // English
