import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: '700px',
          borderRadius: '8px',
          '& > div:first-of-type': {
            padding: '24px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
          '& > div:last-of-type': {
            padding: '24px',
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            borderRadius: '0px',
            margin: '0px',
            '& > div:last-of-type': {
              flexDirection: 'column-reverse',
              '& > button': {
                width: '100%',
              },
            },
          },
        },
        paperFullWidth: {
          width: '100%',
        },
      },
    },
  };
}
