import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& th': {
            borderBottom: '1px solid #CECECE',
          },

          '& .MuiTypography-root': {
            ...theme.typography.body2,
            fontWeight: 600,
            color: '#141414',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& td': {
            border: 'none',
          },
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#F7F7F7',
            '&:hover': {
              backgroundColor: '#F7F7F7',
            },
          },
          '& tr:nth-of-type(even)': {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
          },
        },
        body: {
          '&:first-of-type': {
            paddingLeft: theme.spacing(3),
          },
          '&:last-of-type': {
            paddingRight: theme.spacing(3),
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
  };
}
