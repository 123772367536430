import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// @types
import { BackupState, BackupDetails, BackupStatus } from 'src/@types/site';

// ----------------------------------------------------------------------

const initialState: BackupState = {
  backupsInProgress: [],
};

const slice = createSlice({
  name: 'backup',
  initialState,
  reducers: {
    // ADD BACKUP IN PROGRESS
    addBackupInProgress(state, action: PayloadAction<BackupDetails>) {
      state.backupsInProgress = [...state.backupsInProgress, action.payload];
    },
    // REMOVE BACKUP IN PROGRESS
    removeBackupInProgress(state, action: PayloadAction<BackupDetails>) {
      state.backupsInProgress = state.backupsInProgress.filter(
        (backup) => backup.settings.hostname !== action.payload.settings.hostname
      );
    },
    // CHANGE BACKUP STATUS
    changeBackupStatus(
      state,
      action: PayloadAction<{
        hostname: string;
        newStatus: BackupStatus;
        endedAt: Date;
      }>
    ) {
      state.backupsInProgress = state.backupsInProgress.map((backupDetails) => {
        if (backupDetails.settings.hostname !== action.payload.hostname) {
          return backupDetails;
        }

        return {
          ...backupDetails,
          currentStatus: action.payload.newStatus,
          endedAt: action.payload.endedAt,
        };
      });
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addBackupInProgress, removeBackupInProgress, changeBackupStatus } = slice.actions;
