import { useNavigate, useParams } from 'react-router';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import Button from 'src/components/gravity/Button';
//
import PageSection from '../PageSection';

// ----------------------------------------------------------------------

export default function StatisticsSettingsBlock() {
  const { name, cluster, namespace } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate, currentLang } = useLocales();

  return (
    <PageSection title={translate('wpone.sites.details.overview.statisticsSettings.title')}>
      <StatisticsAndSettingsCard
        title="wpone.sites.details.overview.statisticsSettings.websitePerformace.title"
        description="wpone.sites.details.overview.statisticsSettings.websitePerformace.description"
        ctaBtn={{
          text: 'wpone.sites.details.overview.statisticsSettings.websitePerformace.action',
          onClick: () => navigate(`/sites/statistics/${cluster}/${namespace}/${name}`),
        }}
      />

      <StatisticsAndSettingsCard
        title="wpone.sites.details.overview.statisticsSettings.siteSettings.title"
        description="wpone.sites.details.overview.statisticsSettings.siteSettings.description"
        ctaBtn={{
          text: 'wpone.sites.details.overview.statisticsSettings.siteSettings.action',
          onClick: () => navigate(`/sites/settings/${cluster}/${namespace}/${name}`),
        }}
      />

      <StatisticsAndSettingsCard
        title="wpone.sites.details.overview.statisticsSettings.help.title"
        description="wpone.sites.details.overview.statisticsSettings.help.description"
        ctaBtn={{
          text: 'wpone.sites.details.overview.statisticsSettings.help.action',
          onClick: () => window.open(`https://help.wp.one/hc/${currentLang.value}`, '_blank'),
        }}
      />
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type StatisticsAndSettingsCardProps = {
  title: string;
  description: string;
  ctaBtn: {
    text: string;
    onClick: VoidFunction;
  };
};

// ----------------------------------------------------------------------

function StatisticsAndSettingsCard({ title, description, ctaBtn }: StatisticsAndSettingsCardProps) {
  // HOOK
  const { translate } = useLocales();

  const isTablet = useResponsive('between', undefined, 'tablet_min', 'tablet_max');

  return (
    <div className="gv-card gv-flex-1" style={{ backgroundColor: 'var(--color-surface-bright)' }}>
      <div className="gv-card-content">
        <h3 className="gv-card-title">{translate(title)}</h3>
        <p className="gv-text-sm">{translate(description)}</p>
      </div>

      <div className="gv-card-footer">
        {ctaBtn && (
          <Button
            text={translate(ctaBtn.text)}
            uiType="secondary"
            condensed
            onClick={ctaBtn.onClick}
            style={{
              width: isTablet ? 'auto' : '100%',
              marginTop: '8px',
            }}
          />
        )}
      </div>
    </div>
  );
}
