// hooks
import useLocales from 'src/hooks/useLocales';
// components
import IconWrapper from 'src/components/gravity/IconWrapper';

// ----------------------------------------------------------------------

type Props = {
  iconName: string;
  link: string;
  title: string;
  description: string;
};

// ----------------------------------------------------------------------

export default function HelpCard({ title, description, iconName, link }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-content-container gv-p-lg gv-flex-row-md gv-items-center gv-flex-1"
      onClick={() => window.open(link, '_blank')}
      style={{
        cursor: 'pointer',
      }}
    >
      <IconWrapper>
        <gv-icon src={`/src/icons/${iconName}.svg`} />
      </IconWrapper>
      <div className="gv-flex-1">
        <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
        <p className="gv-caption-lg gv-text-on-alternative">{translate(description)}</p>
      </div>
      <IconWrapper>
        <gv-icon src="/src/icons/arrow_forward.svg" />
      </IconWrapper>
    </div>
  );
}
