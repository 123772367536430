// @types
import { ProgressStepStatusEnum } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Loader from './Loader';

// ----------------------------------------------------------------------

type Props = {
  status: ProgressStepStatusEnum;
  text: string;
};

// ----------------------------------------------------------------------

export default function ProgressStep({ status, text }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-flex-row-md gv-justify-between gv-py-sm gv-px-md gv-radius"
      style={{ backgroundColor: 'var(--color-surface-dim)' }}
    >
      <div className="gv-flex-row-sm">
        {status === ProgressStepStatusEnum.IN_PROGRESS ? (
          <Loader condensed />
        ) : (
          <gv-icon
            src={`/src/icons/${
              status === ProgressStepStatusEnum.WAITING ? 'remove' : 'check_circle'
            }.svg`}
          />
        )}
        <p className="gv-text-sm">{translate(text)}</p>
      </div>
      <div
        className={`gv-badge gv-badge-${
          status === ProgressStepStatusEnum.IN_PROGRESS
            ? 'info'
            : status === ProgressStepStatusEnum.WAITING
            ? 'generic'
            : 'success'
        }`}
      >
        {translate(`wpone.general.status.${status}`)}
      </div>
    </div>
  );
}
