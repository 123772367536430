// hooks
import useLocales from 'src/hooks/useLocales';
//
import PageSection from '../../PageSection';
import HelpCard from './HelpCard';

// ----------------------------------------------------------------------

export default function HelpBlock() {
  // HOOK
  const { translate, currentLang } = useLocales();

  return (
    <PageSection
      title={translate('dashboard.sites.details.mwp.overview.help.title')}
      description={translate('dashboard.sites.details.mwp.overview.help.description')}
    >
      <div className="gv-flex gv-tab-lg-flex-row gv-flex-col gv-gap-md gv-w-full">
        <HelpCard
          iconName="help_clinic"
          link={`https://help.wp.one/hc/${currentLang.value}`}
          title="dashboard.sites.details.mwp.overview.help.helpCentre.title"
          description="dashboard.sites.details.mwp.overview.help.helpCentre.description"
        />
        <HelpCard
          iconName="chat_add_on"
          link={`https://help.wp.one/hc/${currentLang.value}/requests/new`}
          title="dashboard.sites.details.mwp.overview.help.emailSupport.title"
          description="dashboard.sites.details.mwp.overview.help.emailSupport.description"
        />
      </div>
    </PageSection>
  );
}
