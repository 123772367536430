import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useDeleteSiteDomainMutation } from 'src/redux/api/siteApi';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { TextWithBoldDynamicContent } from 'src/components/gravity/text';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  site: ListSiteResponseDTO | undefined;
  selectedDomain: string | null;
};

// ----------------------------------------------------------------------

export default function DeleteDomainConfirmation({ onClose, site, selectedDomain }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [deleteSiteDomain, { isLoading }] = useDeleteSiteDomainMutation();

  // EVENT FUNCTION
  const handleDeleteDomain = () => {
    if (!selectedDomain || !site) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'delete-domain',
    });

    deleteSiteDomain({
      cluster: site.cluster.name,
      namespace: site.namespace,
      domainName: selectedDomain,
    })
      .unwrap()
      .then(() => {
        displayToast(translate('wpone.sites.details.domains.deleteDomainDialog.toast.success'));
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.domains.deleteDomainDialog.title')}
        </h2>

        <div style={{ overflowX: 'auto' }}>
          <TextWithBoldDynamicContent
            text={translate('wpone.sites.details.domains.deleteDomainDialog.description', {
              siteName: name,
            })}
          />
        </div>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          uiType="destructive"
          text={translate('wpone.sites.details.domains.deleteDomainDialog.action')}
          onClick={handleDeleteDomain}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
