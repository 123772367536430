import { useParams } from 'react-router';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// redux
import { useEditSiteWPMemoryLimitMutation } from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  memoryLimit: string;
  afterSubmit?: string;
};

type Props = {
  currentMemoryLimit: number;
  handleEditWpMemoryLimit: (newLimit: number) => void;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function EditWPMemoryLimitForm({
  currentMemoryLimit,
  handleEditWpMemoryLimit,
  onClose,
}: Props) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [editSiteWPMemoryLimit, { isLoading }] = useEditSiteWPMemoryLimitMutation();

  // FORM
  const defaultValues = {
    memoryLimit: currentMemoryLimit.toString(),
  };

  const EditMemoryLimitSchema = Yup.object().shape({
    memoryLimit: Yup.string().test(
      'valid-limit',
      translate(
        'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.inputField.validation'
      ),
      function (value) {
        const formattedValue = Number(value);
        if (formattedValue >= 128 && formattedValue <= 1024 && Number.isInteger(formattedValue)) {
          return true;
        }
        return false;
      }
    ),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(EditMemoryLimitSchema),
    defaultValues,
  });

  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // VAR
  const memoryLimitValue = watch('memoryLimit');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!namespace || !cluster) return;
    editSiteWPMemoryLimit({ siteId: `${cluster}/${namespace}`, newLimit: data.memoryLimit })
      .unwrap()
      .then((res) => {
        if (res.code === 0) {
          displayToast(
            translate(
              'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.toast.success'
            )
          );
          handleEditWpMemoryLimit(Number(data.memoryLimit));
          onClose();
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate(
                'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.toast.error'
              ),
            });
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.toast.error'
              ),
              { variant: 'alert' }
            );
          }
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.title'
          )}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-lg" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}
            <p>
              {translate(
                'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.description'
              )}
            </p>
            <RHFTextField
              name="memoryLimit"
              label={translate(
                'wponesupport.sites.details.support.wpMemoryLimitSettings.editWpMemoryLimitDialog.inputField.label'
              )}
              labelId="memory-limit"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.save')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || memoryLimitValue === currentMemoryLimit.toString()}
        />
      </ButtonGroup>
    </>
  );
}
