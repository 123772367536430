// hooks
import useLocales from 'src/hooks/useLocales';
//
import { TextWithBoldDynamicContent } from '../text';

// ----------------------------------------------------------------------

export default function BlockContent() {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-flex gv-flex-col gv-items-center gv-justify-center gv-py-md"
      style={{ width: '100%' }}
    >
      <img
        src="/assets/upload.svg"
        alt="upload a file"
        style={{
          width: '55px',
        }}
      />

      <div className="gv-flex gv-flex-col gv-items-center gv-justify-center gv-pt-lg">
        <p style={{ fontSize: '20px' }}>{translate('wpone.general.upload.description1')}</p>

        <TextWithBoldDynamicContent
          text={translate('wpone.general.upload.description2')}
          fontSize="md"
          strongStyle={{ color: 'var(--color-signal-info)' }}
        />
      </div>
    </div>
  );
}
