// hooks
import useLocales from 'src/hooks/useLocales';
import useToggle from 'src/hooks/useToggle';
// components
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';
import ReinstallWPCoreDialog from './ReinstallWPCoreDialog';

// ----------------------------------------------------------------------

export default function WPCoreSettings() {
  // HOOK
  const { translate } = useLocales();

  const {
    toggle: toggleReinstallWPCoreDialog,
    onOpen: onOpenReinstallWPCoreDialog,
    onClose: onCloseReinstallWPCoreDialog,
  } = useToggle();

  return (
    <>
      <div style={{ position: 'absolute' }}>
        <ReinstallWPCoreDialog
          open={toggleReinstallWPCoreDialog}
          onClose={onCloseReinstallWPCoreDialog}
        />
      </div>

      <SettingsBlock title={translate('wponesupport.sites.details.support.wpCoreSettings.title')}>
        <div className="gv-w-full gv-flex-column-md gv-tab-flex-row gv-tab-items-center">
          <p className="gv-text-sm">
            {translate('wponesupport.sites.details.support.wpCoreSettings.description')}
          </p>
          <Button
            condensed
            text={translate('wponesupport.sites.details.support.wpCoreSettings.action')}
            onClick={onOpenReinstallWPCoreDialog}
          />
        </div>
      </SettingsBlock>
    </>
  );
}
