import { ButtonHTMLAttributes, MouseEvent, Ref, forwardRef } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  text: string;
  startIcon?: string;
  endIcon?: string;
  uiType?: 'primary' | 'secondary' | 'cta' | 'cancel' | 'destructive' | 'neutral';
  condensed?: boolean;
  extraClass?: string;
};

type Props = IProps & ButtonHTMLAttributes<HTMLButtonElement>;

// ----------------------------------------------------------------------

const Button = forwardRef(
  (
    {
      text,
      startIcon,
      endIcon,
      uiType = 'primary',
      condensed,
      extraClass,
      onClick,
      ...other
    }: Props,
    ref: Ref<HTMLButtonElement>
  ) => {
    // EVENT FUNCTION
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
      event.currentTarget.blur(); // Blur the button after click
    };

    return (
      <button
        ref={ref}
        type="button"
        className={`gv-button gv-button-${uiType}${condensed ? ' gv-mode-condensed' : ''}${
          extraClass ? ` ${extraClass}` : ''
        }`}
        onClick={handleClick}
        {...other}
      >
        {startIcon && <gv-icon src={`/src/icons/${startIcon}.svg`} />}
        <span>{text}</span>
        {endIcon && <gv-icon src={`/src/icons/${endIcon}.svg`} />}
      </button>
    );
  }
);

export default Button;
