// @types
import { SupportMobileOS } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  mobileOS: SupportMobileOS;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function CompanianAppDownloadContent({ mobileOS, onClose }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('dashboard.sites.details.mwp.overview.companianAppDownloadDialog.title', {
            mobileOS: mobileOS === SupportMobileOS.ios ? 'iPhone' : 'Android',
          })}
        </h2>

        <div style={{ overflowX: 'auto' }}>
          <div className="gv-flex-column-sm">
            <p>
              {translate(
                'dashboard.sites.details.mwp.overview.companianAppDownloadDialog.description'
              )}
            </p>

            <img
              src={`/assets/mwp/companian_app_${
                mobileOS === SupportMobileOS.ios ? 'ios' : 'android'
              }_qr_code.svg`}
              alt="qr-code"
              style={{ width: '144px', height: '144px' }}
            />
          </div>
        </div>
      </div>

      <Button
        text={translate('dashboard.sites.details.mwp.overview.companianAppDownloadDialog.action')}
        onClick={onClose}
      />
    </>
  );
}
