// @types
import { UpdateVulnerabilityRow } from 'src/@types/site';
import { TableHeader } from 'src/@types/layout';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
// utils
import { filterTableSort } from 'src/utils/vulnerabilities';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
import { TextCustomize } from 'src/components/gravity/text';
//
import VulnerabilityRow from './VulnerabilityRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'severity',
    label: 'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.header.severity',
    sort: true,
  },
  {
    id: 'vulnName',
    label: 'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.header.vulnName',
  },
  {
    id: 'type',
    label: 'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.header.type',
  },
  {
    id: 'pluginName',
    label:
      'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.header.pluginName',
    sort: true,
  },
  {
    id: 'updateVer',
    label:
      'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.header.fixVersion',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  autoUpdateSetting: boolean;
  isFetching: boolean;
  unsortedTableData: UpdateVulnerabilityRow[];
  handleSelectUpdateVulnerabilities: (vulns: UpdateVulnerabilityRow[]) => void;
};

// ----------------------------------------------------------------------

export default function VulnerabilityTable({
  autoUpdateSetting,
  isFetching,
  unsortedTableData,
  handleSelectUpdateVulnerabilities,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    onSort,
    onChangePage,
  } = useGravityTable({
    defaultOrderBy: 'severity',
    defaultOrder: 'desc',
    defaultRowsPerPage: 10,
  });

  // VAR
  const headers = autoUpdateSetting ? HEADERS.slice(0, -1) : HEADERS;

  const filteredData = [...unsortedTableData].sort(filterTableSort(orderBy, order));

  const isNotFound = filteredData.length === 0;

  return (
    <div className="gv-data-table">
      <div style={{ overflowX: 'auto' }}>
        <table className="gv-col-1-shrink gv-col-3-shrink gv-col-6-shrink">
          <TableHeadCustom
            headers={
              isNotFound || isFetching
                ? headers.map((header) => ({ ...header, sort: false }))
                : headers
            }
            order={order}
            orderBy={orderBy}
            onSort={onSort}
          />
          <tbody>
            {!isFetching
              ? filteredData
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((row) => (
                    <VulnerabilityRow
                      key={row.id}
                      autoUpdateSetting={autoUpdateSetting}
                      row={row}
                      handleSelectUpdateVulnerabilities={handleSelectUpdateVulnerabilities}
                    />
                  ))
              : [...Array(rowsPerPage)].map((_, index) => (
                  <TableSkeleton key={index} headers={HEADERS} />
                ))}
          </tbody>
        </table>
      </div>

      {!isFetching && isNotFound && (
        <div className="gv-empty-state">
          <div className="gv-empty-state-content">
            <h3 className="gv-title">
              {translate(
                'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.noData.title'
              )}
            </h3>
            <p>
              {translate(
                'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.noData.description'
              )}
            </p>
          </div>
        </div>
      )}

      <TablePagination
        totalRows={filteredData.length}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        customInfo={
          <TextCustomize
            text={translate(
              'dashboard.sites.details.mwp.security.main.vulnerabillitiesBlock.table.footer'
            )}
            textClass="gv-caption gv-text-bold"
            linkHref={['https://patchstack.com/']}
            linkStyle={{
              cursor: 'pointer',
              fontWeight: 600,
            }}
          />
        }
      />
    </div>
  );
}
