// @types
import { TableHeader } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { TableHeadCustom, TableSkeleton } from 'src/components/gravity/table';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  { id: 'plan', label: 'wpone.sites.details.plan.table.header.plan' },
  { id: 'diskSpace', label: 'wpone.sites.details.plan.table.header.diskSpace' },
  { id: 'planStorageLimit', label: 'wpone.sites.details.plan.table.header.planStorageLimit' },
];

// ----------------------------------------------------------------------

type Props = {
  isFetching: boolean;
  planName: string;
  localDiskUsage: number;
  localDiskLimit: number;
  planDiskLimit: number;
};

// ----------------------------------------------------------------------

export default function PlanTable({
  isFetching,
  planName,
  localDiskUsage,
  localDiskLimit,
  planDiskLimit,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div
      className="gv-data-table"
      style={{
        overflowX: 'auto',
      }}
    >
      <table>
        <TableHeadCustom headers={HEADERS} />
        <tbody>
          {!isFetching ? (
            <tr>
              <td>{translate(`wpone.sites.plan.${planName}`)}</td>
              <td>
                {localDiskUsage}/{localDiskLimit} {translate('wpone.general.unit.gb')}
              </td>
              <td>
                {localDiskLimit}/{planDiskLimit} {translate('wpone.general.unit.gb')}
              </td>
            </tr>
          ) : (
            <TableSkeleton headers={HEADERS} />
          )}
        </tbody>
      </table>
    </div>
  );
}
