// @types
import { BackupSettings } from 'src/@types/site';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
import RestoreBackupForm from './RestoreBackupForm';

// ----------------------------------------------------------------------

type Props = {
  backupSettings: BackupSettings;
  toggleRestoreBackupDialog: boolean;
  onCloseRestoreBackupDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function RestoreBackupDialog({
  backupSettings,
  toggleRestoreBackupDialog,
  onCloseRestoreBackupDialog,
}: Props) {
  return (
    <ActionDialog
      open={toggleRestoreBackupDialog}
      onClose={onCloseRestoreBackupDialog}
      children={
        <RestoreBackupForm backupSettings={backupSettings} onClose={onCloseRestoreBackupDialog} />
      }
    />
  );
}
