// redux
import { useSelector } from 'src/redux/store';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  colorCode: {
    iconName: string;
    color: string;
  };
  title: string;
  description: string;
  convertedCurrentValueDisplay: string | undefined;
  valueDiffDisplay: string | undefined;
};

// ----------------------------------------------------------------------

export default function SubScoreCard({
  colorCode,
  title,
  description,
  convertedCurrentValueDisplay,
  valueDiffDisplay,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { showSubScoreDescription } = useSelector((state) => state.site);

  return (
    <div className="gv-flex-column-sm">
      <div className="gv-flex-row-sm">
        <span style={{ color: `var(${colorCode.color})` }}>
          <gv-icon src={`/src/icons/${colorCode.iconName}.svg`} />
        </span>
        <p className="gv-text-sm gv-text-bold">{translate(title)}</p>
      </div>

      {convertedCurrentValueDisplay === undefined ? (
        <p className="gv-text-sm  gv-text-on-alternative">
          {translate(
            'dashboard.sites.details.mwp.performance.block.performance.subScores.cardInfo.valueMessage.noData'
          )}
        </p>
      ) : (
        <div className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
          <p className="gv-text-sm gv-text-bold">{convertedCurrentValueDisplay}</p>
          <p className="gv-text-sm gv-text-on-alternative">{valueDiffDisplay}</p>
        </div>
      )}
      {showSubScoreDescription && (
        <p className="gv-text-sm gv-text-on-alternative">{translate(description)}</p>
      )}
    </div>
  );
}
