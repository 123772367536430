import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// @types
import { MigrationWPCredentialsResponse, MigrationWithWPCredentials } from 'src/@types/site';

// ----------------------------------------------------------------------

export const wpCredentialsMigrationApi = createApi({
  reducerPath: 'wpCredentialsMigrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://app.wp.one/api/migration-service/migration',
  }),
  tagTypes: ['WPCredentialMigration'],
  endpoints: (builder) => ({
    // ENQUEUE MIGRATION
    enqueueWPCredentialsMigration: builder.mutation<
      MigrationWPCredentialsResponse,
      MigrationWithWPCredentials
    >({
      query: (data) => ({
        url: '',
        method: 'post',
        body: data,
      }),
    }),
    // GET MIGRATION STATUS
    getWPCredentialsMigrationStatus: builder.query<
      MigrationWPCredentialsResponse,
      string | undefined
    >({
      query: (migrationId) => ({
        url: `/${migrationId}`,
        method: 'get',
      }),
    }),
  }),
});

export const { useEnqueueWPCredentialsMigrationMutation, useGetWPCredentialsMigrationStatusQuery } =
  wpCredentialsMigrationApi;
