import { CSSProperties, useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

type TextWithBoldDynamicContentProps = {
  text: string;
  fontSize?: 'xl' | 'lg' | 'md' | 'sm';
  strongStyle?: CSSProperties;
};

// ----------------------------------------------------------------------

export default function TextWithBoldDynamicContent({
  text,
  fontSize = 'sm',
  strongStyle = {},
}: TextWithBoldDynamicContentProps) {
  const textRef = useRef<HTMLParagraphElement>(null);

  // HELPER FUNCTION
  function applyStrongStyle(element: HTMLElement, strongStyle: CSSProperties) {
    const strongElements = element.getElementsByTagName('strong');
    for (let i = 0; i < strongElements.length; i++) {
      const strongElement = strongElements[i];
      Object.assign(strongElement.style, strongStyle);
    }
  }

  //
  useEffect(() => {
    if (textRef.current) {
      textRef.current.innerHTML = text;
      applyStrongStyle(textRef.current, strongStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return <p ref={textRef} className={`gv-text-${fontSize}`} />;
}
