import { RefObject, useEffect } from 'react';

// ----------------------------------------------------------------------

type Props = {
  outsideRefs: RefObject<HTMLElement>[]; // Refs to elements where clicking outside will trigger handleClickingOutside
  insideRefs?: RefObject<HTMLElement>[]; // Refs that are inside outsideRefs, clicking inside any of these refs will trigger handleClickingOutside
  handleClickingOutside: (event: MouseEvent) => void;
  shouldListen: boolean;
};

// ----------------------------------------------------------------------

const useClickOutsideEffect = ({
  outsideRefs,
  insideRefs = [],
  handleClickingOutside,
  shouldListen,
}: Props) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      // Check if the click is outside all outsideRefs
      const isOutside = outsideRefs.every((ref) => !ref.current || !ref.current.contains(target));
      // Check if the click is inside any insideRefs
      const isInside = insideRefs.some((ref) => ref.current && ref.current.contains(target));

      if (isOutside || isInside) {
        handleClickingOutside(event);
      }
    };

    if (shouldListen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (shouldListen) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [outsideRefs, insideRefs, handleClickingOutside, shouldListen]);
};

export default useClickOutsideEffect;
