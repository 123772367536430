import type { BaseQueryFn } from '@reduxjs/toolkit/query';
// axios
import axios from 'src/utils/axios';
import type { AxiosRequestConfig, AxiosError } from 'axios';

// ----------------------------------------------------------------------

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      // console.log('Endpoint url:', method?.toUpperCase(), baseUrl + url);
      // console.log('Request body:', data);

      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
      });

      // console.log('Result:', result);

      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;

      const error = {
        status: err.response?.status,
        data: err.response?.data || err.message,
      };

      // console.log('Error:', error);

      return {
        error: error,
      };
    }
  };
