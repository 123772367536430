import { ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  description: string;
  content: ReactNode;
  action?: ReactNode;
};

// ----------------------------------------------------------------------

export default function SettingsBlock({ title, description, content, action }: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-md">
      <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
        <div className="gv-flex-row-sm gv-justify-between">
          <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
          {!isMobile && action && action}
        </div>
        <p className="gv-text-sm">{translate(description)}</p>
        {isMobile && action && action}
      </div>
      <div className="gv-content-container gv-p-lg">{content}</div>
    </div>
  );
}
