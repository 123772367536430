import { useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useGenerateSSHPasswordMutation, useLazyGetPasswordsQuery } from 'src/redux/api/siteApi';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { PasswordInputState } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';
import SettingsDetails from './SettingsDetails';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function SSHSettingsBlock({ site }: Props) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // API
  const [
    getPasswords,
    {
      data: getPasswordsData, // When this is defined, default pw "1234" has already been replaced
    },
  ] = useLazyGetPasswordsQuery();

  const [generateSSHPassword, { isLoading: generateSSHPasswordIsLoading }] =
    useGenerateSSHPasswordMutation();

  // STATE
  const [password, setPassword] = useState<PasswordInputState>({
    value: '1234',
    isHidden: true,
  });

  // EVENT FUNCTION
  function handleGenerateNewPassword() {
    if (!namespace || !cluster) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'generate-new-sftp-password',
    });

    generateSSHPassword({ cluster: cluster, namespace: namespace })
      .unwrap()
      .then((res) => {
        setPassword((prev) => ({ ...prev, value: res.ssh_password }));
        displayToast(
          translate(
            'wpone.sites.details.settings.ssh.requestResponse.successMessage.generatePassword'
          )
        );
      })
      .catch((err) => {
        const errCode = (err as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
      });
  }

  // Show/Copy password
  function handleGetPassword(isCopy: boolean) {
    if (!namespace || !cluster) return;

    if (password.isHidden) {
      if (getPasswordsData) {
        if (isCopy) {
          navigator.clipboard.writeText(getPasswordsData.ssh_password);
          displayToast(translate('wpone.general.action.copied'));
        } else {
          setPassword((prev) => ({
            ...prev,
            isHidden: false,
          }));
        }
      } else {
        getPasswords({ cluster: cluster, namespace: namespace }, true)
          .unwrap()
          .then((res) => {
            if (isCopy) {
              navigator.clipboard.writeText(res.ssh_password);
              displayToast(translate('wpone.general.action.copied'));
              setPassword((prev) => ({ ...prev, value: res.ssh_password }));
            } else {
              setPassword((prev) => ({ ...prev, value: res.ssh_password, isHidden: false }));
            }
          })
          .catch(() => {
            displayToast(
              translate(
                'wpone.sites.details.settings.ssh.requestResponse.errorMessage.getCurrentPassword'
              ),
              { variant: 'alert' }
            );
          });
      }
    } else {
      if (isCopy) {
        navigator.clipboard.writeText(password.value);
        displayToast(translate('wpone.general.action.copied'));
      } else {
        setPassword((prev) => ({
          ...prev,
          isHidden: true,
        }));
      }
    }
  }

  return (
    <SettingsBlock
      title="wpone.sites.details.settings.ssh.title"
      description="wpone.sites.details.settings.ssh.description"
      content={
        <div className="gv-flex-column-md">
          <div
            className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-4`}
            style={{
              gridRowGap: '16px',
              gridColumnGap: '24px',
            }}
          >
            <SettingsDetails
              title="wpone.sites.details.settings.ssh.infoLabel.host"
              value={site.ssh_configuration.hostname}
              copiable
            />
            <SettingsDetails
              title="wpone.sites.details.settings.ssh.infoLabel.username"
              value={site.ssh_configuration.user}
              copiable
            />
            <SettingsDetails
              title="wpone.sites.details.settings.ssh.infoLabel.password"
              value={password.value}
              password={{
                isHidden: password.isHidden,
                onShow: () => handleGetPassword(false),
                onCopy: () => handleGetPassword(true),
              }}
            />
            <SettingsDetails
              title="wpone.sites.details.settings.ssh.infoLabel.port"
              value={site.ssh_configuration.port.toString()}
              copiable
            />
          </div>

          <SettingsDetails
            title="wpone.sites.details.settings.ssh.infoLabel.sshTerminalCommand"
            value={`ssh -p${site.ssh_configuration.port} ${site.ssh_configuration.user}@${site.ssh_configuration.hostname}`}
            copiable
          />
        </div>
      }
      action={
        <Button
          condensed
          text={translate('wpone.sites.details.settings.ssh.action')}
          onClick={handleGenerateNewPassword}
          disabled={generateSSHPasswordIsLoading}
        />
      }
    />
  );
}
