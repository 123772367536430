import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
// redux
import { useSelector } from 'src/redux/store';
import { useGetCurrentUserQuery } from 'src/redux/api/userApi';
// @types
import { ResourceTypeEnum, AccessTypeEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

const useHighestUserRole = () => {
  const { name } = useParams();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [highestUserRole, setHighestUserRole] = useState<AccessTypeEnum>(AccessTypeEnum.developer);

  const [isLoading, setIsLoading] = useState(true);

  // API
  const { data, isFetching, isSuccess } = useGetCurrentUserQuery(user?.email);

  //
  useEffect(() => {
    if (isSuccess) {
      if (data && data.accessPermissions !== undefined) {
        if (
          data.accessPermissions.find(
            (permission) =>
              permission.resource &&
              permission.resource.type === ResourceTypeEnum.site &&
              permission.resource.externalId === name &&
              permission.accessType === AccessTypeEnum.owner
          )
        ) {
          setHighestUserRole(AccessTypeEnum.owner);
        } else if (
          data.accessPermissions.find(
            (permission) =>
              permission.resource &&
              permission.resource.type === ResourceTypeEnum.site &&
              permission.resource.externalId === name &&
              permission.accessType === AccessTypeEnum.admin
          )
        ) {
          setHighestUserRole(AccessTypeEnum.admin);
        } else {
          setHighestUserRole(AccessTypeEnum.developer);
        }
      }

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return { highestUserRole, isHighestUserRoleLoading: isLoading };
};

export default useHighestUserRole;
