import { InputHTMLAttributes, Ref, forwardRef } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  label?: string;
  labelId?: string; // Required for label
  description?: string;
  errMsg?: string;
  condensed?: boolean;
};

type Props = IProps & InputHTMLAttributes<HTMLInputElement>;

// ----------------------------------------------------------------------

const Toggle = forwardRef(
  (
    { label, labelId, description, errMsg, condensed, ...other }: Props,
    ref: Ref<HTMLInputElement>
  ) => (
    <div className="gv-activated">
      <div className={`${condensed ? ' gv-mode-condensed' : ''}`}>
        <div className="gv-form-option">
          <div className="gv-option-inline">
            <div className="gv-toggle">
              <input ref={ref} id={labelId ? labelId : undefined} type="checkbox" {...other} />
              <div className="gv-toggle-slider" />
            </div>

            {label && labelId && (
              <label htmlFor={labelId} className="gv-label">
                <span>{label}</span>
                {description && <span className="gv-description">{description}</span>}
              </label>
            )}
          </div>

          {errMsg && <span className="gv-input-message gv-error">{errMsg}</span>}
        </div>
      </div>
    </div>
  )
);

export default Toggle;
