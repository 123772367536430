import { ChangeEvent } from 'react';
// @types
import { TableHeader } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Tooltip from '../Tooltip';
import { Checkbox } from '../form';

// ----------------------------------------------------------------------

// Header align, hide, shrink... should be set in table component (that way body col will also be set at the same time)
type Props = {
  headers: TableHeader[];
  order?: 'asc' | 'desc';
  orderBy?: string;
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
};

// ----------------------------------------------------------------------

export default function TableHeadCustom({
  headers,
  order,
  orderBy,
  rowCount = 0,
  numSelected = 0,
  onSort,
  onSelectAllRows,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <thead>
      <tr>
        {onSelectAllRows && (
          <th scope="col">
            <Checkbox
              mixed={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0 && numSelected <= rowCount}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </th>
        )}

        {headers.map((header) => {
          if (header.sort && onSort)
            return (
              <th
                key={header.id}
                scope="col"
                onClick={() => onSort(header.id)}
                style={{ cursor: 'pointer' }}
              >
                <div className="gv-th-sort">
                  {header.tooltip ? (
                    <Tooltip tooltip={translate(header.tooltip)}>
                      <span>{translate(header.label)}</span>
                    </Tooltip>
                  ) : (
                    <span>{translate(header.label)}</span>
                  )}
                  <div className={`gv-sort${orderBy === header.id ? ` gv-${order}` : ''}`}>
                    <gv-indicator src="/src/indicators/sort_asc.svg" />
                    <gv-indicator src="/src/indicators/sort_desc.svg" />
                  </div>
                </div>
              </th>
            );

          return (
            <th key={header.id} scope="col">
              {header.tooltip ? (
                <Tooltip tooltip={translate(header.tooltip)}>
                  <span>{translate(header.label)}</span>
                </Tooltip>
              ) : (
                <span>{translate(header.label)}</span>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
