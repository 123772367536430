import { UpdateVulnerabilityRow, UpdateVulnerabilityType } from 'src/@types/site';

// ----------------------------------------------------------------------

// severity and pluginName can be chosen for sorting, if the primary sortedBy is equal then it will be based on the other
export function filterTableSort(
  orderBy: string,
  order: 'asc' | 'desc'
): (a: UpdateVulnerabilityRow, b: UpdateVulnerabilityRow) => number {
  return (a: UpdateVulnerabilityRow, b: UpdateVulnerabilityRow) => {
    const aScore = a.cvssScore || 0;
    const bScore = b.cvssScore || 0;

    if (orderBy === 'severity') {
      if (aScore === bScore) {
        return order === 'asc'
          ? a.pluginName.localeCompare(b.pluginName)
          : b.pluginName.localeCompare(a.pluginName);
      }
      return order === 'asc' ? aScore - bScore : bScore - aScore;
    } else {
      if (a.pluginName === b.pluginName) {
        return order === 'asc' ? aScore - bScore : bScore - aScore;
      }
      return order === 'asc'
        ? a.pluginName.localeCompare(b.pluginName)
        : b.pluginName.localeCompare(a.pluginName);
    }
  };
}

export function filterHighestVersion(
  allVulns: UpdateVulnerabilityRow[],
  type: UpdateVulnerabilityType
): UpdateVulnerabilityRow[] {
  const typeFilteredVulns = allVulns.filter((vuln) => vuln.type === type);

  const versionMap: { [name: string]: UpdateVulnerabilityRow } = {};

  for (const vuln of typeFilteredVulns) {
    const { pluginName, updateVer: currentVersion } = vuln;
    const existingVuln = versionMap[pluginName];

    if (
      !existingVuln ||
      (currentVersion !== null &&
        existingVuln.updateVer !== null &&
        compareVersions(currentVersion, existingVuln.updateVer) === currentVersion)
    ) {
      versionMap[pluginName] = vuln;
    }
  }

  return Object.values(versionMap).sort((a, b) => (b.cvssScore || 0) - (a.cvssScore || 0));
}

function compareVersions(version1: string, version2: string): string {
  const v1Parts = version1.split('.').map((part) => parseInt(part, 10));
  const v2Parts = version2.split('.').map((part) => parseInt(part, 10));

  const maxLength = Math.max(v1Parts.length, v2Parts.length);

  for (let i = 0; i < maxLength; i++) {
    const part1 = v1Parts[i] || 0;
    const part2 = v2Parts[i] || 0;

    if (part1 > part2) {
      return version1;
    } else if (part1 < part2) {
      return version2;
    }
  }

  // If both versions are equal
  return version1;
}

export function convertScoreToSeverity(score: null | number): {
  type: 'generic' | 'warning' | 'alert';
  status: 'unknown' | 'low' | 'medium' | 'high' | 'critical';
} {
  const type = score === null ? 'generic' : score < 7 ? 'warning' : 'alert';
  const status =
    score === null
      ? 'unknown'
      : score < 4
      ? 'low'
      : score < 7
      ? 'medium'
      : score < 9
      ? 'high'
      : 'critical';

  return {
    type,
    status,
  };
}
