// @mui
import { Stack, PaperProps, Typography } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import Image from './Image';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '' }: Props) {
  const { translate } = useLocales();

  return searchQuery ? (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height={'100%'}
      p={3}
      spacing="9px"
    >
      <Image
        disabledEffect
        visibleByDefault
        alt="empty content"
        src={'/assets/no-results.svg'}
        sx={{ width: '72px', height: '72px' }}
      />
      <Typography align="center" fontWeight={600} sx={{ color: '#141414' }}>
        {translate('wpone.globalSearch.optionNotFound')}
      </Typography>
      <Typography variant="caption" align="center" sx={{ color: '#141414' }}>
        {translate('wpone.globalSearch.optionNotFoundDescription', { searchQuery })}
      </Typography>
    </Stack>
  ) : (
    <></>
  );
}
