import { Dispatch, SetStateAction } from 'react';
import ReactGA from 'react-ga4';
// @types
import { SecuritySettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { TextCustomize } from 'src/components/gravity/text';
import { Toggle } from 'src/components/gravity/form';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

type Props = {
  autoUpdateSetting: boolean;
  isSettingsLoading: boolean;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
  onOpenDeactivateAutoFixDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AutomaticUpdateForm({
  autoUpdateSetting,
  isSettingsLoading,
  setSettings,
  setIsSettingsLoading,
  onOpenDeactivateAutoFixDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  //EVENT FUNCTION
  function handleToggleAutoFix(event: React.ChangeEvent<HTMLInputElement>) {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'toggle-auto-fix-vulns-site-security-settings',
    });

    if (event.target.checked) {
      setIsSettingsLoading(true);
      setTimeout(() => {
        const isSuccess = true;

        if (isSuccess) {
          setSettings((prev) => ({ ...prev, autoUpdate: true }));
          displayToast(
            translate(
              'dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.toast.activateAutoFix.success'
            )
          );
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.toast.activateAutoFix.error'
            ),
            { variant: 'alert' }
          );
        }

        setIsSettingsLoading(false);
      }, 2000);
    } else {
      onOpenDeactivateAutoFixDialog();
    }
  }

  return (
    <SettingsBlock
      title="dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.title"
      description="dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.description"
    >
      <div className="gv-content-container">
        <div className="gv-p-lg">
          <Toggle
            checked={autoUpdateSetting}
            onChange={(e) => handleToggleAutoFix(e)}
            label={translate(
              'dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.toggle.label'
            )}
            labelId="activateAutoFix"
            description={translate(
              'dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.toggle.description'
            )}
            condensed
            disabled={isSettingsLoading}
          />
        </div>
        <div className="gv-notice gv-notice-addon gv-notice-info">
          <gv-icon class="gv-notice-icon" src="/src/icons/info.svg" />
          <div className="gv-notice-content">
            <TextCustomize
              text={translate(
                'dashboard.sites.details.mwp.security.settings.automaticUpdateBlock.infoAlert'
              )}
              textClass="gv-text-sm"
              linkAction={[
                () =>
                  window.open(
                    'https://help.one.com/hc/en-us/articles/4402283373841-Roll-back-plugins-and-themes-to-a-previous-version',
                    '_blank'
                  ),
              ]}
              linkStyle={{
                cursor: 'pointer',
                fontWeight: 600,
              }}
            />
          </div>
        </div>
      </div>
    </SettingsBlock>
  );
}
