import { createSlice } from '@reduxjs/toolkit';
// @types
import { DomainState } from 'src/@types/domain';

// ----------------------------------------------------------------------

const initialState: DomainState = {
  // For editing dns record
  editingDnsRecord: null,
};

const slice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    // SET EDITING DNS RECORD
    setEditingDnsRecord(state, action) {
      state.editingDnsRecord = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setEditingDnsRecord } = slice.actions;
