import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// redux
import {
  useLazyGetSiteSMTPBlockedStatusQuery,
  useEnableSiteSMTPBlockMutation,
  useDisableSiteSMTPBlockMutation,
} from 'src/redux/api/siteApi';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Toggle from 'src/components/gravity/form/Toggle';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

export default function SMTPSettings() {
  const { namespace, cluster } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [getSiteSMTPBlockedStatus, { isLoading: blockSMTPStatusIsLoading }] =
    useLazyGetSiteSMTPBlockedStatusQuery();
  const [enableSiteSMTPBlock, { isLoading: blockSMTPIsLoading }] = useEnableSiteSMTPBlockMutation();
  const [disableSiteSMTPBlock, { isLoading: unblockSMTPIsLoading }] =
    useDisableSiteSMTPBlockMutation();

  // STATE
  const [toggleBlockage, setToggleBlockage] = useState(false);
  const [smtpBlockageError, setSmtpBlockageError] = useState<string | null>(null);

  // EVENT FUNCTION
  const handleToggleChange = () => {
    if (!namespace || !cluster) return;
    if (toggleBlockage) {
      disableSiteSMTPBlock(`${cluster}/${namespace}`)
        .unwrap()
        .then((data) => {
          if (data.code === 0) {
            if (isMountedRef.current) {
              setToggleBlockage(false);
            }
            displayToast(
              translate(
                'wponesupport.sites.details.support.smtpSettings.disableSMTPBlock.toast.success'
              )
            );
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.support.smtpSettings.disableSMTPBlock.toast.error'
              ),
              { variant: 'alert' }
            );
          }
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    } else {
      enableSiteSMTPBlock(`${cluster}/${namespace}`)
        .unwrap()
        .then((data) => {
          if (data.code === 0) {
            if (isMountedRef.current) {
              setToggleBlockage(true);
            }

            displayToast(
              translate(
                'wponesupport.sites.details.support.smtpSettings.enableSMTPBlock.toast.success'
              )
            );
          } else {
            displayToast(
              translate(
                'wponesupport.sites.details.support.smtpSettings.enableSMTPBlock.toast.error'
              ),
              { variant: 'alert' }
            );
          }
        })
        .catch((error) => {
          const errCode = (error as { status: number; data: any }).status;
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        });
    }
  };

  //
  useEffect(() => {
    if (!namespace || !cluster) return;
    getSiteSMTPBlockedStatus(`${cluster}/${namespace}`)
      .unwrap()
      .then((data) => {
        if (isMountedRef.current) {
          if (data.code === 0) {
            setToggleBlockage(true);
          } else {
            setToggleBlockage(false);
          }
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setSmtpBlockageError(
            translate('wponesupport.sites.details.support.smtpSettings.getSMTPBlockedStatus.error')
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SettingsBlock title={translate('wponesupport.sites.details.support.smtpSettings.title')}>
      <Toggle
        condensed
        name="blockSMTP"
        label={translate('wponesupport.sites.details.support.smtpSettings.label')}
        labelId="blockSMTP"
        description={translate('wponesupport.sites.details.support.smtpSettings.description')}
        checked={toggleBlockage}
        onChange={handleToggleChange}
        disabled={
          smtpBlockageError !== null ||
          blockSMTPStatusIsLoading ||
          blockSMTPIsLoading ||
          unblockSMTPIsLoading
        }
        errMsg={smtpBlockageError || undefined}
      />
    </SettingsBlock>
  );
}
