import { useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { dispatch } from 'src/redux/store';
import { userApi } from 'src/redux/api/userApi';
import { useAddSiteUserMutation } from 'src/redux/api/resourceApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { AccessTypeEnum } from 'src/@types/user';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { fTranslationDynamicKey } from 'src/utils/format';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField, RHFSelect } from 'src/components/gravity/hook-form';
import Alert from 'src/components/gravity/Alert';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  role: AccessTypeEnum;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AddUserForm({ onClose }: Props) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addSiteUser, { isLoading }] = useAddSiteUserMutation();

  // FORM
  const defaultValues = {
    email: '',
    role: AccessTypeEnum.admin,
  };

  const AddUserPermissionSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(translate('wpone.general.inputField.email.validation1'))
      .required(translate('wpone.general.inputField.email.validation2')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AddUserPermissionSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    if (!cluster || !namespace) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'add-site-user',
    });

    await addSiteUser({
      userForSharing: data.email.trim(),
      accessType: data.role,
      siteId: `${cluster}/${namespace}`,
    })
      .unwrap()
      .then(() => {
        displayToast(translate('wpone.general.requestResponse.access.successMessage.addAccess'));

        // For getting latest data in Customer table (for support/service)
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));

        onClose();
      })
      .catch((error) => {
        // Error message check order: user same brand => is customer => access exists => general
        const errCode = (error as { status: number; data: any }).status;
        const errMsg = (error as { status: number; data: any }).data.message;

        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(
              [
                `wpone.sites.details.users.addUserDialog.toast.error.${fTranslationDynamicKey(
                  errMsg
                )}`,
                `wpone.general.requestResponse.access.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              {
                roleType: errMsg.includes(AccessTypeEnum.admin)
                  ? AccessTypeEnum.admin
                  : AccessTypeEnum.developer,
              }
            ),
          });
        } else {
          displayToast(
            translate(
              [
                `wpone.sites.details.users.addUserDialog.requestResponse.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                `wpone.general.requestResponse.access.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              {
                roleType: errMsg.includes(AccessTypeEnum.admin)
                  ? AccessTypeEnum.admin
                  : AccessTypeEnum.developer,
              }
            ),
            { variant: 'alert' }
          );
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.users.addUserDialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <p>{translate('wpone.sites.details.users.addUserDialog.description')}</p>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}
            <RHFTextField
              name="email"
              label={translate('wpone.general.inputField.email.label')}
              labelId="user-email"
              placeholder="example@gmail.com"
            />
            <RHFSelect
              name="role"
              label={translate('wpone.general.inputField.userRole.label')}
              labelId="user-role"
              options={Object.values(AccessTypeEnum)
                .slice(1)
                .map((option) => ({
                  label: translate(`wpone.general.inputField.userRole.option.${option}`),
                  value: option,
                }))}
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.add')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
