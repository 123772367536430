// form
import { useFormContext, Controller } from 'react-hook-form';
//
import { UploadSingleFile, UploadProps } from '../upload';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
}

// ----------------------------------------------------------------------

export function RHFUploadSingleFile({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <UploadSingleFile
            accept=".txt"
            file={field.value}
            error={checkError}
            helperText={
              checkError && <div className="gv-input-message gv-error">{error.message}</div>
            }
            {...other}
          />
        );
      }}
    />
  );
}
