import { ReactNode } from 'react';
import { useParams } from 'react-router';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useLocales from 'src/hooks/useLocales';
// components
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Action = {
  text: string;
  startIcon?: string;
  endIcon?: string;
  disabled?: boolean;
  onClick: VoidFunction;
};

type Props = {
  title: string;
  description?: string | ReactNode;
  backPath?: string;
  primaryAction?: Action;
  secondaryAction?: Action;
  statBar?: {
    icon: string;
    text: string;
  }[];
  breadcrumbs?: boolean;
};

// ----------------------------------------------------------------------

export default function Header({
  title,
  description,
  backPath = '/sites',
  primaryAction,
  secondaryAction,
  statBar,
  breadcrumbs = true,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex-column-sm">
      {breadcrumbs &&
        (isMobile ? (
          <div className="gv-breadcrumbs">
            <a href={backPath} style={{ cursor: 'pointer' }}>
              <gv-icon src="/src/icons/arrow_back.svg" />
              <span>{translate('wpone.general.action.back')}</span>
            </a>
          </div>
        ) : (
          <ul className="gv-breadcrumbs">
            <li>
              <a href={backPath}>{translate('dashboard.sites.title')}</a>
              <gv-icon src="/src/icons/chevron_right.svg" />
            </li>
            <li>
              <span>{translate('dashboard.sites.details.mwp.sidebarTitle')}</span>
            </li>
          </ul>
        ))}

      <div className="gv-flex-column-md">
        <div className="gv-flex-column-sm">
          <div className="gv-flex-row-lg gv-tab-justify-between">
            <div className="gv-flex gv-flex-col">
              <p className="gv-heading-lg">{translate(title)}</p>
              <p className="gv-heading-sm gv-text-on-alternative">{name}</p>
            </div>
            {!isMobile && (
              <ActionContent primaryAction={primaryAction} secondaryAction={secondaryAction} />
            )}
          </div>
          {statBar && (
            <div
              className="gv-flex gv-flex-col gv-tab-flex-row"
              style={{
                gap: isMobile ? '4px' : '16px',
              }}
            >
              {statBar.map(({ icon, text }, index) => (
                <StatDescrition key={index} text={text} iconName={icon} />
              ))}
            </div>
          )}
          {description &&
            (typeof description === 'string' ? (
              <p className="gv-text-sm gv-text-max">{translate(description)}</p>
            ) : (
              description
            ))}
        </div>

        {isMobile && (
          <ActionContent primaryAction={primaryAction} secondaryAction={secondaryAction} />
        )}
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------

type StatDescriptionProps = {
  text: string;
  iconName: string;
};

// ----------------------------------------------------------------------

function StatDescrition({ text, iconName }: StatDescriptionProps) {
  return (
    <div
      className="gv-flex gv-flex-row gv-items-center gv-text-on-alternative"
      style={{ gap: '4px' }}
    >
      <gv-icon src={`/src/icons/${iconName}.svg`} />
      <p className="gv-text-sm gv-text-bold">{text}</p>
    </div>
  );
}

// ----------------------------------------------------------------------

type ActionContentProps = {
  primaryAction?: Action;
  secondaryAction?: Action;
};

// ----------------------------------------------------------------------

function ActionContent({ primaryAction, secondaryAction }: ActionContentProps) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div className="gv-flex gv-flex-col-reverse gv-tab-flex-row gv-gap-sm gv-tab-gap-md">
      {secondaryAction && (
        <Button
          uiType="secondary"
          condensed={isMobile}
          text={translate(secondaryAction.text)}
          startIcon={secondaryAction.startIcon}
          endIcon={secondaryAction.endIcon}
          disabled={secondaryAction.disabled || false}
          onClick={secondaryAction.onClick}
        />
      )}
      {primaryAction && (
        <Button
          condensed={isMobile}
          text={translate(primaryAction.text)}
          startIcon={primaryAction.startIcon}
          endIcon={primaryAction.endIcon}
          disabled={primaryAction.disabled || false}
          onClick={primaryAction.onClick}
        />
      )}
    </div>
  );
}
